import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Chip, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

import { addNotification } from '~features/notifications/notifications.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import type { WhitelistAddressMetadata } from '~types/WhitelistType';

type WhitelistAddressItemProps = {
  address: string;
  whitelistAddressMetadata: WhitelistAddressMetadata;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleAddressSelectToggle: Function;
  handleAddSingleWallet: any; // bug with defining as Function type - Type 'Function' is not assignable to type 'MouseEventHandler<HTMLButtonElement>'.
};

const WhitelistAddressItem = ({
  address,
  whitelistAddressMetadata,
  handleAddressSelectToggle,
  handleAddSingleWallet,
}: WhitelistAddressItemProps) => {
  const dispatch = useAppDispatch();

  const handleItemSelect = (address) => {
    handleAddressSelectToggle(address);
  };

  const handleCopyToClipboard = (address: string) => {
    void navigator.clipboard.writeText(address);
    dispatch(
      addNotification({
        message: 'Address copied!',
        severity: 'info',
        duration: 1000,
      }),
    );
  };

  return (
    <>
      {whitelistAddressMetadata[address] && (
        <ListItem
          style={
            whitelistAddressMetadata[address]['isNewWallet']
              ? { background: '#94e098' }
              : whitelistAddressMetadata[address]['isSelected']
              ? { background: '#ffc7c7' }
              : { background: 'none' }
          }
          secondaryAction={
            <Button onClick={() => handleItemSelect(address)}>
              {whitelistAddressMetadata[address]['isSelected'] && !whitelistAddressMetadata[address]['isNewWallet'] && (
                <Typography>Undo</Typography>
              )}
              {!whitelistAddressMetadata[address]['isSelected'] &&
                !whitelistAddressMetadata[address]['isNewWallet'] && <DeleteOutlineIcon />}
            </Button>
          }
          disablePadding
        >
          <ListItemButton onClick={() => handleCopyToClipboard(address)}>
            <ListItemText primary={address} />
            {whitelistAddressMetadata[address]['isNewWallet'] && (
              <Chip
                label="Unconfirmed"
                color="success"
              />
            )}
          </ListItemButton>
        </ListItem>
      )}

      {!whitelistAddressMetadata[address] && (
        <ListItem
          secondaryAction={
            <Button onClick={handleAddSingleWallet}>
              <AddBoxOutlinedIcon />
            </Button>
          }
          disablePadding
        >
          <ListItemButton>
            <ListItemText primary={'This wallet address is not included in the whitelist. Would you like to add it?'} />
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};

export default WhitelistAddressItem;
