/* eslint-disable absolutely-import/import */
import './ProjectDrawerMenu.scss';

import { Box } from '@mui/material';
import { useMatch } from 'react-router-dom';

import { selectProjectId } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';

import ProjectDrawerMenuButton from '../ProjectDrawerMenuButton';
import { menuItems } from './ProjectDrawerMenu.consts';

type ProjectDrawerMenuProps = {
  small: boolean;
};
const ProjectDrawerMenu = ({ small }: ProjectDrawerMenuProps) => {
  const projectId = useAppSelector(selectProjectId);

  return (
    <Box className={'CAKE__project-drawer-menu'}>
      {menuItems.map((item) => (
        <ProjectDrawerMenuButton
          key={item.title}
          title={item.title}
          link={item.link(projectId)}
          icon={item.icon}
          isActive={Boolean(useMatch(item.linkPattern))}
          small={small}
        />
      ))}
    </Box>
  );
};

export default ProjectDrawerMenu;
