import ProjectVerbs from '~constants/streams/ProjectVerbs';
import type { ActivityReactionType, FeedActivityType } from '~types/Streams';

const successCallback = () => {
  console.log('now listening to changes in realtime');
};

const failCallback = (data) => {
  console.log('something went wrong with subscribing to feed, check the console logs');
  console.log(data);
};

const fetchStreamData = async (client: any, projectId: any, token: any, offset: number) => {
  try {
    console.log('fetching feed data');
    const feed = client.feed('projects_aggregated', projectId, token);
    const data = await feed.get({
      limit: 10,
      offset,
      enrich: true,
      reactions: { own: true, counts: true, recent: true },
    });
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const isEmittedActivityUserPost = async (activities: FeedActivityType[], userId: string) => {
  for (let i = 0; i < activities.length; i++) {
    if (activities[i].actor.id === userId && activities[i].verb === 'userPost') {
      return true;
    }
  }
  return false;
};

const addActivity = async (
  client: any,
  userId: string,
  projectId: string,
  verb: string,
  foreignId: string,
  object: any,
) => {
  const user = client.user(userId);
  const feed = client.feed('user', userId);
  const objectCollectionRef = await client.collections.add(verb, foreignId, object);
  if (ProjectVerbs.includes(verb)) {
    await feed.addActivity({
      actor: user,
      verb: verb,
      object: objectCollectionRef,
      time: new Date(),
      foreign_id: foreignId,
      to: [`projects_${verb}:${projectId}`],
    });
  }
  return;
};

const postStreamReaction = async (client: any, kind: string, activityId: string, data?: { text: string }) => {
  try {
    const reaction = await client.reactions.add(kind, activityId, data ? data : {});
    return reaction;
  } catch (e) {
    console.log(e);
    return;
  }
};

const postStreamChildReaction = async (client: any, kind: string, reactionId: string) => {
  try {
    const reaction = await client.reactions.addChild(kind, reactionId);
    return reaction;
  } catch (e) {
    return;
  }
};

const updateActivityCommentArray = (reactionActivity: ActivityReactionType, commentArray: ActivityReactionType[]) => {
  const comments = commentArray.reduce((arr, comment) => {
    if (comment.id === reactionActivity.parent) {
      return [
        ...arr,
        {
          ...comment,
          children_counts: {
            like: comment.children_counts.like ? comment.children_counts.like + 1 : 1,
          },
          latest_children: {
            like: comment.latest_children.like
              ? [reactionActivity, ...comment.latest_children.like]
              : [reactionActivity],
          },
        },
      ];
    }
    return [...arr, comment];
  }, []);

  return comments;
};

const removeLikeFromActivityCommentArray = (reactionId: string, activityCommentArray: ActivityReactionType[]) => {
  try {
    const newActivityCommentArray = activityCommentArray.reduce((arr, comment) => {
      if (comment.latest_children.like && comment.latest_children.like.length > 0) {
        const likeArray = comment.latest_children.like.filter((likeObj) => likeObj.id !== reactionId);
        const newActivityCommentArray = {
          ...comment,
          latest_children: { like: likeArray },
          children_counts: {
            like:
              comment.latest_children.like.length === likeArray.length
                ? comment.children_counts.like
                : comment.children_counts.like - 1,
          },
        };
        return [...arr, newActivityCommentArray];
      } else {
        return [...arr, comment];
      }
    }, []);
    // console.log(newActivityCommentArray)
    return newActivityCommentArray;
  } catch (e) {
    console.log(e);
    return activityCommentArray;
  }
};

const removeLikeFromActivityLikeArray = (reactionId: string, activityLikeArray: ActivityReactionType[]) => {
  try {
    const newActivityCommentArray = activityLikeArray.filter((reactionObj) => reactionObj.id !== reactionId);
    return newActivityCommentArray;
  } catch (e) {
    console.log(e);
    return activityLikeArray;
  }
};

const deleteStreamReaction = async (client: any, reactionId: string) => {
  await client.reactions.delete(reactionId);
  return;
};

const updateStreamUser = async (client: any, userId: string, userName: string, profileImage: string) => {
  await client.user(userId).update({
    name: userName,
    profileImage: profileImage,
  });
  return;
};

export {
  addActivity,
  deleteStreamReaction,
  failCallback,
  fetchStreamData,
  isEmittedActivityUserPost,
  postStreamChildReaction,
  postStreamReaction,
  removeLikeFromActivityCommentArray,
  removeLikeFromActivityLikeArray,
  successCallback,
  updateActivityCommentArray,
  updateStreamUser,
};
