/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AgreementType } from '~types/AgreementTypes';

interface AgreementsConfigState {
  agreement: AgreementType;
  isLoading: boolean;
}

const initialState: AgreementsConfigState = {
  agreement: {
    _id: '',
    type: '',
    isActivated: false,
    url: '',
    data: [],
  },
  isLoading: false,
};

const agreementsConfigSlice = createSlice({
  name: 'agreements-config',
  initialState,
  reducers: {
    createAgreement(state) {
      state.isLoading = true;
    },
    createAgreementSuccess(state, action: PayloadAction<string>) {
      state.agreement = { ...state.agreement, _id: action.payload };
      state.isLoading = false;
    },
    createAgreementError(state) {
      state.isLoading = false;
    },
    getAgreementConfig(state, action: PayloadAction<string>) {},
    updateAgreementConfig(state, action: PayloadAction<AgreementType>) {},
    setAgreementConfig(state, action: PayloadAction<AgreementType>) {
      state.agreement = action.payload;
    },
    uploadFile(state, action: PayloadAction<{ file: File }>) {},
  },
});

export const {
  createAgreement,
  createAgreementSuccess,
  createAgreementError,
  getAgreementConfig,
  updateAgreementConfig,
  setAgreementConfig,
  uploadFile,
} = agreementsConfigSlice.actions;
export default agreementsConfigSlice.reducer;
