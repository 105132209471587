import '~components/projects/ProjectBuilderImageUpload/ProjectBuilderImageUpload.scss';

import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { selectProjectBuilderImage } from '~features/project-builder/project-builder.selectors';
import { setImageFile } from '~features/project-builder/project-builder.slice';
import useAppSelector from '~hooks/useAppSelector';
import NewProjectImage from '~images/common/project/project_new.png';

const ProjectBuilderImageUpload = () => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const imageFile = useAppSelector(selectProjectBuilderImage);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!imageFile) {
      return setImagePreview(null);
    }

    const objectUrl = URL.createObjectURL(imageFile);

    setImagePreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [imageFile]);

  const setFile = (file: File) => {
    dispatch(setImageFile(file));
  };

  const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      dispatch(setImageFile(null));
      return;
    }

    setFile(e.target.files[0]);
  };

  function onDrop<T extends File>(acceptedFiles: T[]) {
    setFile(acceptedFiles[0]);
  }

  return (
    <Box className="CAKE__builder-image-upload">
      <Stack
        className={classNames({
          'CAKE__builder-image-upload__image': true,
          'CAKE__builder-image-upload__image--active': isDragActive,
        })}
        {...getRootProps()}
      >
        <input
          {...getInputProps()}
          accept="image/png, image/gif, image/jpeg"
          id="builder-image-upload"
          className="CAKE__builder-image-upload__input"
          type="file"
          onChange={onUploadFile}
        />

        <label htmlFor="builder-image-upload">
          <img
            src={imagePreview || NewProjectImage}
            alt="img"
            loading="lazy"
          />
        </label>
      </Stack>
    </Box>
  );
};

export default ProjectBuilderImageUpload;
