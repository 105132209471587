// eslint-disable-next-line absolutely-import/import
import './ProjectDrawerMenuButton.scss';

import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type ProjectDrawerMenuButtonProps = {
  title: string;
  link: string;
  icon: React.ReactNode;
  isActive: boolean;
  small: boolean;
};

const ProjectDrawerMenuButton = ({ title, link, icon, isActive, small }: ProjectDrawerMenuButtonProps) => {
  const navigate = useNavigate();

  return (
    <Tooltip
      title={title}
      placement="left"
    >
      <button
        onClick={() => navigate(link)}
        className={classNames({
          'CAKE__project-drawer-menu-button': true,
          'CAKE__project-drawer-menu-button--active': isActive,
          'CAKE__project-drawer-menu-button--hidden': small,
        })}
      >
        <div className={'CAKE__project-drawer-menu-button__icon'}>{icon}</div>
        <p>{title}</p>
      </button>
    </Tooltip>
  );
};

export default ProjectDrawerMenuButton;
