import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';

import { selectUserGravatar, selectUserNickname } from '~features/auth/auth.selectors';
import { logout } from '~features/auth/auth.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const UserMenu = () => {
  const dispatch = useAppDispatch();

  const userNickname = useAppSelector(selectUserNickname);
  const userAvatar = useAppSelector(selectUserGravatar);
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);

  const onLogout = () => {
    setMenuElement(null);
    dispatch(logout());
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton
        onClick={(e) => setMenuElement(e.currentTarget)}
        sx={{ p: 0 }}
      >
        <Avatar
          alt={userNickname}
          src={userAvatar}
        />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={menuElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menuElement)}
        onClose={() => setMenuElement(null)}
      >
        <MenuItem
          key={'logout-button'}
          onClick={onLogout}
        >
          <Typography textAlign="center">{'Logout'}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
