/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ContentItemType } from '~types/ContentType';

interface ContentState {
  content: Array<ContentItemType>;
  contentItem?: ContentItemType;
  newContentItemId: string;
  slugs: Array<{ id: string; slug: string }>;
  tags: Array<string>;
  isItemUploading: boolean;
}

const initialState = {
  content: [],
  contentItem: null,
  newContentItemId: '',
  slugs: [],
  tags: [],
  isItemUploading: false,
};

const projectContentSlice = createSlice({
  name: 'project-content',
  initialState,
  reducers: {
    getProjectContent(state: ContentState, action: PayloadAction<{ id: string }>) {},
    setProjectContent(state: ContentState, action: PayloadAction<Array<ContentItemType>>) {
      state.content = action.payload;
      state.slugs = action.payload.map((content: ContentItemType) => ({ id: content._id, slug: content.slug }));
      state.tags = action.payload.reduce((acc, curr: ContentItemType) => {
        const tags = curr.tags.filter((item) => !acc.includes(item));
        return [...acc, ...tags];
      }, []);
    },
    resetContentItem(state) {
      state.contentItem = initialState.contentItem;
    },
    getProjectContentItem(state, action: PayloadAction<string>) {},
    createNewContentItem(state) {},
    setProjectContentItem(state, action: PayloadAction<ContentItemType>) {
      state.contentItem = action.payload;
      state.tags = action.payload.tags.reduce(
        (acc, curr: string) => {
          if (!acc.includes(curr)) {
            return [...acc, curr];
          } else {
            return acc;
          }
        },
        [...state.tags],
      );
    },
    updateProjectContentItem(state, action: PayloadAction<ContentItemType>) {},
    uploadImage(state, action: PayloadAction<{ file: File; key: string }>) {},
    uploadIPFSImage(state, action: PayloadAction<{ file: File }>) {},
    deleteIPFSImage(state, action: PayloadAction<{ id: string }>) {},
    setNewContentItemId(state, action: PayloadAction<string>) {
      state.newContentItemId = action.payload;
    },
    setImageIsLoading(state) {
      state.isItemUploading = !state.isItemUploading;
    },
  },
});

export const {
  getProjectContent,
  setProjectContent,
  resetContentItem,
  getProjectContentItem,
  setProjectContentItem,
  createNewContentItem,
  updateProjectContentItem,
  uploadImage,
  uploadIPFSImage,
  deleteIPFSImage,
  setImageIsLoading,
  setNewContentItemId,
} = projectContentSlice.actions;
export default projectContentSlice.reducer;
