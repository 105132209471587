import type { PayloadAction } from '@reduxjs/toolkit';
import type { Task } from 'redux-saga';
import { all, call, join, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { readJSONFile, validateTokenMetadata } from '~features/collection-upload/collection-upload.helpers';
import { selectUploadQueue } from '~features/collection-upload/collection-upload.selectors';
import {
  addItemsToUploadQueue,
  addValidationErrors,
  removeItemFromUploadQueue,
  updateItemImage,
  updateItemImageSuccess,
  uploadItemAlreadyExists,
  uploadItemFinished,
  uploadItems,
  uploadItemsFinished,
} from '~features/collection-upload/collection-upload.slice';
import { selectProjectId, selectProjectSlug } from '~features/project-config/project-config.selectors';
import { postProtectedAPI } from '~features/utils/api/api.sagas';
import { S3_STORAGE_TOKENS_ARTWORK_DIR } from '~features/utils/s3storage/s3.storage.consts';
import { doesFileExistsOnS3, uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type CollectionItemType from '~types/CollectionItemType';
import type UploadTokenValidationType from '~types/token/UploadTokenValidationType';
import getS3TokenFilePath from '~utils/api/getS3TokenFilePath';

let uploadWorkers: Array<Task> = [];
let uploadWorkersCount = 0;
const MAX_UPLOAD_WORKERS_AMOUNT = 2;

function* getItem(): Iterator<any, UploadTokenValidationType> {
  const tokens: Array<UploadTokenValidationType> = yield select(selectUploadQueue);
  yield put(removeItemFromUploadQueue());
  if (tokens.length === 0) {
    return null;
  }
  return tokens[0];
}

function* checkErrors(token: UploadTokenValidationType): Iterator<any> {
  const upladErrors: Array<string> = [];
  if (!token.isMetadataValid) {
    upladErrors.push(`Metadata data not correct for token: ${token.name}`);
  } else if (Object.keys(token.metadata).length === 0) {
    upladErrors.push(`Metadata file is missing for token: ${token.name}`);
  }

  if (!token.artwork) {
    upladErrors.push(`Artwork is missing for token: ${token.name}`);
  }

  yield put(addValidationErrors(upladErrors));
}

function* uploadArtwork(token: UploadTokenValidationType, fileLocation: { path: string; dir: string }): Iterator<any> {
  const isFile: boolean = yield doesFileExistsOnS3(token.artwork?.name, fileLocation.path);
  if (!isFile) {
    yield console.log(`upload ${token.artwork.name}`);
    yield call(uploadFileToS3, token.artwork, fileLocation.dir);
  } else {
    yield put(addValidationErrors([`Artwork for token ${token.name} already exists`]));
  }
}

function* uploadMetadata(
  token: UploadTokenValidationType,
  fileLocation: { path: string; dir: string },
): Iterator<any, any> {
  const projectId: string = yield select(selectProjectId);
  const imageUrl = token.artwork ? `${fileLocation.path}/${token.artwork.name}` : token.metadata.image;
  const metadata = {
    ...token.metadata,
    name: token.name,
    image: imageUrl,
    isMetadataValid: token.isMetadataValid,
  };

  const res: Response = yield call(postProtectedAPI, `project/${projectId}/tokens`, metadata);
  const data = yield res.json();

  return data;
}

function* spawnUploadWorkerSaga(token: UploadTokenValidationType) {
  yield uploadWorkersCount++;

  const projectSlug: string = yield select(selectProjectSlug);
  const fileLocation: {
    dir: string;
    path: string;
  } = getS3TokenFilePath(projectSlug, S3_STORAGE_TOKENS_ARTWORK_DIR);

  yield call(checkErrors, token);

  const createdMetadata = yield call(uploadMetadata, token, fileLocation);
  if (token.artwork) {
    yield call(uploadArtwork, token, fileLocation);
  }

  yield uploadWorkersCount--;
  yield put(
    uploadItemFinished({
      ...token,
      metadata: createdMetadata,
    }),
  );
}

function* processFilesToUpload(files: Array<File>): Iterator<any, Array<UploadTokenValidationType>> {
  const tokens = yield files.reduce(async (acc, curr) => {
    const currAcc = await acc;
    const fileName: string = curr.name.split('.')[0];
    const isFileMetadata: boolean = curr.type.includes('json');
    const fileValue = isFileMetadata ? await readJSONFile(curr) : curr;
    const isMetadataValid: boolean = isFileMetadata
      ? await validateTokenMetadata(fileValue)
      : currAcc[fileName]?.isMetadataValid || false;
    return {
      ...currAcc,
      [fileName]: {
        ...currAcc[fileName],
        metadata: currAcc[fileName] || {},
        name: fileName,
        [isFileMetadata ? 'metadata' : 'artwork']: isFileMetadata && !isMetadataValid ? {} : fileValue,
        isMetadataValid,
      },
    };
  }, {});

  const sortedTokens: Array<UploadTokenValidationType> = Object.values<UploadTokenValidationType>(tokens).sort(
    (a: UploadTokenValidationType, b: UploadTokenValidationType) => a.name.localeCompare(b.name),
  );
  return sortedTokens;
}

function* uploadItemsSaga(action: PayloadAction<Array<File>>) {
  const validatedAndSortedTokens: Array<UploadTokenValidationType> = yield call(
    processFilesToUpload,
    action.payload || [],
  );
  yield put(addItemsToUploadQueue(validatedAndSortedTokens));

  let isNextToken = validatedAndSortedTokens.length > 0;
  if (!isNextToken) {
    return;
  }
  while (isNextToken) {
    const item = yield call(getItem);
    if (item) {
      if (uploadWorkersCount >= MAX_UPLOAD_WORKERS_AMOUNT) {
        yield take([uploadItemFinished.type, uploadItemAlreadyExists.type]);
      }
      const task = yield spawn(spawnUploadWorkerSaga, item);
      uploadWorkers = [...uploadWorkers.filter((item: Task) => item.isRunning()), task];
    } else {
      isNextToken = false;
    }
  }

  yield join(uploadWorkers);
  yield put(uploadItemsFinished());
}

function* updateItemImageSaga(action: PayloadAction<{ file: File; token: CollectionItemType }>) {
  const { file, token } = action.payload;
  const projectSlug: string = yield select(selectProjectSlug);
  const fileLocation: {
    dir: string;
    path: string;
  } = getS3TokenFilePath(projectSlug, S3_STORAGE_TOKENS_ARTWORK_DIR);

  const [, extension] = file.name.split('.');

  const updatedFile = new File([file], `${token.name}.${extension}`, {
    type: file.type,
  });
  yield call(uploadFileToS3, updatedFile, fileLocation.dir);

  yield put(
    updateItemImageSuccess({
      ...token,
      refresh: token.refresh || token.refresh + 1,
    }),
  );
}

export default function* collectionUploadSaga(): Iterator<any> {
  yield all([takeLatest(uploadItems.type, uploadItemsSaga), takeLatest(updateItemImage.type, updateItemImageSaga)]);
}
