import { TableCell, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { selectTokenGatePolicy } from '~features/token-gate-policy/token-gate-policy.selectors';
import { getTokenGatePolicyConfig } from '~features/token-gate-policy/token-gate-policy.slice';
import useAppSelector from '~hooks/useAppSelector';
import type DataRoomObjectType from '~types/DataRoomTypes';

type ObjectItemProps = {
  object: DataRoomObjectType;
  onClick: (content: DataRoomObjectType) => void;
};
const ObjectItem = ({ object, onClick }: ObjectItemProps) => {
  const dispatch = useDispatch();
  const tokenGatePolicy = useAppSelector(selectTokenGatePolicy);

  useEffect(() => {
    dispatch(getTokenGatePolicyConfig({ id: object.policyId }));
  }, []);

  return (
    <TableRow
      className={'CAKE__content-table__item'}
      hover
      onClick={() => onClick(object)}
      tabIndex={-1}
      key={object._id}
    >
      <TableCell
        component="th"
        scope="row"
        padding="none"
      >
        {object.ipfsFile.name || 'No file uploaded'}
      </TableCell>
      <TableCell align="right">{object.owner}</TableCell>
      <TableCell align="right">{object.status}</TableCell>
      <TableCell align="right">{tokenGatePolicy.policy}</TableCell>
      <TableCell align="right">{object.created}</TableCell>
    </TableRow>
  );
};

export default ObjectItem;
