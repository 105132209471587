/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CollectionType } from '~types/CollectionsType';

interface CollectionsConfigState {
  collection: CollectionType;
}

const initialState: CollectionsConfigState = {
  collection: {
    _id: null,
    tokenTypes: [],
    placeholderData: {
      name: '',
      image: '',
    },
    traitTypeName: '',
  },
};

const collectionConfigState = createSlice({
  name: 'collection-config',
  initialState,
  reducers: {
    getCollectionConfig(state, action: PayloadAction<string>) {},
    updateCollectionConfig(state, action: PayloadAction<{ collectionId: string; name: string; value: boolean }>) {},
    setCollectionConfig(state, action: PayloadAction<CollectionType>) {
      state.collection = action.payload;
    },
    updateCollectionConfigSuccess(state, action: PayloadAction<CollectionType>) {
      state.collection = { ...state.collection, ...action.payload };
    },
  },
});

export const { getCollectionConfig, updateCollectionConfig, setCollectionConfig, updateCollectionConfigSuccess } =
  collectionConfigState.actions;
export default collectionConfigState.reducer;
