import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ProjectType } from '~types/ProjectType';

interface ProjectsListState {
  all: Array<ProjectType>;
  isLoading: boolean;
  error: string | null;
}

const initialState: ProjectsListState = {
  all: [],
  isLoading: false,
  error: null,
};

const projectsListState = createSlice({
  name: 'projects-list',
  initialState,
  reducers: {
    getAllProjects(state) {
      state.isLoading = true;
      state.error = null;
    },
    setAllProjects(state, action: PayloadAction<Array<ProjectType>>) {
      state.all = action.payload;
      state.isLoading = false;
    },
  },
});

export const { getAllProjects, setAllProjects } = projectsListState.actions;
export default projectsListState.reducer;
