import '~components/dashboard/WalletDistributionChart/WalletDistributionChart.scss';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect } from 'react';

import { selectTopWalletHolders } from '~features/analytics/analytics.selectors';
import { getTopWalletHolders } from '~features/analytics/analytics.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { TopWalletHoldersType } from '~types/AnalyticsType';
import etherscanLinkBuilder from '~utils/url-builder/etherscanLinkBuilder/etherscanLinkBuilder';

type TopWalletHoldersTableProps = {
  chainId: number;
};

const TopWalletHoldersTable = ({ chainId }: TopWalletHoldersTableProps) => {
  const projectConfig = useAppSelector(selectProjectConfig);
  const topWalletsArray = useAppSelector(selectTopWalletHolders);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectConfig && projectConfig._id) {
      dispatch(
        getTopWalletHolders({
          projectId: projectConfig._id,
          contractAddress:
            chainId === 1
              ? projectConfig.contract.livenet.contractAddress
              : projectConfig.contract.testnet.contractAddress,
          refresh: true,
        }),
      );
    }

    // eslint-disable-next-line
  }, [chainId]);

  return (
    <div className={'CAKE__wallet-distribution-chart__top-holders-table__container'}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Wallet Address</TableCell>
              <TableCell align="right">Tokens</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topWalletsArray.map((row: TopWalletHoldersType) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                >
                  <a
                    className={'CAKE__wallet-distribution-chart__top-holders-table--link'}
                    href={etherscanLinkBuilder({ chainId, type: 'address', data: { address: row._id } })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row._id}
                  </a>
                </TableCell>
                <TableCell align="right">{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TopWalletHoldersTable;
