import type { RootState } from '~features';

const selectWeb3ConfigContractAbi = (state: RootState): string => state.web3Config.contractAbi;

const selectWeb3ConfigContractFunctionAbi = (state: RootState): any => state.web3Config.abi;

const selectWeb3ConfigContractReadFunctionAbi = (state: RootState): any => state.web3Config.abi.read;

const selectWeb3ConfigContractWriteFunctionAbi = (state: RootState): any => state.web3Config.abi.write;

const selectWeb3ConfigIsLoading = (state: RootState): boolean => state.web3Config.isLoading;

const selectWeb3ConfigError = (state: RootState): string | null => state.web3Config.error;

export {
  selectWeb3ConfigContractAbi,
  selectWeb3ConfigContractFunctionAbi,
  selectWeb3ConfigContractReadFunctionAbi,
  selectWeb3ConfigContractWriteFunctionAbi,
  selectWeb3ConfigError,
  selectWeb3ConfigIsLoading,
};
