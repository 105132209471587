import { TableCell, TableHead, TableRow } from '@mui/material';

const ProjectRecipientsTableHead = () => {
  const headTitles = ['Recipent', 'Share [%]', ''];
  return (
    <TableHead>
      <TableRow>
        {headTitles.map((name: string, idx: number) => (
          <TableCell
            key={`head-${name}-${idx}`}
            align={idx === 0 ? 'left' : 'right'}
            sx={{ fontWeight: 600 }}
          >
            {name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ProjectRecipientsTableHead;
