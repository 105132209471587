import 'react-markdown-editor-lite/lib/index.css';
import '~components/dashboard/ContentItemEditor/components/MarkdownSettings/MarkdownSettings.scss';

import { Grid } from '@mui/material';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';

type MarkdownSettingsProps = {
  data: string;
  handleChangeMarkdown: (text: string) => void;
};

const MarkdownSettings = ({ data, handleChangeMarkdown }: MarkdownSettingsProps) => {
  const mdParser = new MarkdownIt();

  return (
    <Grid
      container
      className={'CAKE__content-item__markdown-settings__container'}
      columns={{ xs: 2, sm: 4, md: 4 }}
    >
      <Grid
        item
        xs={2}
        sm={4}
        md={4}
      >
        <div>
          <Grid
            item
            xs={12}
          >
            <MdEditor
              className={'CAKE__content-item__markdown-settings__editor'}
              onChange={({ text }) => handleChangeMarkdown(text)}
              value={data}
              renderHTML={(text) => mdParser.render(text)}
              plugins={[
                'header',
                'font-bold',
                'font-italic',
                'list-unordered',
                'list-ordered',
                'block-quote',
                'block-wrap',
                'block-code-inline',
                'block-code-block',
                'image',
                'link',
                'clear',
                'logger',
                'mode-toggle',
                'full-screen',
              ]}
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default MarkdownSettings;
