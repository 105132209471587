import { Box, Stack } from '@mui/material';

import ProjectDrawer from '~components/projects/ProjectDrawer';

const ProjectPageLayout = ({ children }: React.PropsWithChildren<void>) => {
  return (
    <Stack height={'100%'}>
      <Box height={'100%'}>{children}</Box>
      <ProjectDrawer />
    </Stack>
  );
};

export default ProjectPageLayout;
