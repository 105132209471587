import web3 from 'web3';

const convertWeiToEth = (wei: any): string => {
  try {
    return `${web3.utils.fromWei(wei.toString(), 'ether')} ETH`;
  } catch (e) {
    return 'Unknown';
  }
};

export default convertWeiToEth;
