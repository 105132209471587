import { Card, Typography } from '@mui/material';

const FeedError = () => {
  return (
    <Card className={'CAKE__feed__activityCard--error'}>
      <Typography> We could not retrieve your project activity at this time</Typography>
    </Card>
  );
};

export default FeedError;
