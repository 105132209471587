import { TableCell, TableRow } from '@mui/material';

import type { ContentItemType } from '~types/ContentType';

type ContentItemProps = {
  content: ContentItemType;
  onClick: (content: ContentItemType) => void;
};
const ContentItem = ({ content, onClick }: ContentItemProps) => (
  <TableRow
    className={'CAKE__content-table__item'}
    hover
    onClick={() => onClick(content)}
    tabIndex={-1}
    key={content._id}
  >
    <TableCell
      component="th"
      scope="row"
      padding="none"
    >
      {content.title}
    </TableCell>
    <TableCell align="right">{content.author.name}</TableCell>
    <TableCell align="right">{content.status}</TableCell>
    <TableCell align="right">{content.created}</TableCell>
  </TableRow>
);

export default ContentItem;
