import PrivateRoutes from '~constants/routes/private-routes.consts';
import {
  AnalyticsIcon,
  ChecklistIcon,
  ContractIcon,
  DappSettingsIcon,
  GalleryIcon,
  NotificationsIcon,
  UtilityIcon,
} from '~icons/project/menu';

const menuItems = [
  {
    title: 'dApp Settings',
    link: (projectId: string) => `/dashboard/projects/${projectId}/dapp`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_DAPP,
    icon: <DappSettingsIcon />,
  },
  {
    title: 'Contract',
    link: (projectId: string) => `/dashboard/projects/${projectId}/contract`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_CONTRACT,
    icon: <ContractIcon />,
  },
  {
    title: 'Gallery',
    link: (projectId: string) => `/dashboard/projects/${projectId}/collection`,
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_COLLECTION,
    icon: <GalleryIcon />,
  },
  {
    title: 'Notifications',
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_OVERVIEW,
    link: (projectId: string) => `/dashboard/projects/${projectId}/`,
    icon: <NotificationsIcon />,
  },
  {
    title: 'Analytics',
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_ANALYTICS,
    link: (projectId: string) => `/dashboard/projects/${projectId}/analytics`,
    icon: <AnalyticsIcon />,
  },
  {
    title: 'Utility',
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_UTILITY,
    link: (projectId: string) => `/dashboard/projects/${projectId}/utility`,
    icon: <UtilityIcon />,
  },
  {
    title: 'Checklist',
    linkPattern: PrivateRoutes.DASHBOARD_PROJECT_OVERVIEW,
    link: (projectId: string) => `/dashboard/projects/${projectId}/`,
    icon: <ChecklistIcon />,
  },
];

export { menuItems };
