import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack } from '@mui/material';

type ProjectControlsProps = {
  onAddProject: () => void;
};

const ProjectControls = ({ onAddProject }: ProjectControlsProps) => {
  return (
    <Stack
      alignItems="flex-end"
      justifyContent="center"
    >
      <IconButton onClick={onAddProject}>
        <AddIcon color={'primary'} />
      </IconButton>
    </Stack>
  );
};

export default ProjectControls;
