/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  AggregateMintsTypes,
  TopWalletHoldersType,
  TransferDataType,
  WalletDistributionType,
} from '~types/AnalyticsType';

interface AnalyticsState {
  aggregateMints: Array<AggregateMintsTypes>;
  walletDistribution: Array<WalletDistributionType>;
  transferData: Array<TransferDataType>;
  topWalletHolders: Array<TopWalletHoldersType>;
}

const initialState: AnalyticsState = {
  aggregateMints: [],
  walletDistribution: [],
  transferData: [],
  topWalletHolders: [],
};

const analyticsState = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    getMintAnalytics(state, action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>) {},
    setMintAnalytics(state, action: PayloadAction<Array<AggregateMintsTypes>>) {
      state.aggregateMints = action.payload;
    },
    getWalletDistribution(
      state,
      action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
    ) {},
    setWalletDistribution(state, action: PayloadAction<Array<WalletDistributionType>>) {
      state.walletDistribution = action.payload;
    },
    getTransferData(state, action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>) {},
    setTransferData(state, action: PayloadAction<Array<TransferDataType>>) {
      state.transferData = action.payload;
    },
    getTopWalletHolders(
      state,
      action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
    ) {},
    setTopWalletHolders(state, action: PayloadAction<Array<TopWalletHoldersType>>) {
      state.topWalletHolders = action.payload;
    },
  },
});

export const {
  getMintAnalytics,
  setMintAnalytics,
  setWalletDistribution,
  getWalletDistribution,
  getTransferData,
  setTransferData,
  getTopWalletHolders,
  setTopWalletHolders,
} = analyticsState.actions;
export default analyticsState.reducer;
