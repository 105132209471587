import { Stack } from '@mui/material';

import DashboardCard from '~components/dashboard/DashboardCard';
import EditableTextField from '~components/inputs/EditableTextField';
import { selectProjectDappConfig } from '~features/project-config/project-config.selectors';
import { updateProjectDappConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

// eslint-disable-next-line absolutely-import/import
import { validateLink } from './SocialSettingsCard.herpers';

const SocialSettingsCard = () => {
  const dispatch = useAppDispatch();
  const dappConfig = useAppSelector(selectProjectDappConfig);
  const { social } = dappConfig;

  const onUpdateLink = (key: string, value: string) => {
    const modified = {
      ...social,
      [key]: value.length === 0 ? undefined : value,
    };
    dispatch(
      updateProjectDappConfig({
        dapp: {
          ...dappConfig,
          social: modified,
        },
        event: {
          content: {
            event: 'dApp Settings Update',
            description: `New ${key[0].toUpperCase() + key.slice(1)} link added`,
          },
          link: value,
        },
      }),
    );
  };

  return (
    <DashboardCard
      title={'Social links'}
      subtitle={'Please link your social media channels'}
    >
      <Stack gap={2}>
        <EditableTextField
          title="Discord"
          placeholder="https://discord.com"
          value={social.discord || ''}
          onUpdate={(value) => onUpdateLink('discord', value)}
          validator={validateLink}
        />
        <EditableTextField
          title="Twitter"
          placeholder="https://twitter.com"
          value={social.twitter || ''}
          onUpdate={(value) => onUpdateLink('twitter', value)}
          validator={validateLink}
        />
        <EditableTextField
          title="Instagram"
          placeholder="https://instagram.com"
          value={social.instagram || ''}
          onUpdate={(value) => onUpdateLink('instagram', value)}
          validator={validateLink}
        />
        <EditableTextField
          title="OpenSea"
          placeholder="https://opensea.io"
          value={social.opensea || ''}
          onUpdate={(value) => onUpdateLink('opensea', value)}
          validator={validateLink}
        />
        <EditableTextField
          title="LooksRare"
          placeholder="https://looksrare.org"
          value={social.looksrare || ''}
          onUpdate={(value) => onUpdateLink('looksrare', value)}
          validator={validateLink}
        />
        <EditableTextField
          title="Etherscan"
          placeholder="https://etherscan.io"
          value={social.etherscan || ''}
          onUpdate={(value) => onUpdateLink('etherscan', value)}
        />
        <EditableTextField
          title="Website"
          placeholder="https://yourwebsite.com"
          value={social.website || ''}
          onUpdate={(value) => onUpdateLink('website', value)}
          validator={validateLink}
        />
      </Stack>
    </DashboardCard>
  );
};

export default SocialSettingsCard;
