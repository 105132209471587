/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface PubsubState {
  subscriptions: {
    channels: Array<string>;
  };
}
const initialState = {
  subscriptions: {
    channels: [],
  },
};

const pubsubSlice = createSlice({
  name: 'pubsub',
  initialState,
  reducers: {
    initPubsub(state) {},
    subscribeChannels(state: PubsubState, action: PayloadAction<Array<string>>) {},
    unsubscribeChannels(state: PubsubState, action: PayloadAction<Array<string>>) {},
    updateChannelSubscribtion(state: PubsubState, action: PayloadAction<Array<string>>) {
      state.subscriptions.channels = action.payload;
    },
  },
});

export const { initPubsub, subscribeChannels, unsubscribeChannels, updateChannelSubscribtion } = pubsubSlice.actions;
export default pubsubSlice.reducer;
