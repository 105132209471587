import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';

const selectStreamClient = (state: RootState) => state.streams.client;

const selectStreamAuthToken = (state: RootState) => state.streams.authToken;

const selectStreamFeedOrder = (state: RootState) => state.streams.order;

const selectStreamFeedData = (state: RootState) => state.streams.feedDataMap;

const selectFeedOrderAndDataObject = createDraftSafeSelector(
  selectStreamFeedOrder,
  selectStreamFeedData,
  (feedOrder: string[], feedDataMap: any) => {
    return {
      order: feedOrder,
      data: feedDataMap,
    };
  },
);

const selectStreamFeedGroupIdToActivityIdMap = (state: RootState) => state.streams.activityGroupToActivityIdMap;

const selectStreamFeedActivityIdToGroupIdMap = (state: RootState) => state.streams.activityIdToActivityGroupIdMap;

const selectTokenImages = (state: RootState) => state.streams.feedTokenImagesMap;

const selectStreamNextPage = (state: RootState) => state.streams.next;

const selectUserPostAttachment = (state: RootState) => state.streams.attachment;

const selectIsNewFeedEventWaiting = (state: RootState) => state.streams.isNewFeedEventWaiting;

const selectIsProjectFeedUpdating = (state: RootState) => state.streams.isFeedUpdating;

const selectIsFeedLoading = (state: RootState) => state.streams.feedIsLoading;

const selectFeedError = (state: RootState) => state.streams.error;

export {
  selectFeedError,
  selectFeedOrderAndDataObject,
  selectIsFeedLoading,
  selectIsNewFeedEventWaiting,
  selectIsProjectFeedUpdating,
  selectStreamAuthToken,
  selectStreamClient,
  selectStreamFeedActivityIdToGroupIdMap,
  selectStreamFeedData,
  selectStreamFeedGroupIdToActivityIdMap,
  selectStreamFeedOrder,
  selectStreamNextPage,
  selectTokenImages,
  selectUserPostAttachment,
};
