import { CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import type { FeedAggregateActivityType } from '~types/Streams';
import isVideo from '~utils/regex/isVideoURL';

type CakeActivityContentProps = {
  data: FeedAggregateActivityType;
};

const CakeActivityContent = ({ data }: CakeActivityContentProps) => {
  return (
    <div>
      <Typography
        variant="body1"
        color="text"
      >
        {data.activities[0].object.data.content['event']}
      </Typography>
      <Typography fontSize={'14px'}>
        {data.activities[0].object.data.content['description'] && data.activities[0].object.data.content['description']}
      </Typography>
      <Typography pt={2}>
        {data.activities[0].object.data['link'] && (
          <Link
            className={'CAKE__feed__activityCard__content-link'}
            to={data.activities[0].object.data['link']}
          >
            <Typography
              sx={{ marginTop: '10px' }}
              variant="body1"
              color="text"
            >
              View here
            </Typography>
          </Link>
        )}
      </Typography>
      {data.activities[0].object.data['media'] && isVideo(data.activities[0].object.data['media']) ? (
        <video
          autoPlay
          loop
          muted={true}
          src={data.activities[0].object.data['media']}
        />
      ) : data.activities[0].object.data['media'] ? (
        <CardMedia
          className={'CAKE__feed__activityCard__content-post-image'}
          component="img"
          height="194"
          sx={{ objectFit: 'contain' }}
          image={data.activities[0].object.data['media']}
          alt={'new-activity-image'}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CakeActivityContent;
