import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { CardMedia, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { selectProjectId } from '~features/project-config/project-config.selectors';
import { selectTokenImages } from '~features/streams/streams.selectors';
import { addTokenImageToMap, fetchProjectTokenImages } from '~features/streams/streams.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { FeedAggregateActivityType } from '~types/Streams';
import convertWeiToEth from '~utils/web3/conversion/eth-converter';

type MintActivityContentProps = {
  data: FeedAggregateActivityType;
};

const MintActivityContent = ({ data }: MintActivityContentProps) => {
  const dispatch = useAppDispatch();
  const tokenImageMap = useAppSelector(selectTokenImages);
  const projectId = useAppSelector(selectProjectId);
  const [tokenArrayIdx, setTokenArrayIdx] = useState<number>(0);

  useEffect(() => {
    data.activities.forEach((activity) => {
      if (activity.object.data.content) {
        if (activity.object.data.content['tokenId'] || activity.object.data.content['tokenId'] === 0) {
          if (!Object.keys(tokenImageMap).includes(activity.object.data.content['tokenId'])) {
            dispatch(
              addTokenImageToMap({
                tokenId: activity.object.data.content['tokenId'],
                image: '',
              }),
            );
          }
        }
      }
    });
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchProjectTokenImages());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleIncrement = () => {
    setTokenArrayIdx(tokenArrayIdx + 1);
  };
  const handleDecrement = () => {
    setTokenArrayIdx(tokenArrayIdx - 1);
  };

  return (
    <div>
      <div className={'CAKE__feed__activityCard__content-transfer-details-container'}>
        <div className={'CAKE__feed__activityCard__content-transfer-details'}>
          <div>
            <Typography
              sx={{ marginBottom: '10px', fontSize: '20px' }}
              variant="body1"
              color="text"
            >
              {`${data.activities.length >= 10 ? '10+' : data.activities.length}
            ${data.activities.length > 1 ? 'mints have' : 'mint has'} occured in the last hour.`}
            </Typography>
            <Typography
              variant="body1"
              color="text"
            >
              {`TokenId: ${
                data.activities[tokenArrayIdx].object.data.content
                  ? data.activities[tokenArrayIdx].object.data.content['tokenId']
                  : 'Unknown'
              }`}
            </Typography>
            <Typography
              variant="body1"
              color="text"
            >
              {`Value: ${
                data.activities[tokenArrayIdx].object.data.content
                  ? convertWeiToEth(data.activities[tokenArrayIdx].object.data.content['value'])
                  : 'Unknown'
              }`}
            </Typography>
          </div>
          <div className={'CAKE__feed__activityCard__content-transfer-image-container'}>
            <IconButton
              onClick={handleDecrement}
              size="small"
              disabled={tokenArrayIdx === 0 ? true : false}
            >
              <ArrowBackIosNewRoundedIcon sx={{ color: tokenArrayIdx === 0 ? 'transparent' : 'black' }} />
            </IconButton>
            <CardMedia
              className={'CAKE__feed__activityCard__content-post-image'}
              component="img"
              height="194"
              sx={{ objectFit: 'contain' }}
              image={
                data.activities[tokenArrayIdx].object.data.content &&
                tokenImageMap[data.activities[tokenArrayIdx].object.data.content['tokenId']]
                  ? tokenImageMap[data.activities[tokenArrayIdx].object.data.content['tokenId']]
                  : 'https://cakeapp-assets.s3.us-west-2.amazonaws.com/cakeapp-logo.png'
              }
              alt={data.activities[0].id}
            />
            <IconButton
              onClick={handleIncrement}
              size="small"
              disabled={tokenArrayIdx === data.activities.length - 1 ? true : false}
            >
              <ArrowForwardIosRoundedIcon
                sx={{ color: tokenArrayIdx === data.activities.length - 1 ? 'transparent' : 'black' }}
              />
            </IconButton>
          </div>
          <Link
            className={'CAKE__feed__activityCard__content-link'}
            to={`/dashboard/projects/${projectId}/analytics`}
          >
            <Typography
              sx={{ marginTop: '10px' }}
              variant="body1"
              color="text"
            >
              View logs
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MintActivityContent;
