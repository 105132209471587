enum FileUploadKey {
  LIGHT_BACKGROUND_LOGO = 'LIGHT_BACKGROUND_LOGO',
  LIGHT_PRIMARY_LOGO = 'LIGHT_PRIMARY_LOGO',
  LIGHT_SECONDARY_LOGO = 'LIGHT_SECONDARY_LOGO',
  LIGHT_TITLE_LOGO = 'LIGHT_TITLE_LOGO',
  DARK_BACKGROUND_LOGO = 'DARK_BACKGROUND_LOGO',
  DARK_PRIMARY_LOGO = 'DARK_PRIMARY_LOGO',
  DARK_SECONDARY_LOGO = 'DARK_SECONDARY_LOGO',
  DARK_TITLE_LOGO = 'DARK_TITLE_LOGO',
  FAVICON = 'FAVICON',
  CONTENT_ITEM_AVATAR = 'CONTENT_ITEM_AVATAR',
  CONTENT_ITEM_BANNER = 'CONTENT_ITEM_BANNER',
}

export default FileUploadKey;
