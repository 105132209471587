import { Button, List, Typography } from '@mui/material';

import DashboardCard from '~components/dashboard/DashboardCard';
import DropListItem from '~components/dashboard/ProjectDropsCard/components/DropListItem';
import PrivateRoutes from '~constants/routes/private-routes.consts';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { selectProjectDropsManagerDrops } from '~features/project-drops-manager/project-drops-manager.selectors';
import useAppSelector from '~hooks/useAppSelector';
import useSmartNavigate from '~hooks/useSmartNavigate';
import type { BatterDrop } from '~types/DropType';

const ProjectDropsCard = () => {
  const drops = useAppSelector(selectProjectDropsManagerDrops);
  const projectConfig = useAppSelector(selectProjectConfig);
  const { navigate, createHref } = useSmartNavigate();

  const onEditDrop = (dropId: string) => {
    navigate(PrivateRoutes.DASHBOARD_PROJECT_DROP, {
      id: projectConfig._id,
      drop_id: dropId,
    });
  };

  return (
    <DashboardCard subtitle={'Manage Merch Drops'}>
      {drops.length <= 0 && <Typography marginBottom={4}>You have not created any drops yet.</Typography>}

      {drops.length > 0 && (
        <>
          <Typography
            variant="h6"
            fontWeight={700}
          >
            Drops
          </Typography>

          <List>
            {drops.map((drop: BatterDrop) => (
              <DropListItem
                drop={drop}
                onEditDrop={onEditDrop}
                key={drop._id}
              />
            ))}
          </List>
        </>
      )}

      <Button
        href={createHref(PrivateRoutes.DASHBOARD_PROJECT_NEW_DROP, { id: projectConfig._id })}
        variant="contained"
      >
        Create New Drop
      </Button>
    </DashboardCard>
  );
};

export default ProjectDropsCard;
