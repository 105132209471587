import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

type FAQDeletionModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const FAQDeletionModal = ({ isOpen, onCancel, onConfirm }: FAQDeletionModalProps) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogContent>
        <Typography>{'Do you want to delete?'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={onConfirm}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FAQDeletionModal;
