import '~views/App/App.scss';

import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import Notifications from '~components/miscs/Notifications/Notifications';
import Redirect from '~components/navigation/Redirect';
import type { RouteType } from '~constants/routes';
import { privateRoutes, publicRoutes } from '~constants/routes';
import PrivateRoute from '~views/App/PrivateRoute';
import PublicRoute from '~views/App/PublicRoute';
import NotFoundView from '~views/NotFoundView';

const App = () => {
  return (
    <Fragment>
      <Notifications />
      <Routes>
        <Route
          path="/"
          element={<Redirect to={'https://trycake.xyz'} />}
        />
        {publicRoutes.map(({ path, Component, LayoutComponent }: RouteType) => (
          <Route
            key={path}
            path={path}
            element={
              <PublicRoute>
                <LayoutComponent>
                  <Component />
                </LayoutComponent>
              </PublicRoute>
            }
          />
        ))}
        {privateRoutes.map(({ path, Component, LayoutComponent }: RouteType) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute>
                <LayoutComponent>
                  <Component />
                </LayoutComponent>
              </PrivateRoute>
            }
          />
        ))}
        <Route
          path="*"
          element={
            <PublicRoute>
              <NotFoundView />
            </PublicRoute>
          }
        />
      </Routes>
    </Fragment>
  );
};

export default App;
