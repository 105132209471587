type etherscanLinkBuilderDataType = {
  address?: string;
  transactionHash?: string;
  tokenId?: number;
};

type etherscanLinkBuilderProps = {
  chainId: number | string;
  type: string;
  data: etherscanLinkBuilderDataType;
};

const etherscanLinkBuilder = ({ chainId, type, data }: etherscanLinkBuilderProps) => {
  const getBaseURL = () => {
    switch (chainId) {
      case 1:
        return process.env.REACT_APP_MAINNET_ETHERSCAN_BASE_URL;
      default:
        return process.env.REACT_APP_GOERLI_ETHERSCAN_BASE_URL;
    }
  };

  const getURLPath = () => {
    switch (type) {
      case 'tokenId':
        return `token/${data.address}?a=${data.tokenId}`;
      case 'txn':
        return `tx/${data.transactionHash}`;
      case 'address':
        return `address/${data.address}`;
      default:
        return;
    }
  };

  return `${getBaseURL()}/${getURLPath()}`;
};

export default etherscanLinkBuilder;
