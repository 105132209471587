import '~components/collections/CollectionViewer/components/CollectionViewerHeader/CollectionViewerHeader.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';

import { selectCollectionTokensIsSelection } from '~features/collection-items/collection-items.selectors';
import { deleteSelectedCollectionItems } from '~features/collection-items/collection-items.slice';
import { selectIsCollectionUpladInProgress } from '~features/collection-upload/collection-upload.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const CollectionViewerHeader = () => {
  const isSelection = useAppSelector(selectCollectionTokensIsSelection);
  const isUploading = useAppSelector(selectIsCollectionUpladInProgress);
  const dispatch = useAppDispatch();

  const onBulkDelete = () => {
    dispatch(deleteSelectedCollectionItems());
  };
  return (
    <Box className={'CAKE__collection-viewer-header'}>
      <Box className={'CAKE__collection-viewer-header__left-container'}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          disabled={!isSelection}
          onClick={onBulkDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box className={'CAKE__collection-viewer-header__right-container'}>
        {isUploading && (
          <>
            <CircularProgress size={24} />
            <Typography variant={'body2'}>Uploading...</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CollectionViewerHeader;
