import type { Auth0Client } from '@auth0/auth0-spa-js';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AllEffect, CallEffectDescriptor, ForkEffect, SimpleEffect } from 'redux-saga/effects';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { getAuthClient } from '~features/auth/auth.client';
import { login, logout, setIsLoggedIn, setSessionId, setUser, signup } from '~features/auth/auth.slice';
import Monitoring from '~utils/monitoring';

function* setUserSaga(): Iterator<any> {
  const authClient: Auth0Client = getAuthClient();
  const user: string | any | undefined = yield call([authClient, 'getUser']);
  Monitoring.setUser(user);
  yield put(setUser(user));
}

function* initSaga(): Iterator<any> {
  const isAuthenticated: boolean | undefined = yield call(isAuthenticatedSaga);
  const sessionId: string = uuidv4();
  yield put(setSessionId(sessionId));
  Monitoring.setSession(sessionId);
  if (isAuthenticated) {
    yield call(setUserSaga);
  }
  yield put(setIsLoggedIn(!!isAuthenticated));
}

function* getTokenSaga(): Iterator<any, string | null, string | undefined> {
  const authClient: Auth0Client = getAuthClient();
  try {
    const token: string | undefined = yield call([authClient, 'getTokenSilently']);
    return token || null;
  } catch (error) {
    return null;
  }
}

function* isAuthenticatedSaga(): Iterator<any, boolean, string> {
  const token: string = yield call(getTokenSaga);
  return token ? true : false;
}

function* loginSaga(): Iterator<any> {
  const authClient: Auth0Client = getAuthClient();
  yield call([authClient, 'loginWithRedirect']);
  const isAuthenticated: boolean = yield call(isAuthenticatedSaga);
  yield put(setIsLoggedIn(!!isAuthenticated));
  if (isAuthenticated) {
    yield call(setUserSaga);
  }
}

function* signupSaga(action: PayloadAction<string>): Iterator<any> {
  const invitationId = action.payload;
  const authClient: Auth0Client = getAuthClient();

  yield call([authClient, 'loginWithRedirect'], { fragment: `invitation:${invitationId}` });
}

function* logoutSaga(): Iterator<any> {
  const authClient: Auth0Client = getAuthClient();
  yield call([authClient, 'logout']);
  yield put(setIsLoggedIn(false));
}

export default function* authSaga(): Generator<
  AllEffect<unknown> | ForkEffect<any | never> | SimpleEffect<'CALL', CallEffectDescriptor<void>>,
  void,
  void
> {
  yield fork(initSaga);
  yield all([
    yield takeLatest(login.type, loginSaga),
    yield takeLatest(logout.type, logoutSaga),
    yield takeLatest(signup.type, signupSaga),
  ]);
}

export { getTokenSaga, isAuthenticatedSaga };
