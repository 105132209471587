const initAbiFunctionState = (contractAbi: string) => {
  const readStore = [];
  const writeStore = [];

  if (contractAbi) {
    JSON.parse(contractAbi).forEach((abi) => {
      if (abi.type === 'function' && abi.stateMutability === 'view') {
        abi.data = null;
        readStore.push(abi);
      } else if (abi.type === 'function' && ['nonpayable', 'payable'].includes(abi.stateMutability)) {
        abi.data = null;
        writeStore.push(abi);
      }
    });
  }

  return { readStore, writeStore };
};

const confirmTransaction = (web3, txnHash) => {
  // see: https://ethereum.stackexchange.com/questions/67232/how-to-wait-until-transaction-is-confirmed-web3-js

  return new Promise((resolve) => {
    let interval;

    try {
      interval = setInterval(() => {
        web3.eth.getTransactionReceipt(txnHash, (error, receipt) => {
          if (error) {
            resolve({ status: 'error' });
            clearInterval(interval);
          }
          if (receipt && receipt.status) {
            clearInterval(interval);
            console.log({ status: 'success' });
            resolve({ status: 'success' });
          }
          if (receipt && receipt.status === false) {
            console.log({ status: 'fail' });
            clearInterval(interval);
            resolve({ status: 'fail' });
          }
        });
      }, 5000);
    } catch (err) {
      console.log(err);
      resolve({ status: 'error' });
    }
  });
};

export { confirmTransaction, initAbiFunctionState };
