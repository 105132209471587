import '~components/collections/CollectionItemModal/components/CollectionItemContent/CollectionItemContent.scss';

import AddIcon from '@mui/icons-material/Add';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ImageIcon from '@mui/icons-material/Image';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';

import CollectionItemImage from '~components/collections/CollectionItemImage';
import TraitEditModal from '~components/collections/CollectionItemModal/components/TraitEditModal';
import TraitsTable from '~components/collections/CollectionItemModal/components/TraitsTable';
import EditableTextField from '~components/inputs/EditableTextField';
import { updateItemImage } from '~features/collection-upload/collection-upload.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import type CollectionItemType from '~types/CollectionItemType';
import type TraitType from '~types/token/TraitType';

type CollectionItemContentProps = {
  token: CollectionItemType;
  onChange: (token: CollectionItemType) => void;
};

const CollectionItemContent = ({ token, onChange }: CollectionItemContentProps) => {
  const dispatch = useAppDispatch();
  const { attributes, description, image, tokenId, name } = token;
  const [isImage, setIsImage] = useState<boolean>(!!image);
  const [isTraitModal, setIsTraitModal] = useState<boolean>(false);
  const [editTrait, setEditTrait] = useState<{ trait: TraitType; index: number }>(null);

  const onTraitModalClose = () => {
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onEditTrait = (item: TraitType, idx: number) => {
    setEditTrait({ trait: item, index: idx });
    setIsTraitModal(true);
  };

  const onConfirmTraitEdit = (item: TraitType) => {
    if (editTrait && !Number.isNaN(editTrait?.index)) {
      onTraitEdited(item, editTrait.index);
    } else {
      onTraitAdded(item);
    }
    setIsTraitModal(false);
    setEditTrait(null);
  };

  const onAddTrait = () => {
    setIsTraitModal(true);
    setEditTrait(null);
  };

  const onTraitAdded = (item: TraitType) => {
    onChange({
      ...token,
      attributes: [...attributes, item],
    });
  };

  const onTraitEdited = (item: TraitType, idx: number) => {
    const newAttributes = [...attributes];
    newAttributes[idx] = item;
    onChange({
      ...token,
      attributes: newAttributes,
    });
  };

  const onDeleteTrait = (item: TraitType, idx: number) => {
    const newAttributes = [...attributes];
    newAttributes.splice(idx, 1);
    onChange({
      ...token,
      attributes: newAttributes,
    });
  };

  const onUpdateDescription = (description: string) => {
    onChange({
      ...token,
      description: description.length === 0 ? undefined : description,
    });
  };

  const onUpdateImageFile = (files: Array<File>) => {
    const file: File = files[0];
    dispatch(updateItemImage({ file, token }));
  };

  return (
    <Box className={'CAKE__collection-item-content'}>
      <Box className={'CAKE__collection-item-content__top-container'}>
        <Box
          className={classNames({
            'CAKE__collection-item-content__image': true,
            'CAKE__collection-item-content__image--no': !isImage,
          })}
        >
          <CollectionItemImage
            image={image}
            isImage={isImage}
            onImageError={() => setIsImage(false)}
            isDropzone
            onDropImage={onUpdateImageFile}
          />
        </Box>
        <Box className={'CAKE__collection-item-content__properties'}>
          <Typography
            paddingBottom={4}
            variant={'h6'}
            color={'primary'}
          >{`Token name: ${name}`}</Typography>
          <Box className={'CAKE__collection-item-content__properties__chips'}>
            <Chip
              label="Image"
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => window.open(token.image, '_blank')}
              icon={<ImageIcon />}
              disabled={!isImage}
            />
            <Chip
              label={`tokenId: ${tokenId < 0 ? 'N/A' : tokenId}`}
              size="small"
              variant="outlined"
              color="primary"
              icon={<FingerprintIcon />}
            />
          </Box>
          <EditableTextField
            title="Description"
            value={description || ''}
            onUpdate={onUpdateDescription}
          />
        </Box>
      </Box>
      <Box className={'CAKE__collection-item-content__traits'}>
        <Box className={'CAKE__collection-item-content__traits__title-container'}>
          <Typography
            variant={'h6'}
            color={'primary'}
          >
            Traits
          </Typography>
          <IconButton onClick={onAddTrait}>
            <AddIcon color={'primary'} />
          </IconButton>
        </Box>
        <TraitsTable
          traits={attributes}
          onEdit={onEditTrait}
          onDelete={onDeleteTrait}
        />
        {isTraitModal && (
          <TraitEditModal
            isOpen={isTraitModal}
            trait={editTrait?.trait}
            onConfirm={onConfirmTraitEdit}
            onCancel={onTraitModalClose}
          />
        )}
      </Box>
    </Box>
  );
};

export default CollectionItemContent;
