import type TokenMetadataType from '~types/token/TokenMetadataType';
import type TraitType from '~types/token/TraitType';

const readFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

const validateTraits = (traits: Array<TraitType>): boolean => {
  if (Array.isArray(traits)) {
    for (let i = 0; i < traits.length; i++) {
      if (!traits[i].value || (typeof traits[i].value !== 'string' && typeof traits[i].value !== 'number')) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
};

const readJSONFile = async (file: File) => {
  const data: any = await readFile(file);

  try {
    const metadata: any = JSON.parse(data || '');
    return metadata;
  } catch (e) {
    console.error(e);
  }
  return null;
};

const validateTokenMetadata = async (metadata: TokenMetadataType) => {
  if (Number.isNaN(metadata.tokenId) || metadata.tokenId < 0) {
    return false;
  }
  if (!validateTraits(metadata.attributes)) {
    return false;
  }
  return true;
};

export { readJSONFile, validateTokenMetadata };
