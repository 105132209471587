import { TableCell, TableHead, TableRow } from '@mui/material';

const ProjectsTableHead = () => {
  const headTitles = ['Name', 'Mint Limit', 'Price', 'Creation date'];
  return (
    <TableHead>
      <TableRow>
        {headTitles.map((name: string, idx: number) => (
          <TableCell
            key={`head-${name}-${idx}`}
            align={idx === 0 ? 'left' : 'right'}
          >
            {name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ProjectsTableHead;
