import type { RootState } from '~features';
import type DataRoomObjectType from '~types/DataRoomTypes';

const selectProjectDataRoomObjects = (state: RootState): Array<DataRoomObjectType> => state.projectDataRoom.objects;

const selectProjectDataRoomObject = (state: RootState): DataRoomObjectType => state.projectDataRoom.object;

const selectProjectDataRoomCategories = (state: RootState): Array<string> => state.projectDataRoom.categories;

const selectIsObjectLoading = (state: RootState): boolean => state.projectDataRoom.isLoading;

const selectNewObjectId = (state: RootState): string => state.projectDataRoom.newObjectId;

export {
  selectIsObjectLoading,
  selectNewObjectId,
  selectProjectDataRoomCategories,
  selectProjectDataRoomObject,
  selectProjectDataRoomObjects,
};
