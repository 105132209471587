import { Stack } from '@mui/material';

import ColorPicker from '~components/inputs/ColorPicker';
import type { AppearanceColorType } from '~types/ProjectType';

type AppearanceModeSettingsProps = {
  colors?: AppearanceColorType;
  onUpdateColors: (value: AppearanceColorType) => void;
};

const AppearanceModeSettings = ({ colors, onUpdateColors }: AppearanceModeSettingsProps) => {
  return (
    <Stack
      flexDirection={'row'}
      gap={'20px'}
    >
      <ColorPicker
        color={colors?.primary}
        title={'Primary color'}
        onUpdate={(color) => onUpdateColors({ ...colors, primary: color })}
      />
      <ColorPicker
        color={colors?.background}
        title={'Background color'}
        onUpdate={(color) => onUpdateColors({ ...colors, background: color })}
      />
      <ColorPicker
        color={colors?.header}
        title={'Header color'}
        onUpdate={(color) => onUpdateColors({ ...colors, header: color })}
      />
      <ColorPicker
        color={colors?.footer}
        title={'Footer color'}
        onUpdate={(color) => onUpdateColors({ ...colors, footer: color })}
      />
    </Stack>
  );
};

export default AppearanceModeSettings;
