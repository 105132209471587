import { TableCell, TableHead, TableRow } from '@mui/material';

const DataRoomTableHead = () => {
  const headTitles = ['File', 'Owner', 'Status', 'Policy', 'Created'];
  return (
    <TableHead>
      <TableRow>
        {headTitles.map((name: string, idx: number) => (
          <TableCell
            key={`head-${name}-${idx}`}
            align={idx === 0 ? 'left' : 'right'}
          >
            {name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default DataRoomTableHead;
