import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { selectProjectId } from '~features/project-config/project-config.selectors';
import { selectProjectDropsManagerDrops } from '~features/project-drops-manager/project-drops-manager.selectors';
import {
  cancelProjectDropsManagerLoad,
  getProjectDropsManager,
  setProjectDropsManager,
  setProjectDropsManagerError,
} from '~features/project-drops-manager/project-drops-manager.slice';
import { subscribeChannels } from '~features/pubsub/pubsub.slice';
import { fetchAPI } from '~features/utils/api/api.sagas';
import type { BatterDrop } from '~types/DropType';

function* fetchProjectDropsManagerSaga(action: PayloadAction<{ id: string }>): Iterator<any> {
  const { id } = action.payload;

  const currentId: string = yield select(selectProjectId);
  const currentDrops: BatterDrop[] = yield select(selectProjectDropsManagerDrops);

  if (id === currentId && currentDrops.length) {
    yield put(cancelProjectDropsManagerLoad());
    return;
  }

  try {
    const res: Response = yield call(fetchAPI, `public/project/${id}/drops`, {
      options: ['withProducts'],
    });
    const data: any = yield res.json();

    if (data) {
      yield put(setProjectDropsManager(data));
      yield put(subscribeChannels([`project_${data._id}`]));
    } else {
      yield put(setProjectDropsManagerError('Project not found'));
    }
  } catch (e) {
    console.error(e);
    yield put(setProjectDropsManagerError(e.toString()));
  }
}

export default function* projectDropsManagerSaga(): Iterator<any> {
  yield all([yield takeLatest(getProjectDropsManager.type, fetchProjectDropsManagerSaga)]);
}
