import { all } from 'redux-saga/effects';

import agreementsConfigSaga from '~features/agreements-config/agreements-config.sagas';
import analyticsSaga from '~features/analytics/analytics.sagas';
import authSaga from '~features/auth/auth.sagas';
import collectionSaga from '~features/collection-config/collection-config.sagas';
import collectionItemsSaga from '~features/collection-items/collection-items.sagas';
import collectionUploadSaga from '~features/collection-upload/collection-upload.sagas';
import contractDeployerSaga from '~features/contract-deployer/contract-deployer.sagas';
import projectBuilderSaga from '~features/project-builder/project-builder.sagas';
import projectConfigSaga from '~features/project-config/project-config.sagas';
import projectContentSaga from '~features/project-content/project-content.sagas';
import projectDataRoomSaga from '~features/project-data-room/project-data-room.sagas';
import projectDropSaga from '~features/project-drop/project-drop.sagas';
import projectDropsManagerSaga from '~features/project-drops-manager/project-drops-manager.sagas';
import projectsListSaga from '~features/projects-list/projects-list.sagas';
import pubsubSaga from '~features/pubsub/pubsub.sagas';
import streamsSaga from '~features/streams/streams.sagas';
import tokenGatePolicyConfigSagas from '~features/token-gate-policy/token-gate-policy.sagas';
import userInvitationSaga from '~features/user-invitation/user-invitation.sagas';
import web3Saga from '~features/web3/web3.sagas';
import whitelistSaga from '~features/whitelist-config/whitelist-config.sagas';

function* rootSaga() {
  yield all([
    agreementsConfigSaga(),
    analyticsSaga(),
    authSaga(),
    collectionItemsSaga(),
    collectionSaga(),
    collectionUploadSaga(),
    contractDeployerSaga(),
    projectBuilderSaga(),
    projectConfigSaga(),
    projectContentSaga(),
    projectDataRoomSaga(),
    projectDropSaga(),
    projectDropsManagerSaga(),
    projectsListSaga(),
    pubsubSaga(),
    tokenGatePolicyConfigSagas(),
    streamsSaga(),
    userInvitationSaga(),
    web3Saga(),
    whitelistSaga(),
  ]);
}

export default rootSaga;
