import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  callDeployContract,
  callDeployContractError,
  callDeployContractSuccess,
  callVerifyContract,
  callVerifyContractError,
  callVerifyContractSuccess,
} from '~features/contract-deployer/contract-deployer.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { postProtectedAPI } from '~features/utils/api/api.sagas';
import type { ProjectType } from '~types/ProjectType';

function* callDeployContractSaga(
  action: PayloadAction<{ projectId: string; network: string; chainId?: number }>,
): Iterator<any> {
  const { projectId, network, chainId } = action.payload;

  const baseMessage = 'Deploying Contract to';
  const networkMessage = chainId === 1 ? 'Ethereum Mainnet' : 'Goerli Testnet';
  yield put(
    addNotification({
      message: `${baseMessage} ${networkMessage}.`,
      severity: 'info',
      duration: 5000,
    }),
  );

  try {
    const path = `contract/${projectId}/deploy`;
    const payload = { network };
    const res: Response = yield call(postProtectedAPI, path, payload);
    yield res.json();
    if (res.status !== 200) {
      yield put(callDeployContractError('Deployment failed'));
    }
  } catch (e) {
    console.log(e);
    yield put(callDeployContractError(e.toString()));
  }
}

function* callDeployContractSuccessSaga(
  action: PayloadAction<{ network: string; contractAddress: string; projectId: string }>,
) {
  const project: ProjectType = yield select(selectProjectConfig);
  yield put(getProjectConfig({ id: project._id, force: true }));
  yield put(callVerifyContract({ ...action.payload }));
}

function* callDeployContractErrorSaga(action: PayloadAction<string>) {
  yield put(
    addNotification({
      message: action.payload,
      severity: 'error',
      duration: 5000,
    }),
  );
}

function* callVerifyContractSaga(
  action: PayloadAction<{ network: string; contractAddress: string; projectId: string }>,
): Iterator<any> {
  const { network, contractAddress, projectId } = action.payload;
  yield put(
    addNotification({
      message: 'Verifying Contract on Etherscan',
      severity: 'info',
      duration: 60000,
    }),
  );

  try {
    const path = `contract/${projectId}/verify`;
    const payload = { network, contractAddress };
    const res: Response = yield call(postProtectedAPI, path, payload);
    yield res.json();
    if (res.status !== 200) {
      yield put(callVerifyContractError({ network, error: 'Contract verification failed' }));
    }
  } catch (e) {
    console.log(e);
    yield put(callVerifyContractError({ network, error: e.toString() }));
  }
}

function* callVerifyContractSuccessSaga() {
  yield put(
    addNotification({
      message: 'Contract verification succeed',
      severity: 'success',
      duration: 5000,
    }),
  );
}

function* callVerifyContractErrorSaga(action: PayloadAction<{ networ: string; error: string }>) {
  yield put(
    addNotification({
      message: action.payload.error,
      severity: 'error',
      duration: 5000,
    }),
  );
}

export default function* contractDeployerSaga(): Iterator<any> {
  yield all([
    yield takeLatest(callDeployContract.type, callDeployContractSaga),
    yield takeLatest(callDeployContractSuccess.type, callDeployContractSuccessSaga),
    yield takeLatest(callDeployContractError.type, callDeployContractErrorSaga),
    yield takeLatest(callVerifyContract.type, callVerifyContractSaga),
    yield takeLatest(callVerifyContractError.type, callVerifyContractErrorSaga),
    yield takeLatest(callVerifyContractSuccess.type, callVerifyContractSuccessSaga),
  ]);
}
