import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select } from 'redux-saga/effects';

import { selectUserNickname } from '~features/auth/auth.selectors';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectId } from '~features/project-config/project-config.selectors';
import { selectProjectDataRoomObject } from '~features/project-data-room/project-data-room.selectors';
import {
  createNewDataRoomObject,
  deleteDataObject,
  getProjectDataRoomObject,
  getProjectDataRoomObjects,
  setIsLoading,
  setNewDataObjectId,
  setProjectDataRoomObject,
  setProjectDataRoomObjects,
  updateProjectDataRoomObject,
  uploadDataObject,
} from '~features/project-data-room/project-data-room.slice';
import { addStreamActivity } from '~features/streams/streams.slice';
import { fetchProtectedAPI, postProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { uploadFileToSubmarineIPFS } from '~features/utils/pinata/pinata.sagas';
import { takeLatestWithMonitoring } from '~features/utils/saga-utils';
import type DataRoomObjectType from '~types/DataRoomTypes';
import type PinataType from '~types/PinataType';

function* fetchProjectObjectsSaga(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;

  if (id) {
    const res: Response = yield call(fetchProtectedAPI, `data-room?projectId=${id}`);
    const data: Array<DataRoomObjectType> = yield res.json();
    yield put(setProjectDataRoomObjects(data));
  }
}

function* fetchProjectDataRoomObjectSaga(action: PayloadAction<string>) {
  const objectId = action.payload;
  const params = {};
  const res: Response = yield call(fetchProtectedAPI, `data-room/${objectId}`, params);
  const data: DataRoomObjectType = yield res.json();
  yield put(setProjectDataRoomObject(data));
}

function* updateProjectDataRoomObjectSaga(action: PayloadAction<DataRoomObjectType>) {
  const currentProjectDataRoomObject = yield select(selectProjectDataRoomObject);
  const updatedProjectDataRoomObject = {
    ...currentProjectDataRoomObject,
    ...action.payload,
    updated: new Date(),
  };

  const path = `data-room/${currentProjectDataRoomObject._id}`;
  try {
    const res: Response = yield call(putProtectedAPI, path, updatedProjectDataRoomObject);

    if (res.status === 200) {
      yield put(setProjectDataRoomObject(updatedProjectDataRoomObject));
    } else {
      throw new Error('Failed to updated item.');
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'There was an error trying to update your data item.',
        severity: 'error',
        duration: 3000,
      }),
    );
  }
}

function* uploadDataObjectSaga(action: PayloadAction<{ file: File }>) {
  const { file } = action.payload;
  const currentProjectDataRoomObject = yield select(selectProjectDataRoomObject);

  try {
    yield put(setIsLoading());
    const res: any = yield uploadFileToSubmarineIPFS(file);
    const data: PinataType = yield res.json();
    if (res.status === 200) {
      yield put(
        updateProjectDataRoomObject({
          ...currentProjectDataRoomObject,
          ipfsFile: { id: data.items[0].id, cid: data.items[0].cid, name: data.items[0].name },
        }),
      );
      yield put(getProjectDataRoomObject(currentProjectDataRoomObject._id));
      yield put(setIsLoading());
    } else {
      throw new Error('Failed to upload file to IPFS');
    }
  } catch (e) {
    console.log(e);
    yield put(setIsLoading());
  }
}

function* deleteDataObjectSaga() {
  const currentProjectDataRoomObject = yield select(selectProjectDataRoomObject);

  try {
    yield put(updateProjectDataRoomObject({ ...currentProjectDataRoomObject, ipfsFiles: {} }));
  } catch (e) {
    console.log(e);
  }
}

function* createNewDataRoomObjectSaga() {
  const projectId = yield select(selectProjectId);
  const owner = yield select(selectUserNickname);

  yield put(setIsLoading());

  try {
    const path = 'data-room';
    const body = { projectId, owner };
    const res: Response = yield call(postProtectedAPI, path, body);

    if (res.status === 200) {
      const data: DataRoomObjectType = yield res.json();
      yield put(setProjectDataRoomObject(data));
      yield put(setNewDataObjectId(data._id));
      yield put(
        addStreamActivity({
          verb: 'cake',
          object: {
            content: {
              event: 'Token Gated Content',
              description: 'New Data Room Object Created',
            },
            link: `/dashboard/project/utility/${projectId}/data-room/${data._id}`,
          },
          projectId,
        }),
      );
    } else {
      throw new Error('Failed to create new content item');
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'There was an error trying to create your new Data Room item.',
        severity: 'error',
        duration: 3000,
      }),
    );
  }

  yield put(setIsLoading());
}

export default function* projectDataRoomSaga(): Iterator<any> {
  yield all([
    yield takeLatestWithMonitoring(getProjectDataRoomObjects.type, fetchProjectObjectsSaga, 'dataRoom.objects.fetch'),
    yield takeLatestWithMonitoring(
      getProjectDataRoomObject.type,
      fetchProjectDataRoomObjectSaga,
      'dataRoom.object.fetch',
    ),
    yield takeLatestWithMonitoring(
      updateProjectDataRoomObject.type,
      updateProjectDataRoomObjectSaga,
      'dataRoom.object.update',
    ),
    yield takeLatestWithMonitoring(createNewDataRoomObject.type, createNewDataRoomObjectSaga, 'dataRoom.object.create'),
    yield takeLatestWithMonitoring(uploadDataObject.type, uploadDataObjectSaga, 'dataRoom.uploadObject'),
    yield takeLatestWithMonitoring(deleteDataObject.type, deleteDataObjectSaga, 'dataRoom.uploadObject'),
  ]);
}
