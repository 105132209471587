import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';
import type { ContractType, DappSettingsType, ProjectGalleryType, ProjectType } from '~types/ProjectType';

const selectProjectConfig = (state: RootState): ProjectType => state.projectConfig.project;

const selectProjectContractConfig = (state: RootState): ContractType => state.projectConfig.project?.contract;

const selectProjectDappConfig = (state: RootState): DappSettingsType => state.projectConfig.project?.dapp;

const selectProjectGallery = (state: RootState): ProjectGalleryType => state.projectConfig.project?.dapp?.images;

const selectProjectConfigIsLoading = (state: RootState): boolean => state.projectConfig.isLoading;

const selectProjectConfigError = (state: RootState): string | null => state.projectConfig.error;

const selectUploadStatus = (state: RootState): object => state.projectConfig.imageUploader;

const selectProjectName = createDraftSafeSelector(selectProjectConfig, (config) => (config ? config.name : null));

const selectProjectSlug = createDraftSafeSelector(selectProjectConfig, (config) => (config ? config.slug : null));

const selectProjectId = createDraftSafeSelector(selectProjectConfig, (config) => (config ? config._id : null));

const selectProjectCollectionId = createDraftSafeSelector(selectProjectConfig, (config) =>
  config ? config.collectionId : null,
);

const selectProjectMintAgreementId = createDraftSafeSelector(selectProjectDappConfig, (dapp) =>
  dapp ? dapp.mintAgreementId : null,
);

export {
  selectProjectCollectionId,
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
  selectProjectContractConfig,
  selectProjectDappConfig,
  selectProjectGallery,
  selectProjectId,
  selectProjectMintAgreementId,
  selectProjectName,
  selectProjectSlug,
  selectUploadStatus,
};
