/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import type { ProjectType } from '~types/ProjectType';

type ProjectBuilderState = {
  currentStep: number;
  project: ProjectType;
  imageFile: File | null;
  status: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string;
  };
};

const initialState: ProjectBuilderState = {
  currentStep: 0,
  project: {
    name: '',
    slug: '',
    contract: {
      abi: '',
      livenet: {
        network: 'mainnet',
        contractAddress: '',
        isVerified: false,
      },
      testnet: {
        network: 'goerli',
        contractAddress: '',
        deployedContracts: [],
        isVerified: false,
      },
      maxMintTotal: null,
      maxMintTxn: null,
      merkleroot: null,
      price: 0,
      royalty: [],
      superAdmin: '',
      symbol: null,
      totalSupply: 0,
      whitelist: [],
    },
    dapp: {
      description: '',
      images: {
        banner: '',
        galleryColumns: 1,
        gallery: [],
      },
      social: {},
      mintAgreementId: null,
    },
    status: 'DRAFT',
    paper: {
      isActivated: false,
      contractId: null,
      isFixed: null,
    },
    presale: moment().valueOf(),
    publicSale: moment().valueOf(),
  },
  imageFile: null,
  status: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
};

const projectBuilderSlice = createSlice({
  name: 'project-builder',
  initialState,
  reducers: {
    createProject(state) {
      state.status.isLoading = true;
      state.status.isSuccess = false;
      state.status.error = null;
    },
    createProjectSuccess(state, action: PayloadAction<string>) {
      state.project = { ...state.project, _id: action.payload };
      state.status.isSuccess = true;
      state.status.error = null;
    },
    createProjectError(state, action: PayloadAction<string>) {
      state.status.isLoading = false;
      state.status.isSuccess = false;
      state.status.error = action.payload;
    },
    nextStep(state) {},
    prevStep(state) {},
    setStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setProjectConfig(state, action: PayloadAction<ProjectType>) {
      state.project = action.payload;
    },
    resetProjectConfig(state) {
      state.currentStep = initialState.currentStep;
      state.project = initialState.project;
      state.status = initialState.status;
    },
    setImageFile(state, action: PayloadAction<File>) {
      state.imageFile = action.payload;
    },
  },
});

export const {
  createProject,
  createProjectSuccess,
  createProjectError,
  nextStep,
  prevStep,
  setStep,
  setProjectConfig,
  resetProjectConfig,
  setImageFile,
} = projectBuilderSlice.actions;
export default projectBuilderSlice.reducer;
