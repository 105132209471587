import { Box } from '@mui/material';
import { useRef } from 'react';

import EditableTextField from '~components/inputs/EditableTextField';
import useOutsideClick from '~hooks/useOutsideClick';

type ProjectNameFieldProps = {
  onChange: (value: string) => void;
  onClickOutside: () => void;
  projectName: string;
};
const ProjectNameField = ({ onChange, onClickOutside, projectName }: ProjectNameFieldProps) => {
  const input = useRef();

  useOutsideClick(input, () => onClickOutside());

  return (
    <Box ref={input}>
      <EditableTextField
        autoFocus
        title="Project Name"
        placeholder="Your project name"
        value={projectName}
        onUpdate={(value) => onChange(value)}
        variant="standard"
      />
    </Box>
  );
};

export default ProjectNameField;
