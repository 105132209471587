import '~components/dashboard/Feed/Feed.scss';

import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityHeader from '~components/dashboard/Feed/components/ActivityHeader';
import { selectUserId } from '~features/auth/auth.selectors';
import { addChildActivityReaction, removeStreamReaction } from '~features/streams/streams.slice';
import useAppSelector from '~hooks/useAppSelector';
import type { ActivityReactionType } from '~types/Streams';

type FeedCommentsProps = {
  comment: ActivityReactionType;
};

const ActivityComment = ({ comment }: FeedCommentsProps) => {
  const dispatch = useDispatch();
  const userId = useAppSelector(selectUserId);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [likes, setLikes] = useState<number>(0);
  const [userLikeId, setUserLikeId] = useState<string>('');

  useEffect(() => {
    if (comment.children_counts.like || comment.children_counts.like === 0) {
      setLikes(comment.children_counts.like);
    }

    if (comment.latest_children.like) {
      for (let i = 0; i < comment.latest_children.like.length; i++) {
        if (comment.latest_children.like[i].user_id === userId.split('|')[1]) {
          setUserLikeId(comment.latest_children.like[i].id);
          setIsLiked(true);
          return;
        }
      }
    }
  }, [comment]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChildActivityReaction = () => {
    if (isLiked) {
      dispatch(
        removeStreamReaction({
          reactionId: userLikeId,
          activityId: comment.activity_id,
          isChild: true,
        }),
      );
    } else {
      dispatch(
        addChildActivityReaction({
          kind: 'like',
          activityId: comment.activity_id,
          reactionId: comment.id,
        }),
      );
    }

    setIsLiked(!isLiked);
  };

  return (
    <Card
      className={'CAKE__feed__activityCard__comments__container'}
      sx={{ borderRadius: '0px' }}
    >
      <ActivityHeader
        profileImage={comment.user ? comment.user.data.profileImage : ''}
        updated={comment.updated_at.split('Z')[0]} // remove Z from string
        userName={comment.user ? comment.user.data.name : 'Someone On Your Team'}
      />
      <CardContent>
        <Typography>{comment.data.text}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={'CAKE__feed__activityCard__reaction-icon'}
          aria-label="like"
          sx={{ color: isLiked ? 'green' : 'none' }}
          onClick={handleChildActivityReaction}
        >
          <ThumbUpAltOutlinedIcon />
          <Typography>{likes ? likes : ''}</Typography>
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ActivityComment;
