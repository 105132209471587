import FolderOffIcon from '@mui/icons-material/FolderOff';
import { Stack, Typography } from '@mui/material';

const CollectionViewerEmptyState = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
      gap={2}
    >
      <FolderOffIcon
        color={'primary'}
        fontSize={'large'}
      />
      <Typography color={'primary'}>There is no tokens for this collection</Typography>
    </Stack>
  );
};

export default CollectionViewerEmptyState;
