import agreementsConfigSlice from '~features/agreements-config/agreements-config.slice';
import analyticsSlice from '~features/analytics/analytics.slice';
import authSlice from '~features/auth/auth.slice';
import collectionConfigSlice from '~features/collection-config/collection-config.slice';
import collectionItemsSlice from '~features/collection-items/collection-items.slice';
import collectionUploadSlice from '~features/collection-upload/collection-upload.slice';
import contractDeployerSlice from '~features/contract-deployer/contract-deployer.slice';
import notificationsSlice from '~features/notifications/notifications.slice';
import projectBuilderSlice from '~features/project-builder/project-builder.slice';
import projectConfigSlice from '~features/project-config/project-config.slice';
import projectConentSlice from '~features/project-content/project-content.slice';
import projectDataRoomSlice from '~features/project-data-room/project-data-room.slice';
import projectDropSlice from '~features/project-drop/project-drop.slice';
import projectDropsManagerSlice from '~features/project-drops-manager/project-drops-manager.slice';
import projectsListSlice from '~features/projects-list/projects-list.slice';
import pubsubSlice from '~features/pubsub/pubsub.slice';
import streamsSlice from '~features/streams/streams.slice';
import tokenGatePolicyConfigSlice from '~features/token-gate-policy/token-gate-policy.slice';
import userInvitationSlice from '~features/user-invitation/user-invitation.slice';
import web3Slice from '~features/web3/web3.slice';
import whitelistConfigSlice from '~features/whitelist-config/whitelist-config.slice';

const reducers = {
  agreementsConfig: agreementsConfigSlice,
  analytics: analyticsSlice,
  auth: authSlice,
  collectionConfig: collectionConfigSlice,
  collectionItems: collectionItemsSlice,
  collectionUpload: collectionUploadSlice,
  contractDeployer: contractDeployerSlice,
  notifications: notificationsSlice,
  projectBuilder: projectBuilderSlice,
  projectConfig: projectConfigSlice,
  projectContent: projectConentSlice,
  projectDataRoom: projectDataRoomSlice,
  projectDropsManager: projectDropsManagerSlice,
  projectDrop: projectDropSlice,
  projectsList: projectsListSlice,
  pubsub: pubsubSlice,
  tokenGatePolicyConfig: tokenGatePolicyConfigSlice,
  streams: streamsSlice,
  userInvitation: userInvitationSlice,
  web3Config: web3Slice,
  whitelistConfig: whitelistConfigSlice,
};

export default reducers;
