import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { NotificationType } from '~types/miscs/NotificationType';

interface NotificationState {
  messages: Array<NotificationType>;
}

const initialState: NotificationState = {
  messages: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<NotificationType>) {
      state.messages.push(action.payload);
    },
    closeNotification(state) {
      state.messages.shift();
    },
  },
});
export const { addNotification, closeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
