import ProjectMessageType from '~constants/pubsub/ProjectMessageType';
import {
  callDeployContractError,
  callDeployContractSuccess,
  callVerifyContractError,
  callVerifyContractSuccess,
} from '~features/contract-deployer/contract-deployer.slice';

const processProjectMessages = (emitter, message) => {
  const payload = message.message;

  switch (payload.type) {
    case ProjectMessageType.CONTRACT_DEPLOYMENT_SUCCESS:
      emitter(
        callDeployContractSuccess({
          network: payload.data.network,
          contractAddress: payload.data.contractAddress,
          projectId: payload.data.projectId,
        }),
      );
      break;
    case ProjectMessageType.CONTRACT_DEPLOYMENT_FAILED:
      emitter(callDeployContractError('Contract deployment failed'));
      break;
    case ProjectMessageType.CONTRACT_VERIFICATION_SUCCESS:
      emitter(callVerifyContractSuccess({ network: payload.data.network }));
      break;
    case ProjectMessageType.CONTRACT_VERIFICATION_FAILED:
      emitter(callVerifyContractError({ network: payload.data.network, error: 'Contract verification failed' }));
      break;
  }
};

const pubsubEvents = (emitter, pubnub) => ({
  message: (message) => {
    console.log({ message, c: message.channel.startsWith('project_') });
    if (message.channel.startsWith('project_')) {
      processProjectMessages(emitter, message);
    }
  },
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-empty-function
  presence: () => {},
  status: (statusEvent) => {
    switch (statusEvent.category) {
      case 'PNConnectedCategory':
        // PubNub connected
        break;
      case 'PNNetworkUpCategory':
        console.log('pubnub reconnecting');
        if (pubnub) {
          pubnub.reconnect();
        }
        break;
      case 'PNNetworkDownCategory':
        console.log('pubnub connection failed');
        break;
      default:
        break;
    }
  },
});

export { pubsubEvents };
