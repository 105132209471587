import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppearanceSettingsCard from '~components/dashboard/AppearanceSettingsCard';
import BrandingSettingsCard from '~components/dashboard/BrandingSettingsCard';
import EssentialSettingsCard from '~components/dashboard/EssentialSettingsCard';
import FAQSettingsCard from '~components/dashboard/FAQSettingsCard';
import GallerySettingsCard from '~components/dashboard/GallerySettingsCard';
import ProjectNameCard from '~components/dashboard/ProjectNameCard';
import SocialSettingsCard from '~components/dashboard/SocialSettingsCard';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import { getAgreementConfig } from '~features/agreements-config/agreements-config.slice';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectDAppView = () => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const isError = useAppSelector(selectProjectConfigError);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  useEffect(() => {
    if (projectConfig && projectConfig.dapp.mintAgreementId) {
      dispatch(getAgreementConfig());
    }
  }, [projectConfig]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || projectConfig === null) {
    return <NotFoundView />;
  }

  return (
    <DashboardPageLayout>
      <Box
        marginTop={4}
        marginRight={8}
      >
        <ProjectNameCard />
        <EssentialSettingsCard />
        <AppearanceSettingsCard />
        <GallerySettingsCard />
        <BrandingSettingsCard />
        <SocialSettingsCard />
        <FAQSettingsCard />
      </Box>
    </DashboardPageLayout>
  );
};

export default ProjectDAppView;
