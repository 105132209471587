import '~components/dashboard/DropCard/DropCard.scss';

import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useEffect, useState } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  selectProjectDrop,
  selectProjectDropIsSaving,
  selectProjectDropProducts,
} from '~features/project-drop/project-drop.selectors';
import { deleteProjectDrop, saveProjectDrop } from '~features/project-drop/project-drop.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { SprinklesDrop } from '~types/DropType';
import { CampaignTypes } from '~types/DropType';

const DropCard = () => {
  const dispatch = useAppDispatch();
  const isSaving = useAppSelector(selectProjectDropIsSaving);
  const batterDrop = useAppSelector(selectProjectDrop);
  const products = useAppSelector(selectProjectDropProducts);
  const startTime = moment(new Date().getTime()); // Start one month out by default.
  const initialOneToOneTrait = { traitType: undefined, traitValue: undefined };

  const [form, setForm] = useState<SprinklesDrop>({
    openWindowEpochTime: startTime.add(1, 'months').valueOf(),
    closeWindowEpochTime: startTime.add(1, 'months').valueOf(),
    isOneToOne: false,
    oneToOneTrait: initialOneToOneTrait,
    productId: null,
  });

  useEffect(() => {
    if (!batterDrop) {
      return;
    }

    setForm({
      openWindowEpochTime: batterDrop.openWindowEpochTime,
      closeWindowEpochTime: batterDrop.closeWindowEpochTime,
      isOneToOne: batterDrop.isOneToOne,
      oneToOneTrait: batterDrop.oneToOneTrait ?? initialOneToOneTrait,
      productId: batterDrop.productId,
    });
  }, [batterDrop]);

  const formGroupClasses = { root: 'CAKE__merch-drop-form-group' };
  const formLabelClasses = { root: 'CAKE__merch-drop-form-label' };

  const addError = (message: string) => {
    dispatch(
      addNotification({
        duration: 5000,
        severity: 'error',
        message,
      }),
    );
  };

  const handleSave = () => {
    const now = Date.now();

    if (now > form.openWindowEpochTime) {
      return addError('Window must begin later than now.');
    }

    if (form.closeWindowEpochTime < form.openWindowEpochTime) {
      return addError('Window must close at a later time than it opens.');
    }

    if (!form.productId) {
      return addError('You must set a product for the drop.');
    }

    if (form.isOneToOne && (!form.oneToOneTrait.traitType || !form.oneToOneTrait.traitValue)) {
      return addError('You must set a trait and value for one to one drops.');
    }

    dispatch(
      saveProjectDrop({
        dropId: batterDrop?._id,
        data: { ...form, oneToOneTrait: form.isOneToOne ? form.oneToOneTrait : initialOneToOneTrait },
      }),
    );
  };

  const handleDelete = () => {
    dispatch(deleteProjectDrop({ id: batterDrop._id }));
  };

  return (
    <DashboardCard
      subtitle={'Drop Editor'}
      rightContainer={
        <div>
          <Stack
            flexDirection={'row'}
            gap={1}
          >
            <LoadingButton
              onClick={handleSave}
              loading={isSaving}
              loadingIndicator="Saving"
              variant="outlined"
            >
              Save
            </LoadingButton>

            <LoadingButton
              onClick={handleDelete}
              variant="contained"
              color="error"
            >
              Delete
            </LoadingButton>
          </Stack>
        </div>
      }
    >
      <FormControl>
        <FormGroup classes={formGroupClasses}>
          <FormLabel classes={formLabelClasses}>Campaign Window</FormLabel>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid
              container
              mb={2}
              spacing={2}
            >
              <Grid
                item
                sm={6}
              >
                <DateTimePicker
                  label="Campaign Start"
                  value={moment(form.openWindowEpochTime)}
                  onChange={(newDate) => setForm({ ...form, openWindowEpochTime: newDate.valueOf() })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>

              <Grid
                item
                sm={6}
              >
                <DateTimePicker
                  label="Campaign End"
                  value={moment(form.closeWindowEpochTime)}
                  onChange={(newDate) => setForm({ ...form, closeWindowEpochTime: newDate.valueOf() })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </FormGroup>

        <FormGroup classes={formGroupClasses}>
          <FormLabel classes={formLabelClasses}>Product</FormLabel>

          <Select
            onChange={(event: any) => {
              setForm({ ...form, productId: String(event.target.value) });
            }}
            label="Product"
            sx={{ marginBottom: '1rem' }}
            value={form.productId}
          >
            {Object.values(products).map((product) => (
              <MenuItem
                key={product.id}
                value={product.id}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <Avatar src={product.image} />
                  <Typography>{product.title}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormGroup>

        <FormGroup classes={formGroupClasses}>
          <FormLabel>Campaign Type</FormLabel>
          <FormHelperText>
            One to many means one token is good for many redemptions, while one to one only allows one redemption per
            token.
          </FormHelperText>

          <RadioGroup
            row
            defaultValue="one-to-one"
            value={form.isOneToOne ? CampaignTypes.OneToOne : CampaignTypes.OneToMany}
            onChange={(e) => setForm({ ...form, isOneToOne: e.target.value === CampaignTypes.OneToOne })}
          >
            <FormControlLabel
              value={CampaignTypes.OneToOne}
              control={<Radio />}
              label="One to One"
            />
            <FormControlLabel
              value={CampaignTypes.OneToMany}
              control={<Radio />}
              label="One to Many"
            />
          </RadioGroup>
        </FormGroup>

        {form.isOneToOne && (
          <FormGroup classes={formGroupClasses}>
            <FormLabel sx={{ marginTop: '1rem' }}>One to One Trait</FormLabel>
            <FormHelperText>This is the metadata that will be associated with a redeemed token.</FormHelperText>

            <TextField
              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
              value={form.oneToOneTrait.traitType}
              onChange={(e) =>
                setForm({ ...form, oneToOneTrait: { ...form.oneToOneTrait, traitType: e.target.value } })
              }
              id="outlined-basic"
              label="Trait"
              variant="outlined"
            />

            <TextField
              value={form.oneToOneTrait.traitValue}
              onChange={(e) =>
                setForm({ ...form, oneToOneTrait: { ...form.oneToOneTrait, traitValue: e.target.value } })
              }
              id="outlined-basic"
              label="Value"
              variant="outlined"
            />
          </FormGroup>
        )}
      </FormControl>
    </DashboardCard>
  );
};

export default DropCard;
