import '~components/collections/CollectionItemImage/CollectionItemImage.scss';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import ImageIcon from '@mui/icons-material/ImageNotSupported';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

type CollectionItemImageProps = {
  image?: string;
  isDropzone?: boolean;
  isImage: boolean;
  onImageError: () => void;
  onDropImage?: (files: Array<File>) => void;
};

const CollectionItemImage = ({
  image,
  isImage,
  isDropzone = false,
  onImageError,
  onDropImage,
}: CollectionItemImageProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropImage });
  const isVideo = image ? image.endsWith('mp4') : false;

  return (
    <>
      <Box
        className={classNames({
          'CAKE__collection-item-image': true,
          'CAKE__collection-item-image--with-dropzone': isDropzone,
        })}
      >
        {image && isImage && !isVideo && (
          <img
            className={'CAKE__collection-item-image__image'}
            src={`${image}?random=${Math.random()}`}
            alt="img"
            loading="lazy"
            onError={() => onImageError()}
          />
        )}
        {image && isImage && isVideo && (
          <video
            height="250"
            width={'100%'}
            style={{ objectFit: 'cover' }}
          >
            <source
              src={image}
              type="video/mp4"
            ></source>
          </video>
        )}
      </Box>
      {!isImage && (
        <Stack className={'CAKE__collection-item-image__no-image'}>
          <ImageIcon
            color={'inherit'}
            fontSize={'inherit'}
          />
        </Stack>
      )}
      {isDropzone && (
        <Box
          className={classNames({
            'CAKE__collection-item-image__dropzone': true,
            'CAKE__collection-item-image__dropzone--active': isDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <FileUploadIcon
            color={'primary'}
            fontSize={'inherit'}
          />
        </Box>
      )}
    </>
  );
};

export default CollectionItemImage;
