import { takeEvery, takeLatest } from 'redux-saga/effects';

import Monitoring from '~utils/monitoring';

function takeLatestWithMonitoring(pattern, saga, operationName, options = {}) {
  return takeLatest(pattern, Monitoring.executeGeneratorWithTransaction, saga, {
    name: window.location.pathname,
    op: operationName,
    ...options,
  });
}

function takeEveryWithMonitoring(pattern, saga, operationName, options = {}) {
  return takeEvery(pattern, Monitoring.executeGeneratorWithTransaction, saga, {
    name: window.location.pathname,
    op: operationName,
    ...options,
  });
}

export { takeEveryWithMonitoring, takeLatestWithMonitoring };
