import { TextField } from '@mui/material';

type WhitelistSearchBarProps = {
  searchValue: string;
  handleWalletSearchInput: any; // bug with defining as Function type - Type 'Function' is not assignable to type 'ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>'
};

const WhitelistSearchBar = ({ searchValue, handleWalletSearchInput }: WhitelistSearchBarProps) => {
  return (
    <TextField
      id="standard-basic"
      label="Search Wallet"
      variant="standard"
      sx={{ width: '100%', margin: '5px 10px' }}
      value={searchValue}
      onChange={handleWalletSearchInput}
    ></TextField>
  );
};

export default WhitelistSearchBar;
