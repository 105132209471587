import type { PayloadAction } from '@reduxjs/toolkit';
import { connect } from 'getstream';
import { buffers, eventChannel } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import ProjectVerbs from '~constants/streams/ProjectVerbs';
import { selectIsLoggedIn, selectUserId } from '~features/auth/auth.selectors';
import { setIsLoggedIn } from '~features/auth/auth.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import {
  addActivity,
  deleteStreamReaction,
  failCallback,
  fetchStreamData,
  isEmittedActivityUserPost,
  postStreamChildReaction,
  postStreamReaction,
  removeLikeFromActivityCommentArray,
  removeLikeFromActivityLikeArray,
  successCallback,
  updateActivityCommentArray,
} from '~features/streams/streams.helpers';
import {
  selectStreamAuthToken,
  selectStreamClient,
  selectStreamFeedActivityIdToGroupIdMap,
  selectStreamFeedData,
  selectStreamFeedGroupIdToActivityIdMap,
  selectStreamFeedOrder,
  selectTokenImages,
} from '~features/streams/streams.selectors';
import {
  addActivityReaction,
  addChildActivityReaction,
  addStreamActivity,
  addTokenImageToMap,
  createNewStreamUser,
  fetchProjectFeed,
  fetchProjectTokenImages,
  initProjectStream,
  removeStreamReaction,
  setFeedError,
  setFeedIsLoading,
  setNewFeedEventWaiting,
  setProjectFeedUpdateStatus,
  setProjectFollowing,
  setProjectStreamConfig,
  setProjectStreamData,
  setProjectStreamNextPage,
  setUserPostAttachment,
  unsubscribeFromProjectStream,
  uploadUserPostImage,
} from '~features/streams/streams.slice';
import { fetchProtectedAPI } from '~features/utils/api/api.sagas';
import { postProtectedAPI } from '~features/utils/api/api.sagas';
import { uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type { ProjectType } from '~types/ProjectType';
import type { ActivityReactionType } from '~types/Streams';
import type TokenMetadataType from '~types/token/TokenMetadataType';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

const streamEvent = (emitter) => (data) => {
  console.log({ data, emitter });
  emitter(data);
};

const initStreamClient = (client, projectId, token) =>
  eventChannel((emitter) => {
    const feed = client.feed('projects_aggregated', projectId, token);
    const events = streamEvent(emitter);
    feed.subscribe(events).then(successCallback, failCallback);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, buffers.expanding());

function* initStreamsSaga() {
  const userId = yield select(selectUserId);
  const isLoggedIn = yield select(selectIsLoggedIn);

  if (!userId || !isLoggedIn) {
    return;
  }

  const res: Response = yield call(
    fetchProtectedAPI,
    `streams/auth/${userId.split('|')[1]}`, // Stream id cannot handle `auth|` prepending
  );
  const data: any = yield res.json();
  const client = connect(process.env.REACT_APP_STREAMS_API_KEY, data.userToken, process.env.REACT_APP_STREAMS_APP_ID);
  yield put(setProjectStreamConfig({ client, authToken: data.userToken }));
}

function* initProjectStreamsSaga() {
  const currentProject = yield select(selectProjectConfig);
  const client = yield select(selectStreamClient);
  const authToken = yield select(selectStreamAuthToken);

  try {
    yield call(fetchProjectFeedSaga);
    yield put(setFeedIsLoading(false));
    yield call(subscribeToProjectFeedSaga, client, currentProject._id, authToken);
  } catch (e) {
    console.log(e);
    yield put(setFeedError(e.toString()));
  }
}

function* subscribeToProjectFeedSaga(client: any, projectId: string, token: string) {
  const userId = yield select(selectUserId);
  const subscription = yield call(initStreamClient, client, projectId, token);

  while (true) {
    try {
      const action = yield take(subscription);
      yield call(insertActivityToStreamFeedSaga, action);

      if (!isEmittedActivityUserPost(action.new, userId.split('|')[0])) {
        yield put(setNewFeedEventWaiting(true));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ event: 'streams failed', error });
    }
  }
}

function* unsubscribeFromProjectStreamSaga() {
  const currentProject = yield select(selectProjectConfig);
  const client = yield select(selectStreamClient);
  const streamAuthToken = yield select(selectStreamAuthToken);
  try {
    const feed = client.feed('projects_aggregated', currentProject._id, streamAuthToken);
    feed.unsubscribe();
  } catch (e) {
    console.log({ e });
  }
}

function* fetchProjectFeedSaga() {
  const currentProject = yield select(selectProjectConfig);
  const client = yield select(selectStreamClient);
  const streamAuthToken = yield select(selectStreamAuthToken);
  const prevActivityOrder = yield select(selectStreamFeedOrder);
  const feed = yield select(selectStreamFeedData);
  const activityGroupToActivityIdMap = yield select(selectStreamFeedGroupIdToActivityIdMap);
  const activityIdToActivityGroupIdMap = yield select(selectStreamFeedActivityIdToGroupIdMap);

  yield put(setProjectFeedUpdateStatus(true));

  const res: any = yield call(fetchStreamData, client, currentProject._id, streamAuthToken, prevActivityOrder.length);

  const activityOrder = prevActivityOrder.concat(res.results.map((activity) => activity.id));

  const newActivities = res.results.reduce((map, activity) => {
    map[activity.id] = activity;
    return map;
  }, {});

  const newActivityGroupToActivityIdMap = res.results.reduce((map, activity) => {
    map[activity.group] = activity.id;
    return map;
  }, {});

  const newActivityIdToActivityGroupIdMap = res.results.reduce((map, groupActivity) => {
    const activityId = groupActivity.activities.reduce((id, activity) => {
      return activity.id;
    }, '');
    map[activityId] = groupActivity.id;
    return map;
  }, {});

  yield put(setProjectStreamNextPage(res.next ? true : false));
  yield put(
    setProjectStreamData({
      activityOrder,
      activities: { ...feed, ...newActivities },
      activityGroupToActivityIdMap: { ...activityGroupToActivityIdMap, ...newActivityGroupToActivityIdMap },
      activityIdToActivityGroupIdMap: { ...activityIdToActivityGroupIdMap, ...newActivityIdToActivityGroupIdMap },
    }),
  );

  yield put(setProjectFeedUpdateStatus(false));
}

function* setProjectFollowingSaga(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;

  const follows = ProjectVerbs.reduce(
    (acc, curr) => [
      ...acc,
      ...[
        {
          source: `projects_aggregated:${id}`,
          target: `projects_${curr}:${id}`,
        },
        {
          source: `projects:${id}`,
          target: `projects_${curr}:${id}`,
        },
      ],
    ],
    [],
  );

  const payload = { follows };
  const res: Response = yield call(postProtectedAPI, `streams/${id}/follow`, payload);
  if (res.status !== 200) {
    // log error
  }
}

function* addStreamActivitySaga(action: PayloadAction<{ verb: string; object: any; projectId?: string }>) {
  const { verb, object, projectId } = action.payload;
  const userId = yield select(selectUserId);
  const client = yield select(selectStreamClient);
  const currentProject = yield select(selectProjectConfig);

  try {
    const payload = {
      userId: userId.split('|')[1],
      verb,
      object,
      projectId: projectId ? projectId : currentProject._id,
      timestamp: new Date(),
    };

    const res: Response = yield call(
      postProtectedAPI,
      `streams/${projectId ? projectId : currentProject._id}/store`,
      payload,
    );
    const data: { foreignId: string } = yield res.json();
    if (res.status === 200) {
      yield call(
        addActivity,
        client,
        userId.split('|')[1],
        projectId ? projectId : currentProject._id,
        verb,
        data.foreignId,
        object,
      );
    }
  } catch (err) {
    console.log(err);
    if (verb === 'userPost') {
      yield put(
        addNotification({
          message: "We're sorry, we were unable to process your post",
          severity: 'error',
          duration: 5000,
        }),
      );
    }
  }
}

function* insertActivityToStreamFeedSaga(data: any) {
  const feedOrder = yield select(selectStreamFeedOrder);
  const feed = yield select(selectStreamFeedData);
  const activityGroupToActivityIdMap = yield select(selectStreamFeedGroupIdToActivityIdMap);
  const activityIdToActivityGroupIdMap = yield select(selectStreamFeedActivityIdToGroupIdMap);

  let newActivityOrder = feedOrder;
  let newFeed = { ...feed }; // create copy of existing feed object
  let newActivities = {};
  let newActivityGroupToActivityIdMap = { ...activityGroupToActivityIdMap };
  let newActivityIdToActivityGroupIdMap = { ...activityIdToActivityGroupIdMap };

  try {
    data.new.forEach((activity) => {
      if (newActivityGroupToActivityIdMap[activity.group]) {
        console.log('grouped activity');
        const activityId = newActivityGroupToActivityIdMap[activity.group];
        newActivityOrder = newActivityOrder.filter((id) => {
          return id !== activityId;
        });
        newActivityOrder = [activityId, ...newActivityOrder];
        const updatedGroupActivity = {
          [activityId]: {
            ...newFeed[activityId],
            activities: [...newFeed[activityId].activities, activity],
            activity_count: newFeed[activityId].activity_count + 1,
            updated_at: activity.time,
          },
        };
        newFeed = { ...newFeed, ...updatedGroupActivity };
      } else {
        console.log('non-grouped activity');
        const newGroupActivity = {
          [activity.id]: {
            activities: [
              {
                ...activity,
                latest_reactions: {},
                own_reactions: {},
                reaction_counts: {},
              },
            ],
            activity_count: 1,
            actor_count: 1,
            created_at: activity.time,
            group: activity.group,
            id: activity.id,
            updated_at: activity.time,
            verb: activity.verb,
          },
        };

        newActivityOrder = [activity.id, ...newActivityOrder];
        newActivities = { ...newActivities, ...newGroupActivity };
        newActivityGroupToActivityIdMap = { ...newActivityGroupToActivityIdMap, [activity.group]: activity.id };
        newActivityIdToActivityGroupIdMap = { ...newActivityIdToActivityGroupIdMap, [activity.id]: activity.id }; // temp mapping to handle edge-case when user posts and immediately likes their own post
      }
    });
  } catch (e) {
    console.log(e);
  }

  yield put(
    setProjectStreamData({
      activityOrder: newActivityOrder,
      activities: { ...newFeed, ...newActivities },
      activityGroupToActivityIdMap: newActivityGroupToActivityIdMap,
      activityIdToActivityGroupIdMap: newActivityIdToActivityGroupIdMap,
    }),
  );
}

function* addActivityReactionSaga(
  action: PayloadAction<{ kind: 'like' | 'comment'; activityId: string; data?: { text: string } }>,
) {
  const { kind, activityId, data } = action.payload;

  const client = yield select(selectStreamClient);
  const feedOrder = yield select(selectStreamFeedOrder);
  const feedDataMap = yield select(selectStreamFeedData);
  const activityGroupToActivityIdMap = yield select(selectStreamFeedGroupIdToActivityIdMap);
  const activityIdToActivityGroupIdMap = yield select(selectStreamFeedActivityIdToGroupIdMap);

  const activityGroupId = activityIdToActivityGroupIdMap[activityId];
  const newFeed = { ...feedDataMap };

  try {
    const res: ActivityReactionType = yield call(postStreamReaction, client, kind, activityId, data);

    if (res) {
      const newActivity = {
        [activityGroupId]: {
          ...newFeed[activityGroupId],
          activities: [
            {
              ...newFeed[activityGroupId].activities[0],
              latest_reactions: {
                comment:
                  kind === 'comment' && newFeed[activityGroupId].activities[0].latest_reactions.comment
                    ? [res, ...newFeed[activityGroupId].activities[0].latest_reactions.comment]
                    : kind === 'comment' && !newFeed[activityGroupId].activities[0].latest_reactions.comment
                    ? [res]
                    : newFeed[activityGroupId].activities[0].latest_reactions.comment
                    ? [...newFeed[activityGroupId].activities[0].latest_reactions.comment]
                    : null,
                like:
                  kind === 'like' && newFeed[activityGroupId].activities[0].latest_reactions.like
                    ? [res, ...newFeed[activityGroupId].activities[0].latest_reactions.like]
                    : kind === 'like' && !newFeed[activityGroupId].activities[0].latest_reactions.like
                    ? [res]
                    : newFeed[activityGroupId].activities[0].latest_reactions.like
                    ? [...newFeed[activityGroupId].activities[0].latest_reactions.like]
                    : null,
              },
              own_reactions: {
                comment:
                  kind === 'comment' && newFeed[activityGroupId].activities[0].own_reactions.comment
                    ? [res, ...newFeed[activityGroupId].activities[0].own_reactions.comment]
                    : kind === 'comment' && !newFeed[activityGroupId].activities[0].own_reactions.comment
                    ? [res]
                    : newFeed[activityGroupId].activities[0].own_reactions.comment
                    ? [...newFeed[activityGroupId].activities[0].own_reactions.comment]
                    : null,
                like:
                  kind === 'like' && newFeed[activityGroupId].activities[0].own_reactions.like
                    ? [res, ...newFeed[activityGroupId].activities[0].own_reactions.like]
                    : kind === 'like' && !newFeed[activityGroupId].activities[0].own_reactions.like
                    ? [res]
                    : newFeed[activityGroupId].activities[0].own_reactions.like
                    ? [...newFeed[activityGroupId].activities[0].own_reactions.like]
                    : null,
              },
              reaction_counts: {
                comment:
                  kind === 'comment' && newFeed[activityGroupId].activities[0].reaction_counts.comment
                    ? newFeed[activityGroupId].activities[0].reaction_counts.comment + 1
                    : kind === 'comment' && !newFeed[activityGroupId].activities[0].reaction_counts.comment
                    ? 1
                    : newFeed[activityGroupId].activities[0].reaction_counts.comment
                    ? newFeed[activityGroupId].activities[0].reaction_counts.comment
                    : 0,
                like:
                  kind === 'like' && newFeed[activityGroupId].activities[0].reaction_counts.like
                    ? newFeed[activityGroupId].activities[0].reaction_counts.like + 1
                    : kind === 'like' && !newFeed[activityGroupId].activities[0].reaction_counts.like
                    ? 1
                    : newFeed[activityGroupId].activities[0].reaction_counts.like
                    ? newFeed[activityGroupId].activities[0].reaction_counts.like
                    : 0,
              },
            },
          ],
        },
      };

      yield put(
        setProjectStreamData({
          activityOrder: feedOrder,
          activities: { ...newFeed, ...newActivity },
          activityGroupToActivityIdMap: activityGroupToActivityIdMap,
          activityIdToActivityGroupIdMap,
        }),
      );
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'An error occured while processing your reaction',
        severity: 'error',
        duration: 5000,
      }),
    );
  }
}

function* createNewStreamUserSaga(action: PayloadAction<{ userId: string; userName: string; profileImage: string }>) {
  const { userId, userName, profileImage } = action.payload;

  try {
    yield postProtectedAPI('public/streams/create-user', { userId, userName, profileImage });
  } catch (e) {
    console.log(e);
  }
}

function* addChildActivityReactionSaga(
  action: PayloadAction<{ kind: 'like'; activityId: string; reactionId: string }>,
) {
  const { kind, activityId, reactionId } = action.payload;

  const client = yield select(selectStreamClient);
  const feedOrder = yield select(selectStreamFeedOrder);
  const feedDataMap = yield select(selectStreamFeedData);
  const activityGroupToActivityIdMap = yield select(selectStreamFeedGroupIdToActivityIdMap);
  const activityIdToActivityGroupIdMap = yield select(selectStreamFeedActivityIdToGroupIdMap);

  const activityGroupId = activityIdToActivityGroupIdMap[activityId];
  const newFeed = { ...feedDataMap };

  const res = yield call(postStreamChildReaction, client, kind, reactionId);

  if (res) {
    const newActivity = {
      [activityGroupId]: {
        ...newFeed[activityGroupId],
        activities: [
          {
            ...newFeed[activityGroupId].activities[0],
            latest_reactions: {
              comment: updateActivityCommentArray(res, newFeed[activityGroupId].activities[0].latest_reactions.comment),
            },
          },
        ],
      },
    };

    yield put(
      setProjectStreamData({
        activityOrder: feedOrder,
        activities: { ...newFeed, ...newActivity },
        activityGroupToActivityIdMap,
        activityIdToActivityGroupIdMap,
      }),
    );
  }
}

function* removeStreamReactionSaga(
  action: PayloadAction<{ reactionId: string; activityId: string; isChild: boolean }>,
) {
  // Note: currently only handles removing like reations

  const { reactionId, activityId, isChild } = action.payload;

  const feedOrder = yield select(selectStreamFeedOrder);
  const feedDataMap = yield select(selectStreamFeedData);
  const activityGroupToActivityIdMap = yield select(selectStreamFeedGroupIdToActivityIdMap);
  const activityIdToActivityGroupIdMap = yield select(selectStreamFeedActivityIdToGroupIdMap);
  const client = yield select(selectStreamClient);

  const activityGroupId = activityIdToActivityGroupIdMap[activityId];
  let updatedActivity = {};

  yield call(deleteStreamReaction, client, reactionId);

  // manipulate state somehow
  if (isChild) {
    updatedActivity = {
      [activityGroupId]: {
        ...feedDataMap[activityGroupId],
        activities: [
          {
            ...feedDataMap[activityGroupId].activities[0],
            latest_reactions: {
              ...feedDataMap[activityGroupId].activities[0].latest_reactions,
              comment: removeLikeFromActivityCommentArray(
                reactionId,
                feedDataMap[activityGroupId].activities[0].latest_reactions.comment,
              ),
            },
          },
        ],
      },
    };
  } else {
    updatedActivity = {
      [activityGroupId]: {
        ...feedDataMap[activityGroupId],
        activities: [
          {
            ...feedDataMap[activityGroupId].activities[0],
            latest_reactions: {
              ...feedDataMap[activityGroupId].activities[0].latest_reactions,
              like: removeLikeFromActivityLikeArray(
                reactionId,
                feedDataMap[activityGroupId].activities[0].latest_reactions.like,
              ),
            },
            own_reactions: {
              ...feedDataMap[activityGroupId].activities[0].own_reactions,
              like: [],
            },
            reaction_counts: {
              ...feedDataMap[activityGroupId].activities[0].reaction_counts,
              like: feedDataMap[activityGroupId].activities[0].reaction_counts.like - 1,
            },
          },
        ],
      },
    };
  }

  console.log(updatedActivity);

  yield put(
    setProjectStreamData({
      activityOrder: feedOrder,
      activities: { ...feedDataMap, ...updatedActivity },
      activityGroupToActivityIdMap,
      activityIdToActivityGroupIdMap,
    }),
  );
}

function* uploadUserPostImageSaga(action: PayloadAction<{ file: File }>) {
  const { file } = action.payload;
  const project = yield select(selectProjectConfig);

  const fileLocation: {
    dir: string;
    path: string;
  } = getS3RootProjectPath(project.name);

  try {
    const data = yield uploadFileToS3(file, `${fileLocation.dir}/feed`);
    console.log(data);
    yield put(setUserPostAttachment({ location: data.location }));
  } catch (err) {
    yield put(
      addNotification({
        message: 'We could not upload your image.',
        severity: 'error',
        duration: 5000,
      }),
    );
  }
}

function* fetchProjectTokenImagesSaga() {
  const tokenImageMap = yield select(selectTokenImages);
  const project: ProjectType = yield select(selectProjectConfig);

  try {
    for (let i = 0; i < Object.keys(tokenImageMap).length; i++) {
      const tokenId = Object.keys(tokenImageMap)[i];
      if (!tokenImageMap[tokenId]) {
        const path = `collection/${project.collectionId}/tokens/${tokenId}`;
        const res: Response = yield call(fetchProtectedAPI, path);

        if (res.status === 200) {
          const data: TokenMetadataType = yield res.json();
          yield put(addTokenImageToMap({ tokenId: Number(tokenId), image: data.image }));
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* streamsSaga() {
  yield all([
    yield takeLatest(setIsLoggedIn.type, initStreamsSaga),
    yield takeLatest(createNewStreamUser.type, createNewStreamUserSaga),
    yield takeLatest(initProjectStream.type, initProjectStreamsSaga),
    yield takeLatest(unsubscribeFromProjectStream.type, unsubscribeFromProjectStreamSaga),
    yield takeLatest(setProjectFollowing.type, setProjectFollowingSaga),
    yield takeLatest(addStreamActivity.type, addStreamActivitySaga),
    yield takeLatest(fetchProjectFeed.type, fetchProjectFeedSaga),
    yield takeLatest(addActivityReaction.type, addActivityReactionSaga),
    yield takeLatest(addChildActivityReaction.type, addChildActivityReactionSaga),
    yield takeLatest(removeStreamReaction.type, removeStreamReactionSaga),
    yield takeLatest(uploadUserPostImage.type, uploadUserPostImageSaga),
    yield takeLatest(fetchProjectTokenImages.type, fetchProjectTokenImagesSaga),
  ]);
}
