/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { initAbiFunctionState } from '~features/web3/web3.helpers';
import type { CallContractParamType, SagaABIType, SendContractParamType } from '~types/Web3Type';

interface Web3State {
  contractAbi: string;
  abi: SagaABIType;
  isLoading: boolean;
  isUpdating: boolean;
  error: string | null;
}

const initialState: Web3State = {
  contractAbi: '',
  abi: {
    read: [],
    write: [],
  },
  isLoading: false,
  isUpdating: false,
  error: null,
};

const web3State = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    initWeb3Config(state, action: PayloadAction<string>) {
      const { readStore, writeStore } = initAbiFunctionState(action.payload);
      state.contractAbi = action.payload;
      state.abi = { read: readStore, write: writeStore };
    },
    updateWeb3CallFunctionData(state, action: PayloadAction<{ idx: number; data: any }>) {
      const abiCopy = state.abi;
      abiCopy.read[action.payload.idx].data = action.payload.data;
      state.abi = abiCopy;
    },
    updateWeb3SendFunctionData(state, action: PayloadAction<{ idx: number; data: any }>) {
      const abiCopy = state.abi;
      abiCopy.write[action.payload.idx].data = action.payload.data;
      state.abi = abiCopy;
    },
    updateWeb3SendFunctionTxnStatus(state, action: PayloadAction<{ idx: number; txnStatus: any }>) {
      const abiCopy = state.abi;
      abiCopy.write[action.payload.idx].txnStatus = action.payload.txnStatus;
      state.abi = abiCopy;
    },
    callClearWeb3TxnStatus(state, action: PayloadAction<string>) {},
    clearWeb3TxnStatus(state, action: PayloadAction<number>) {
      const abiCopy = state.abi;
      abiCopy.write[action.payload].txnStatus = null;
      state.abi = abiCopy;
    },
    callWeb3Contract(state, action: PayloadAction<CallContractParamType>) {},
    sendWeb3Contract(state, action: PayloadAction<SendContractParamType>) {},
    setWeb3ConfigError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  initWeb3Config,
  updateWeb3CallFunctionData,
  updateWeb3SendFunctionData,
  updateWeb3SendFunctionTxnStatus,
  callClearWeb3TxnStatus,
  clearWeb3TxnStatus,
  callWeb3Contract,
  sendWeb3Contract,
  setWeb3ConfigError,
} = web3State.actions;
export default web3State.reducer;
