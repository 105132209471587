/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { TokenGatePolicy } from '~types/TokenGateTypes';

const initialState: TokenGatePolicy = {
  _id: '',
  utility: '',
  policy: 'holders',
  traits: {
    condition: '',
    rules: [],
  },
};

const tokenGatePolicyConfigSlice = createSlice({
  name: 'tokenGatePolicyConfig',
  initialState,
  reducers: {
    getTokenGatePolicyConfig(state, action: PayloadAction<{ id: string }>) {},
    setTokenGatePolicyConfig(state, action: PayloadAction<TokenGatePolicy>) {
      state._id = action.payload._id;
      state.utility = action.payload.utility;
      state.policy = action.payload.policy;
      state.traits = action.payload.traits;
    },
    updateTokenGatePolicyConfig(state, action: PayloadAction<TokenGatePolicy>) {},
    resetTokenGatePolicyConfig(state) {
      state._id = initialState._id;
      state.utility = initialState.utility;
      state.policy = initialState.policy;
      state.traits = initialState.traits;
    },
  },
});

export const {
  getTokenGatePolicyConfig,
  setTokenGatePolicyConfig,
  updateTokenGatePolicyConfig,
  resetTokenGatePolicyConfig,
} = tokenGatePolicyConfigSlice.actions;
export default tokenGatePolicyConfigSlice.reducer;
