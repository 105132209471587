/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { WhitelistType } from '~types/WhitelistType';

interface WhitelistConfigState {
  whitelist: WhitelistType | null;
  isLoading: boolean;
  isUpdating: boolean;
  error: string | null;
}

const initialState: WhitelistConfigState = {
  whitelist: null,
  isLoading: false,
  isUpdating: false,
  error: null,
};

const whitelistConfigState = createSlice({
  name: 'whitelist-config',
  initialState,
  reducers: {
    getWhitelistConfig(state, action: PayloadAction<string>) {
      state.isLoading = true;
      state.error = null;
    },
    setWhitelistConfig(state, action: PayloadAction<WhitelistType>) {
      state.whitelist = action.payload;
      state.isLoading = false;
    },
    cancelWhitelistConfigLoad(state) {
      state.isLoading = false;
    },
    setWhitelistConfigError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.whitelist = null;
    },
    updateWhitelistConfig(state, action: PayloadAction<WhitelistType>) {
      state.isUpdating = true;
    },
    updateWhitelistConfigSuccess(state, action: PayloadAction<WhitelistType>) {
      if (state.whitelist) {
        state.whitelist = action.payload;
      }
      state.isUpdating = false;
    },
    updateWhitelistConfigError(state, action: PayloadAction<string>) {
      state.isUpdating = false;
      state.error = action.payload;
    },
  },
});

export const {
  getWhitelistConfig,
  setWhitelistConfig,
  cancelWhitelistConfigLoad,
  setWhitelistConfigError,
  updateWhitelistConfig,
  updateWhitelistConfigSuccess,
  updateWhitelistConfigError,
} = whitelistConfigState.actions;
export default whitelistConfigState.reducer;
