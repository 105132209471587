import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack } from '@mui/material';

type DataRoomTableControlsProps = {
  onAddDataObject: () => void;
};

const DataRoomTableControls = ({ onAddDataObject }: DataRoomTableControlsProps) => {
  return (
    <Stack
      alignItems="flex-end"
      justifyContent="center"
    >
      <IconButton onClick={onAddDataObject}>
        <AddIcon color={'primary'} />
      </IconButton>
    </Stack>
  );
};

export default DataRoomTableControls;
