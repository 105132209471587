import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import BulkUpload from '~components/dashboard/WhitelistSettingsCard/components/BulkUpload';
import ConfirmUpdatesButton from '~components/dashboard/WhitelistSettingsCard/components/ConfirmUpdatesButton';
import MerklerootDialog from '~components/dashboard/WhitelistSettingsCard/components/MerklerootDialog';
import WhitelistAddressTable from '~components/dashboard/WhitelistSettingsCard/components/WhitelistAddressTable';
import WhitelistSearchBar from '~components/dashboard/WhitelistSettingsCard/components/WhitelistSearchBar';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectContractConfig } from '~features/project-config/project-config.selectors';
import { selectWhitelistConfig } from '~features/whitelist-config/whitelist-config.selectors';
import { updateWhitelistConfig } from '~features/whitelist-config/whitelist-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { WhitelistAddressMetadata } from '~types/WhitelistType';

type WalletUpdateSagaType = {
  add: string[];
  remove: string[];
};

const WhitelistSettingsCard = () => {
  const dispatch = useAppDispatch();

  const { merkleroot } = useAppSelector(selectProjectContractConfig);

  const whitelistConfig = useAppSelector(selectWhitelistConfig);

  const [searchValue, setSearchValue] = useState('');
  const [whitelistAddressMetadata, setWhitelistAddressMetadata] = useState<WhitelistAddressMetadata>({});
  const [isMerkleDialogOpen, setIsMerkleDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (whitelistConfig && whitelistConfig.whitelist) {
      let whitelistAddressMetadataInitializerObject = {};
      whitelistConfig.whitelist.forEach((address: string) => {
        whitelistAddressMetadataInitializerObject = {
          ...whitelistAddressMetadataInitializerObject,
          [address]: { isSelected: false, updateType: null, isNewWallet: false },
        };
      });
      setWhitelistAddressMetadata(whitelistAddressMetadataInitializerObject);
    }
  }, [whitelistConfig]);

  const handleMerkleDialogClose = () => {
    setIsMerkleDialogOpen(false);
  };

  const handleWalletSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value.toLowerCase());
  };

  const handleWhitelistDispatch = (walletUpdates: WalletUpdateSagaType) => {
    dispatch(updateWhitelistConfig({ ...whitelistConfig, whitelist: walletUpdates }));
  };

  const handleAddressSelectToggle = (address: string) => {
    if (whitelistAddressMetadata[address].updateType === 'remove') {
      setWhitelistAddressMetadata({
        ...whitelistAddressMetadata,
        [address]: { isSelected: false, updateType: null, isNewWallet: false },
      });
    } else {
      setWhitelistAddressMetadata({
        ...whitelistAddressMetadata,
        [address]: { isSelected: true, updateType: 'remove', isNewWallet: false },
      });
    }
  };

  const handleAddSingleWallet = () => {
    if (searchValue.length !== 42 || searchValue.slice(0, 2) !== '0x') {
      dispatch(
        addNotification({
          message: 'Invalid Address!',
          severity: 'error',
          duration: 5000,
        }),
      );
    } else {
      setWhitelistAddressMetadata({
        ...whitelistAddressMetadata,
        [searchValue]: { isSelected: false, updateType: 'add', isNewWallet: true },
      });
      setSearchValue('');
    }
  };

  return (
    <DashboardCard title={'Manage Whitelist'}>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 2, sm: 8, md: 8 }}
      >
        <WhitelistSearchBar
          handleWalletSearchInput={handleWalletSearchInput}
          searchValue={searchValue}
        />
        <WhitelistAddressTable
          searchInput={searchValue}
          handleAddressSelectToggle={handleAddressSelectToggle}
          handleAddSingleWallet={handleAddSingleWallet}
          whitelistAddressMetadata={whitelistAddressMetadata}
        />
      </Grid>
      <Grid
        item
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ marginTop: '10px' }}
      >
        <BulkUpload setIsMerkleDialogOpen={setIsMerkleDialogOpen} />
        <ConfirmUpdatesButton
          whitelistAddressMetadata={whitelistAddressMetadata}
          handleWhitelistDispatch={handleWhitelistDispatch}
          setSearchValue={setSearchValue}
          setIsMerkleDialogOpen={setIsMerkleDialogOpen}
        />
      </Grid>
      <Grid
        item
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ marginTop: '20px', display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}
      >
        <Typography variant="h6">Merkleroot:</Typography>
        {merkleroot ? (
          <Typography sx={{ padding: '3px 10px', bgcolor: '#80808069', borderRadius: '12px' }}>{merkleroot}</Typography>
        ) : (
          <Typography>Add addresses to generate a merkleroot</Typography>
        )}
      </Grid>
      <MerklerootDialog
        open={isMerkleDialogOpen}
        handleClose={handleMerkleDialogClose}
      />
    </DashboardCard>
  );
};

export default WhitelistSettingsCard;
