/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitationId: '97290ce8-26e5-4f9e-a6d7-3825e61b2269', // null,
  isLoading: false,
  isValidated: false,
  error: null,
  isSignupInProgress: false,
  isSignupFinished: false,
  signupError: null,
};

const userInvitationState = createSlice({
  name: 'user-invitation',
  initialState,
  reducers: {
    validateInvitation(state, action: PayloadAction<string>) {
      state.isLoading = true;
      state.error = null;
      state.invitationId = action.payload;
    },
    validateInvitationSuccess(state) {
      state.isLoading = false;
      state.isValidated = true;
      state.error = null;
    },
    validateInvitationError(state) {
      state.error = 'validation error';
      state.isValidated = true;
      state.isLoading = false;
    },
    invitationSignup(state, action: PayloadAction<{ user: string; password: string }>) {
      state.isSignupInProgress = true;
      state.isSignupFinished = false;
      state.signupError = null;
    },
    invitationSingupSuccess(state) {
      state.isSignupInProgress = false;
      state.isSignupFinished = true;
    },
    invitationSingupError(state, action: PayloadAction<string>) {
      state.isSignupInProgress = false;
      state.signupError = action.payload;
    },
  },
});

export const {
  validateInvitation,
  validateInvitationSuccess,
  validateInvitationError,
  invitationSignup,
  invitationSingupSuccess,
  invitationSingupError,
} = userInvitationState.actions;
export default userInvitationState.reducer;
