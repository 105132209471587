import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';
import { selectProjectContractConfig } from '~features/project-config/project-config.selectors';
import type { AggregateMintsTypes, WalletDistributionType } from '~types/AnalyticsType';
import type { ContractType } from '~types/ProjectType';

const selectMintAnalytics = (state: RootState) => state.analytics.aggregateMints;

const selectFormattedMintAnalytics = createDraftSafeSelector(
  selectMintAnalytics,
  selectProjectContractConfig,
  (selectMintAnalytics: Array<AggregateMintsTypes>, selectProjectContractConfig: ContractType | null) => {
    let isMintComplete = false;
    const data: Array<any> = [];

    selectMintAnalytics.forEach((record) => {
      if (!isMintComplete) {
        if (record.cumulative < (selectProjectContractConfig ? selectProjectContractConfig.totalSupply : 10000)) {
          data.push({
            name: new Date(record._id).toLocaleDateString('en-us', { month: 'short', day: 'numeric' }),
            value: record.cumulative,
          });
        } else {
          isMintComplete = true;
          data.push({
            name: new Date(record._id).toLocaleDateString('en-us', { month: 'short', day: 'numeric' }),
            value: record.cumulative,
          });
        }
      }
    });

    return data;
  },
);

const selectWalletDistribution = (state: RootState) => state.analytics.walletDistribution;

const selectFormattedWalletDistribution = createDraftSafeSelector(
  selectWalletDistribution,
  (state: WalletDistributionType[]) => {
    const data = state.map((record: WalletDistributionType) => {
      return { name: record._id.tokens, value: record.count };
    });
    return data;
  },
);
const selectTransferData = (state: RootState) => state.analytics.transferData;

const selectTopWalletHolders = (state: RootState) => state.analytics.topWalletHolders;

export {
  selectFormattedMintAnalytics,
  selectFormattedWalletDistribution,
  selectMintAnalytics,
  selectTopWalletHolders,
  selectTransferData,
  selectWalletDistribution,
};
