import '~components/layout/PrivatePageLayout/PrivatePageLayout.scss';

import React from 'react';

import LeftSideMenu from '~components/LeftSideMenu';

type PrivatePageLayoutProps = {
  children: React.ReactNode;
};
const PrivatePageLayout = ({ children }: PrivatePageLayoutProps) => {
  return (
    <div className={'CAKE__page-layout'}>
      <div className={'CAKE__page-layout__left-container'}>
        <LeftSideMenu />
      </div>
      <div className={'CAKE__page-layout__right-container'}>{children}</div>
    </div>
  );
};

export default PrivatePageLayout;
