import type { RootState } from '~features';
import type { ContentItemType } from '~types/ContentType';

const selectProjectContentItems = (state: RootState): Array<ContentItemType> => state.projectContent.content;

const selectProjectContentItem = (state: RootState): ContentItemType => state.projectContent.contentItem;

const selectProjectSlugs = (state: RootState): Array<{ id: string; slug: string }> => state.projectContent.slugs;

const selectProjectTags = (state: RootState): Array<string> => state.projectContent.tags;

const selectIsItemUploading = (state: RootState): boolean => state.projectContent.isItemUploading;

const selectNewContentItemId = (state: RootState): string => state.projectContent.newContentItemId;

export {
  selectIsItemUploading,
  selectNewContentItemId,
  selectProjectContentItem,
  selectProjectContentItems,
  selectProjectSlugs,
  selectProjectTags,
};
