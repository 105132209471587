import { Stack } from '@mui/material';
import { useState } from 'react';

import V2TextInput from '~components/inputs/V2TextInput';
import type { ProjectType } from '~types/ProjectType';
import useDebounce from '~utils/hooks/useDebounce';

type ProjectBasicInfoFormProps = {
  config: ProjectType;
  onConfigUpdated: (config: ProjectType) => void;
};

const ProjectBasicInfoForm = ({ config, onConfigUpdated }: ProjectBasicInfoFormProps) => {
  const [name, setName] = useState<string>(config.name || '');
  const [symbol, setSymbol] = useState<string>(config.contract.symbol || '');
  const [supply, setSupply] = useState<string>(String(config.contract.totalSupply || ''));
  const [price, setPrice] = useState<string>(String(config.contract.price || ''));

  const updateConfig = () => {
    onConfigUpdated({
      ...config,
      name,
      contract: {
        ...config.contract,
        symbol,
        totalSupply: Number(supply),
        price: Number(price),
      },
    });
  };

  useDebounce(updateConfig, [name, symbol, supply, price], 700);

  return (
    <Stack spacing={2}>
      <V2TextInput
        label="Project Title"
        variant="standard"
        color={'primary'}
        value={name}
        fullWidth
        required
        onUpdate={setName}
        validator={(value) => value.length > 2}
        showSuccessIcon
      />

      <Stack
        direction={'row'}
        spacing={2}
      >
        <V2TextInput
          label="Project Symbol"
          variant="standard"
          color={'primary'}
          value={symbol}
          onUpdate={setSymbol}
        />

        <V2TextInput
          label="Total supply"
          variant="standard"
          color={'primary'}
          type="number"
          value={supply}
          onUpdate={setSupply}
        />

        <V2TextInput
          label="ETH Price"
          variant="standard"
          color={'primary'}
          type="number"
          value={price}
          onUpdate={setPrice}
        />
      </Stack>
    </Stack>
  );
};

export default ProjectBasicInfoForm;
