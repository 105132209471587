import { orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: '#599CFF',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#111111',
    },
    success: {
      main: '#20D111',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        sx: { borderRadius: '8px' },
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    body1: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
    body2: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'inherit',
    },
  },
});

export default theme;
