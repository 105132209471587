import { Table, TableBody, TableContainer } from '@mui/material';

import ContentItem from '~components/dashboard/ContentItemsTable/components/ContentItem/ContentItem';
import ContentTableHead from '~components/dashboard/ContentItemsTable/components/ContentTableHead';
import type { ContentItemType } from '~types/ContentType';

type ContentItemsTableProps = {
  content: Array<ContentItemType>;
  onSelectItem: (content: ContentItemType) => void;
};

const ContentItemsTable = ({ content, onSelectItem }: ContentItemsTableProps) => {
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="content"
        size={'medium'}
      >
        <ContentTableHead />
        <TableBody>
          {content.map((item: ContentItemType) => (
            <ContentItem
              key={`content-item-${item._id}`}
              content={item}
              onClick={onSelectItem}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContentItemsTable;
