import '~components/projects/ProjectsTable/components/ProjectItem/ProjectItem.scss';

import { TableCell, TableRow } from '@mui/material';

import type { ProjectType } from '~types/ProjectType';

type ProjectItemProps = {
  project: ProjectType;
  onClick: (project: ProjectType) => void;
};
const ProjectItem = ({ project, onClick }: ProjectItemProps) => (
  <TableRow
    className={'CAKE__project-table__item'}
    hover
    onClick={() => onClick(project)}
    tabIndex={-1}
    key={project._id}
  >
    <TableCell
      component="th"
      scope="row"
      padding="none"
    >
      {project.name}
    </TableCell>
    <TableCell align="right">{project.contract?.maxMintTotal}</TableCell>
    <TableCell align="right">{project.contract?.price}</TableCell>
    <TableCell align="right">{project.createdAt}</TableCell>
  </TableRow>
);

export default ProjectItem;
