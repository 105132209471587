import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import Web3 from 'web3';

import { getTransactionParams, sendTransaction } from '~context/WalletContext/Wallet.helpers';
import { confirmTransaction } from '~features/web3/web3.helpers';
import { selectWeb3ConfigContractFunctionAbi } from '~features/web3/web3.selectors';
import {
  callClearWeb3TxnStatus,
  callWeb3Contract,
  clearWeb3TxnStatus,
  sendWeb3Contract,
  setWeb3ConfigError,
  updateWeb3CallFunctionData,
  updateWeb3SendFunctionData,
  updateWeb3SendFunctionTxnStatus,
} from '~features/web3/web3.slice';
import type { CallContractParamType, SagaABIType, SendContractParamType } from '~types/Web3Type';

window.Buffer = window.Buffer || Buffer;

function* callContractSaga(action: PayloadAction<CallContractParamType>): Iterator<any> {
  const currentWeb3FunctionData: SagaABIType = yield select(selectWeb3ConfigContractFunctionAbi);
  const { provider, abi, contractAddress, functionName, inputs } = action.payload;
  // console.log({provider, abi, contractAddress, functionName, inputs})

  try {
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(abi, contractAddress);
    let res: any = yield contract.methods[functionName](...inputs).call();

    if (typeof res === 'object') {
      if (Array.isArray(res)) {
        res = res.join(', ');
      } else {
        res = Object.values(res).join(', ');
      }
    } else {
      res = res.toString();
    }

    const functionIdxToUdate = currentWeb3FunctionData.read.findIndex((item) => item['name'] === functionName);
    yield put(updateWeb3CallFunctionData({ idx: functionIdxToUdate, data: res }));
  } catch (e) {
    console.log(e);
    yield put(setWeb3ConfigError(e.toString()));
  }
}

function* sendTxnContractSaga(action: PayloadAction<SendContractParamType>): Iterator<any> {
  const currentWeb3FunctionData: SagaABIType = yield select(selectWeb3ConfigContractFunctionAbi);
  const { provider, walletAddress, abi, contractAddress, walletChainId, inputs } = action.payload;

  try {
    const web3 = new Web3(provider);
    const transactionParams = yield getTransactionParams(
      provider,
      walletAddress,
      contractAddress,
      JSON.stringify(abi),
      walletChainId,
      inputs,
    );
    const txnHash = yield sendTransaction(provider, transactionParams);

    const functionIdxToUdate = currentWeb3FunctionData.write.findIndex((item) => item['name'] === abi['name']);
    yield put(updateWeb3SendFunctionData({ idx: functionIdxToUdate, data: txnHash }));

    const txnStatus = yield confirmTransaction(web3, txnHash);
    yield put(updateWeb3SendFunctionTxnStatus({ idx: functionIdxToUdate, txnStatus: txnStatus }));
  } catch (e) {
    yield put(setWeb3ConfigError(e.toString()));
  }
}

function* callClearWeb3TxnStatusSaga(action: PayloadAction<string>): Iterator<any> {
  const currentWeb3FunctionData: SagaABIType = yield select(selectWeb3ConfigContractFunctionAbi);
  try {
    const functionIdxToUdate = currentWeb3FunctionData.write.findIndex((item) => item['name'] === action.payload);
    yield put(clearWeb3TxnStatus(functionIdxToUdate));
  } catch (e) {
    console.log(e);
    yield put(setWeb3ConfigError(e.toString()));
  }
}

export default function* web3Saga(): Iterator<any> {
  yield all([
    yield takeLatest(callWeb3Contract.type, callContractSaga),
    yield takeLatest(sendWeb3Contract.type, sendTxnContractSaga),
    yield takeLatest(callClearWeb3TxnStatus.type, callClearWeb3TxnStatusSaga),
  ]);
}
