/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type DataRoomObjectType from '~types/DataRoomTypes';

interface DataRoomObjectState {
  objects: Array<DataRoomObjectType>;
  object?: DataRoomObjectType;
  newObjectId: string;
  categories: Array<string>;
  isLoading: boolean;
}

const initialState = {
  objects: [],
  object: null,
  newObjectId: '',
  categories: [],
  isLoading: false,
};

const projectDataRoomSlice = createSlice({
  name: 'project-data-room',
  initialState,
  reducers: {
    getProjectDataRoomObjects(state: DataRoomObjectState, action: PayloadAction<{ id: string }>) {},
    setProjectDataRoomObjects(state: DataRoomObjectState, action: PayloadAction<Array<DataRoomObjectType>>) {
      state.objects = action.payload;
      state.categories = action.payload.reduce((acc, curr: DataRoomObjectType) => {
        if (!acc.includes(curr.category)) {
          return [...acc, curr.category];
        }
        return acc;
      }, []);
    },
    resetDataRoomObject(state) {
      state.object = initialState.object;
    },
    getProjectDataRoomObject(state, action: PayloadAction<string>) {},
    createNewDataRoomObject(state) {},
    setProjectDataRoomObject(state, action: PayloadAction<DataRoomObjectType>) {
      state.object = action.payload;
      state.categories = !state.categories.includes(action.payload.category)
        ? [...state.categories, action.payload.category]
        : [...state.categories];
    },
    updateProjectDataRoomObject(state, action: PayloadAction<DataRoomObjectType>) {},
    uploadDataObject(state, action: PayloadAction<{ file: File }>) {},
    deleteDataObject(state) {},
    setNewDataObjectId(state, action: PayloadAction<string>) {
      state.newObjectId = action.payload;
    },
    setIsLoading(state) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {
  getProjectDataRoomObjects,
  setProjectDataRoomObjects,
  resetDataRoomObject,
  getProjectDataRoomObject,
  createNewDataRoomObject,
  setProjectDataRoomObject,
  updateProjectDataRoomObject,
  uploadDataObject,
  deleteDataObject,
  setNewDataObjectId,
  setIsLoading,
} = projectDataRoomSlice.actions;
export default projectDataRoomSlice.reducer;
