import { Button, Typography } from '@mui/material';

import DashboardCard from '~components/dashboard/DashboardCard';

const ShopifyImporterCard = () => {
  return (
    <DashboardCard subtitle={'Shopify Importer'}>
      <Typography marginBottom={2}>
        You have not connected your shopify account. Please install the Frosting app in Shopify to connect.
      </Typography>

      {/* TODO: Add Link to Frosting when it is live */}
      <Button variant="contained">Install</Button>
    </DashboardCard>
  );
};

export default ShopifyImporterCard;
