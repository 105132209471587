import { CardMedia, Typography } from '@mui/material';

import type { FeedAggregateActivityType } from '~types/Streams';

type UserPostActivityContentProps = {
  data: FeedAggregateActivityType;
};

const UserPostActivityContent = ({ data }: UserPostActivityContentProps) => {
  return (
    <div>
      <Typography
        variant="body1"
        color="text"
      >
        {data.activities[0].object.data.content}
      </Typography>
      {data.activities[0].object.data.media && (
        <CardMedia
          className={'CAKE__feed__activityCard__content-post-image'}
          component="img"
          height="194"
          sx={{ objectFit: 'contain' }}
          image={data.activities[0].object.data.media ? data.activities[0].object.data.media : ''}
          alt={data.activities[0].id}
        />
      )}
    </div>
  );
};

export default UserPostActivityContent;
