import type { RootState } from '~features/index';

const REDUX_STATE_REMOVED = { message: 'Removed on reduxStateTransformer' };

const reduxActionTransformer = (action) => {
  // if (action.type === 'GOVERNMENT_SECRETS') {
  //   // Return null to not log the action to Sentry
  //   return null;
  // }
  return action;
};

const reduxStateTranformer = (state: RootState) => ({
  ...state,
  projectConfig: {
    ...state.projectConfig,
    project: {
      ...state.projectConfig.project,
      paper: REDUX_STATE_REMOVED,
      contract: {
        ...state.projectConfig.project?.contract,
        abi: REDUX_STATE_REMOVED,
      },
    },
  },
  web3Config: REDUX_STATE_REMOVED,
  whitelistConfig: {
    ...state.whitelistConfig,
    whitelist: REDUX_STATE_REMOVED,
  },
});

export { reduxActionTransformer, reduxStateTranformer };
