import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContentItemsTable from '~components/dashboard/ContentItemsTable';
import ContentControls from '~components/dashboard/ContentItemsTable/components/ContentControls';
import DashboardCard from '~components/dashboard/DashboardCard';
import DataRoomObjectsTable from '~components/dashboard/DataRoomObjectsTable/DataRoomObjectsTable';
import DataRoomTableControls from '~components/dashboard/DataRoomTableControls/DataRoomTableControls';
import ProjectDropsCard from '~components/dashboard/ProjectDropsCard';
import ShopifyImporterCard from '~components/dashboard/ShopifyImporterCard';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import PrivateRoutes from '~constants/routes/private-routes.consts';
import { getCollectionTraits } from '~features/collection-items/collection-items.slice';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { selectIsItemUploading, selectProjectContentItems } from '~features/project-content/project-content.selectors';
import { selectNewContentItemId } from '~features/project-content/project-content.selectors';
import { getProjectContent } from '~features/project-content/project-content.slice';
import { createNewContentItem } from '~features/project-content/project-content.slice';
import { selectIsObjectLoading, selectNewObjectId } from '~features/project-data-room/project-data-room.selectors';
import { selectProjectDataRoomObjects } from '~features/project-data-room/project-data-room.selectors';
import {
  createNewDataRoomObject,
  getProjectDataRoomObjects,
} from '~features/project-data-room/project-data-room.slice';
import { selectProjectDropsManagerIsLoading } from '~features/project-drops-manager/project-drops-manager.selectors';
import { getProjectDropsManager } from '~features/project-drops-manager/project-drops-manager.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import useSmartNavigate from '~hooks/useSmartNavigate';
import type { ContentItemType } from '~types/ContentType';
import type DataRoomObjectType from '~types/DataRoomTypes';
import NotFoundView from '~views/NotFoundView';

const ProjectUtilityView = () => {
  const dispatch = useAppDispatch();

  const projectConfig = useAppSelector(selectProjectConfig);
  const projectContent = useAppSelector(selectProjectContentItems);
  const newContentItemId = useAppSelector(selectNewContentItemId);
  const projectDataRoomObjects = useAppSelector(selectProjectDataRoomObjects);
  const newDataRoomObjectId = useAppSelector(selectNewObjectId);
  const isContentLoading = useAppSelector(selectIsItemUploading);
  const isProjectLoading = useAppSelector(selectProjectConfigIsLoading);
  const isDataObjectLoading = useAppSelector(selectIsObjectLoading);
  const isLoading = useAppSelector(selectProjectDropsManagerIsLoading);
  const isError = useAppSelector(selectProjectConfigError);
  const { navigate } = useSmartNavigate();

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
    dispatch(getProjectContent({ id: projectId }));
    dispatch(getProjectDataRoomObjects({ id: projectId }));
    dispatch(getProjectDropsManager({ id: projectId }));
    dispatch(getCollectionTraits());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newContentItemId || newDataRoomObjectId) {
      navigate(
        newContentItemId
          ? PrivateRoutes.DASHBOARD_PROJECT_CONTENT_ITEM
          : PrivateRoutes.DASHBOARD_PROJECT_DATA_ROOM_OBJECT,
        {
          id: projectId,
          itemId: newContentItemId ? newContentItemId : newDataRoomObjectId,
        },
      );
    }
  }, [newContentItemId, newDataRoomObjectId]);

  const onSelectContentItem = (content: ContentItemType) => {
    navigate(PrivateRoutes.DASHBOARD_PROJECT_CONTENT_ITEM, {
      id: projectId,
      itemId: content._id,
    });
  };

  const onSelectDataRoomObject = (object: DataRoomObjectType) => {
    navigate(PrivateRoutes.DASHBOARD_PROJECT_DATA_ROOM_OBJECT, {
      id: projectId,
      itemId: object._id,
    });
  };

  const onAddContentItem = () => {
    dispatch(createNewContentItem());
    dispatch(getProjectContent({ id: projectId }));
  };

  const onAddDataObject = () => {
    dispatch(createNewDataRoomObject());
    dispatch(getProjectDataRoomObjects({ id: projectId }));
  };

  if (isLoading || isProjectLoading || isContentLoading || isDataObjectLoading) {
    return <PageLoader />;
  }

  if (isError || projectConfig === null) {
    return <NotFoundView />;
  }

  return (
    <DashboardPageLayout>
      <Stack
        direction={'column'}
        gap={2}
        marginTop={4}
        paddingBottom={4}
      >
        {projectConfig.shopify ? <ProjectDropsCard /> : <ShopifyImporterCard />}
        <DashboardCard subtitle={'Token-Gated Content'}>
          <ContentControls onAddContentItem={onAddContentItem} />
          <ContentItemsTable
            content={projectContent}
            onSelectItem={onSelectContentItem}
          />
        </DashboardCard>
        <DashboardCard subtitle={'Data Room'}>
          <DataRoomTableControls onAddDataObject={onAddDataObject} />
          <DataRoomObjectsTable
            objects={projectDataRoomObjects}
            onSelectItem={onSelectDataRoomObject}
          />
        </DashboardCard>
      </Stack>
    </DashboardPageLayout>
  );
};

export default ProjectUtilityView;
