import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DropCard from '~components/dashboard/DropCard';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import { selectProjectConfigError } from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { selectProjectDropIsLoading } from '~features/project-drop/project-drop.selectors';
import { getProjectDrop, getProjectDropProducts } from '~features/project-drop/project-drop.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectDropView = () => {
  const dispatch = useAppDispatch();

  const locationFragments = useLocation().pathname.split('/');
  const dropId = locationFragments[locationFragments.length - 1];
  const isLoading = useAppSelector(selectProjectDropIsLoading);
  const isError = useAppSelector(selectProjectConfigError);

  const projectId = locationFragments[4];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
    dispatch(getProjectDropProducts({ id: projectId }));

    if (dropId && dropId !== 'new') {
      dispatch(getProjectDrop({ id: projectId, dropId: dropId }));
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return <NotFoundView />;
  }

  return (
    <DashboardPageLayout>
      <Stack gap={2}>
        <DropCard />
      </Stack>
    </DashboardPageLayout>
  );
};

export default ProjectDropView;
