import type { RootState } from '~features';

const selectContractDeployerNetwork = (state: RootState): string => state.contractDeployer.network;

const selectContractDeployerIsLoading = (state: RootState): boolean => state.contractDeployer.isDeploying;

const selectContractDeployerError = (state: RootState): string | null => state.contractDeployer.error;

const selectContractIsVerified = (state: RootState): any => state.contractDeployer.isVerified;

const selectContractIsVerifying = (state: RootState): boolean => state.contractDeployer.isVerifiying;

const selectContractVerificationStatus = (state: RootState): string => state.contractDeployer.verificationStatus;

const selectContractVerificationId = (state: RootState): string => state.contractDeployer.verificationStatusId;

export {
  selectContractDeployerError,
  selectContractDeployerIsLoading,
  selectContractDeployerNetwork,
  selectContractIsVerified,
  selectContractIsVerifying,
  selectContractVerificationId,
  selectContractVerificationStatus,
};
