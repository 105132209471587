import type { RootState } from '~features';

const selectProjectDrop = (state: RootState) => state.projectDrop.drop;

const selectProjectDropProducts = (state: RootState) => state.projectDrop.products;

const selectProjectDropIsLoading = (state: RootState): boolean => state.projectDrop.isLoading;

const selectProjectDropIsSaving = (state: RootState): boolean => state.projectDrop.isSaving;

export { selectProjectDrop, selectProjectDropIsLoading, selectProjectDropIsSaving, selectProjectDropProducts };
