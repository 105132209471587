import '~views/user/LoginView/LoginView.scss';

import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { login } from '~features/auth/auth.slice';
import useAppDispatch from '~hooks/useAppDispatch';

const LoginView = () => {
  const dispatch = useAppDispatch();

  const onLogin = () => {
    dispatch(login());
  };

  return (
    <Stack className={'CAKE__login-view'}>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
      >
        {'Welcome Back Baker!'}
      </Typography>
      <Typography
        variant="body1"
        component="div"
      >
        {'Log in to your Cake account'}
      </Typography>
      <Button
        variant="contained"
        onClick={onLogin}
      >
        {'login with Auth0'}
      </Button>
    </Stack>
  );
};

export default LoginView;
