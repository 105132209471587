const ProjectBuilderSteps = {
  BASIC_INFO: 0,
  SUPER_ADMIN_ADDRESS: 1,
  LAUNCH_DATES: 2,
  MAX_MINT_TOTAL: 3,
  MAX_MINT_TXN: 4,
  SPLIT_PAYMENT: 5,
};

export { ProjectBuilderSteps };
