import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select } from 'redux-saga/effects';

import { selectUserGravatar, selectUserNickname } from '~features/auth/auth.selectors';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectId, selectProjectName } from '~features/project-config/project-config.selectors';
import { updateContentImagePath } from '~features/project-content/project-content.helpers';
import { selectProjectContentItem } from '~features/project-content/project-content.selectors';
import {
  createNewContentItem,
  deleteIPFSImage,
  getProjectContent,
  getProjectContentItem,
  setImageIsLoading,
  setProjectContent,
  setProjectContentItem,
  updateProjectContentItem,
  uploadImage,
  uploadIPFSImage,
} from '~features/project-content/project-content.slice';
import { setNewContentItemId } from '~features/project-content/project-content.slice';
import { addStreamActivity } from '~features/streams/streams.slice';
import { fetchProtectedAPI, postProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { uploadFileToSubmarineIPFS } from '~features/utils/pinata/pinata.sagas';
import { uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import { takeLatestWithMonitoring } from '~features/utils/saga-utils';
import type { ContentItemType } from '~types/ContentType';
import type PinataType from '~types/PinataType';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

function* fetchProjectContentSaga(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;

  if (id) {
    const res: Response = yield call(fetchProtectedAPI, `content?projectId=${id}`);
    const data = yield res.json();
    yield put(setProjectContent(data));
  }
}

function* fetchProjectContentItemSaga(action: PayloadAction<string>) {
  const contentId = action.payload;

  const params = {};
  const res: Response = yield call(fetchProtectedAPI, `content/${contentId}`, params);
  const data = yield res.json();
  yield put(setProjectContentItem(data));
}

function* updateProjectContentItemSaga(action: PayloadAction<ContentItemType>) {
  const currentProjectContentItem = yield select(selectProjectContentItem);
  const updatedProjectItem = {
    ...currentProjectContentItem,
    ...action.payload,
    updated: new Date(),
  };

  const path = `content/${currentProjectContentItem._id}`;
  try {
    const res: Response = yield call(putProtectedAPI, path, updatedProjectItem);

    if (res.status === 200) {
      yield put(setProjectContentItem(updatedProjectItem));
    } else {
      throw new Error('Failed to updated content item');
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'There was an error trying to update your content',
        severity: 'error',
        duration: 3000,
      }),
    );
  }
}

function* uploadImageSaga(action: PayloadAction<{ file: File; key: string }>) {
  const { file, key } = action.payload;
  const projectName = yield select(selectProjectName);
  const contentItem: ContentItemType = yield select(selectProjectContentItem);
  const fileLocation: {
    dir: string;
    path: string;
  } = getS3RootProjectPath(projectName);
  try {
    const data = yield uploadFileToS3(file, fileLocation.dir);
    const updatedContentItem: ContentItemType = updateContentImagePath(contentItem, data.location, key);
    yield put(updateProjectContentItem(updatedContentItem));
  } catch (err) {
    yield put(
      addNotification({
        message: 'There was an error updating your image',
        severity: 'error',
        duration: 3000,
      }),
    );
  }
}

function* uploadIPFSImageSaga(action: PayloadAction<{ file: File }>) {
  const { file } = action.payload;
  const currentContentItem = yield select(selectProjectContentItem);

  try {
    yield put(setImageIsLoading());
    const res: any = yield uploadFileToSubmarineIPFS(file);
    const data: PinataType = yield res.json();
    if (res.status === 200) {
      const isDuplicate = currentContentItem.ipfsFiles.filter((item) => item.cid === data.items[0].cid);
      if (!isDuplicate.length) {
        yield put(
          updateProjectContentItem({
            ...currentContentItem,
            ipfsFiles: [
              ...currentContentItem.ipfsFiles,
              { id: data.items[0].id, cid: data.items[0].cid, name: data.items[0].name },
            ],
          }),
        );
        yield put(getProjectContentItem(currentContentItem._id));
        yield put(setImageIsLoading());
      }
    } else {
      throw new Error('Failed to upload file to IPFS');
    }
  } catch (e) {
    console.log(e);
    yield put(setImageIsLoading());
  }
}

function* deleteIPFSImageSaga(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;
  const contentItem = yield select(selectProjectContentItem);

  try {
    const newIpfsItems = contentItem.ipfsFiles.filter((file: any) => file.id !== id);
    yield put(updateProjectContentItem({ ...contentItem, ipfsFiles: newIpfsItems }));
  } catch (e) {
    console.log(e);
  }
}

function* createNewContentItemSaga() {
  const projectId = yield select(selectProjectId);
  const name = yield select(selectUserNickname);
  const image = yield select(selectUserGravatar);

  yield put(setImageIsLoading());

  try {
    const path = 'content';
    const body = { projectId, name, image };
    const res: Response = yield call(postProtectedAPI, path, body);

    if (res.status === 200) {
      const data: ContentItemType = yield res.json();
      yield put(setProjectContentItem(data));
      yield put(setNewContentItemId(data._id));
      yield put(
        addStreamActivity({
          verb: 'cake',
          object: {
            content: {
              event: 'Token Gated Content',
              description: 'New Content Item Created',
            },
            link: `/dashboard/project/utility/${projectId}/content/${data._id}`,
          },
          projectId,
        }),
      );
    } else {
      throw new Error('Failed to create new content item');
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: 'There was an error trying to create your new content item',
        severity: 'error',
        duration: 3000,
      }),
    );
  }

  yield put(setImageIsLoading());
}

export default function* projectContentSaga(): Iterator<any> {
  yield all([
    yield takeLatestWithMonitoring(getProjectContent.type, fetchProjectContentSaga, 'content.fetch'),
    yield takeLatestWithMonitoring(getProjectContentItem.type, fetchProjectContentItemSaga, 'contentItem.fetch'),
    yield takeLatestWithMonitoring(updateProjectContentItem.type, updateProjectContentItemSaga, 'contentItem.update'),
    yield takeLatestWithMonitoring(createNewContentItem.type, createNewContentItemSaga, 'contentItem.create'),
    yield takeLatestWithMonitoring(uploadImage.type, uploadImageSaga, 'contentItem.uploadImage'),
    yield takeLatestWithMonitoring(uploadIPFSImage.type, uploadIPFSImageSaga, 'contentItem.uploadPinata'),
    yield takeLatestWithMonitoring(deleteIPFSImage.type, deleteIPFSImageSaga, 'contentItem.deletePinata'),
  ]);
}
