import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { Stack } from '@mui/system';

import TraitInputForm from '~components/inputs/TokenGatePolicyConfigForm/components/TraitInputForm';
import isValidPolicy from '~components/inputs/TokenGatePolicyConfigForm/TokenGatePolicyConfigForm.helpers';
import type { TokenGatePolicy } from '~types/TokenGateTypes';

type TokenGatePolicyConfigFormProps = {
  tokenGatePolicy: TokenGatePolicy;
  traits: Array<string>;
  handlePolicyUpdate: (policy: TokenGatePolicy) => void;
};

const TokenGatePolicyConfigForm = ({ tokenGatePolicy, traits, handlePolicyUpdate }: TokenGatePolicyConfigFormProps) => {
  const handlePolicyChange = (event) => {
    if (isValidPolicy(event.target.value)) {
      handlePolicyUpdate({ ...tokenGatePolicy, policy: event.target.value });
    }
  };

  return (
    <Grid
      container
      direction={'column'}
      gap={2}
    >
      <Grid
        item
        xs={12}
      >
        <FormControl>
          <RadioGroup
            value={tokenGatePolicy ? tokenGatePolicy.policy : 'holders'}
            name="radio-buttons-group"
            onChange={handlePolicyChange}
          >
            <Stack direction={'row'}>
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Public"
              />
              <FormControlLabel
                value="holders"
                control={<Radio />}
                label="All Holders"
              />
              <FormControlLabel
                value="traits"
                control={<Radio />}
                label="Trait-Specific"
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      {tokenGatePolicy.policy === 'traits' && (
        <Grid
          item
          xs={12}
        >
          <TraitInputForm
            tokenGatePolicy={tokenGatePolicy}
            traits={traits}
            handlePolicyUpdate={handlePolicyUpdate}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TokenGatePolicyConfigForm;
