import '~components/collections/CollectionViewer/components/CollectionItem/CollectionItem.scss';

import BrokenMetadataIcon from '@mui/icons-material/AssignmentLate';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Box, Button, Checkbox, Paper, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import CollectionItemImage from '~components/collections/CollectionItemImage';
import {
  deleteCollectionItem,
  selectCollectionItem,
  unselectCollectionItem,
} from '~features/collection-items/collection-items.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import type CollectionItemType from '~types/CollectionItemType';

type CollectionItemProps = {
  token: CollectionItemType;
  onEdit: (item: CollectionItemType) => void;
};

const CollectionItem = ({ token, onEdit }: CollectionItemProps) => {
  const dispatch = useAppDispatch();
  const { image, name } = token;

  const [isImage, setIsImage] = useState<boolean>(!!image);

  useEffect(() => {
    setIsImage(!!image);
  }, [image]);

  const onDelete = () => {
    dispatch(deleteCollectionItem(token));
  };

  const onChangeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(selectCollectionItem(token));
    } else {
      dispatch(unselectCollectionItem(token));
    }
  };

  return (
    <Paper
      elevation={1}
      className={'CAKE__collection-item'}
    >
      <CollectionItemImage
        image={image}
        isImage={isImage}
        onImageError={() => setIsImage(false)}
      />
      <Box className={'CAKE__collection-item__description'}>
        <Box>
          <Typography>{name}</Typography>
        </Box>
        <Box>
          <Button onClick={onDelete}>Delete</Button>
          <Button onClick={() => onEdit(token)}>Edit</Button>
        </Box>
      </Box>
      <Box className={'CAKE__collection-item__errors'}>
        {!isImage && (
          <Tooltip title={'Missing image'}>
            <BrokenImageIcon color={'inherit'} />
          </Tooltip>
        )}
        {!token.isMetadataValid && (
          <Tooltip title={'Metadata not valid'}>
            <BrokenMetadataIcon color={'inherit'} />
          </Tooltip>
        )}
      </Box>
      <Box className={'CAKE__collection-item__selection'}>
        <Checkbox
          color="primary"
          onChange={onChangeSelection}
        />
      </Box>
    </Paper>
  );
};

export default CollectionItem;
