import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { selectCollectionConfig } from '~features/collection-config/collection-config.selectors';
import {
  getCollectionConfig,
  setCollectionConfig,
  updateCollectionConfig,
  updateCollectionConfigSuccess,
} from '~features/collection-config/collection-config.slice';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import type { CollectionType } from '~types/CollectionsType';

window.Buffer = window.Buffer || Buffer;

function* fetchCollectionSaga(action: PayloadAction<string>): Iterator<any> {
  const collectionId = action.payload;

  try {
    const path = `collection/${collectionId}`;
    const res: Response = yield call(fetchProtectedAPI, path, {});

    if (res.status === 200) {
      const data: CollectionType = yield res.json();
      yield put(setCollectionConfig(data));
    } else {
      throw new Error('Error retrieving collections data');
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateCollcetionSaga(action: PayloadAction<{ collectionId: string; name: string; value: boolean }>) {
  const currentCollectionData: CollectionType = yield select(selectCollectionConfig);
  const collectionId = currentCollectionData._id;

  const updatedTokenTypes = currentCollectionData.tokenTypes.map((item) => {
    if (item && item.name === action.payload.name) {
      return { ...item, is_reveal: action.payload.value };
    } else {
      return item;
    }
  });

  const updatedCollectionConfigData = {
    ...currentCollectionData,
    tokenTypes: updatedTokenTypes,
  };

  const path = `collection/${collectionId}`;
  try {
    const res: Response = yield call(putProtectedAPI, path, updatedCollectionConfigData);

    if (res.status === 200) {
      const data: CollectionType = yield res.json();
      console.log(data);
      yield put(updateCollectionConfigSuccess({ ...data }));
    } else {
      throw new Error('Error updating collections data');
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* collectionSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getCollectionConfig.type, fetchCollectionSaga),
    yield takeLatest(updateCollectionConfig.type, updateCollcetionSaga),
  ]);
}
