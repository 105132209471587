import '~components/miscs/Stepper/Stepper.scss';

import { Stack } from '@mui/material';
import classNames from 'classnames';

type StepperProps = {
  currentStep: number;
  stepCount: number;
  setStep: (step: number) => void;
};

const Stepper = ({ currentStep, stepCount, setStep }: StepperProps) => (
  <Stack
    className={'CAKE__stepper'}
    direction="row"
    gap={2}
  >
    {[...Array(stepCount)].map((_, index) => (
      <div
        className={classNames({
          CAKE__stepper__step: true,
          'CAKE__stepper__step-completed': currentStep >= index,
        })}
        key={index}
        onClick={() => setStep(index)}
      />
    ))}
  </Stack>
);

export default Stepper;
