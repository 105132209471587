import '~components/dashboard/DataRoomObjectEditor/DataRoomObjectEditor.scss';

import OpenInNewRounded from '@mui/icons-material/OpenInNewRounded';
import { Grid, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DashboardCard from '~components/dashboard/DashboardCard';
import AutocompleteSingleSelect from '~components/inputs/AutocompleteSingleSelect/AutocompleteSingleSelect';
import FileUploadInput from '~components/inputs/FileUploadInput';
import TokenGatePolicyConfigForm from '~components/inputs/TokenGatePolicyConfigForm';
import UndoDeleteBanner from '~components/miscs/UndoDeleteBanner/UndoDeleteBanner';
import UtilityPublishControls from '~components/miscs/UtilityPublishControls/UtilityPublishControls';
import { selectCollectionTraitTypes } from '~features/collection-items/collection-items.selectors';
import { getCollection } from '~features/collection-items/collection-items.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  selectIsObjectLoading,
  selectProjectDataRoomCategories,
} from '~features/project-data-room/project-data-room.selectors';
import {
  getProjectDataRoomObject,
  getProjectDataRoomObjects,
  updateProjectDataRoomObject,
  uploadDataObject,
} from '~features/project-data-room/project-data-room.slice';
import { selectTokenGatePolicy } from '~features/token-gate-policy/token-gate-policy.selectors';
import {
  getTokenGatePolicyConfig,
  resetTokenGatePolicyConfig,
  updateTokenGatePolicyConfig,
} from '~features/token-gate-policy/token-gate-policy.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type DataRoomObjectType from '~types/DataRoomTypes';
import { UploadStatus } from '~types/miscs/UploadStatus';
import type { TokenGatePolicy } from '~types/TokenGateTypes';
import useInterval from '~utils/Interval/Interval';

type DataRoomObjectEditorProps = {
  object: DataRoomObjectType;
};

const DataRoomObjectEditor = ({ object }: DataRoomObjectEditorProps) => {
  const dispatch = useAppDispatch();
  const projectDataRomCategories = useAppSelector(selectProjectDataRoomCategories);
  const projectCollectionTraits = useAppSelector(selectCollectionTraitTypes);
  const isDataObjectLoading = useAppSelector(selectIsObjectLoading);
  const utilityPolicy = useAppSelector(selectTokenGatePolicy);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectDataRoomObjects({ id: projectId }));
    dispatch(getCollection());
    dispatch(getTokenGatePolicyConfig({ id: object.policyId }));

    return () => {
      dispatch(resetTokenGatePolicyConfig());
    };
  }, []);

  useInterval(() => dispatch(getProjectDataRoomObject(object._id)), 1000 * 60 * 60 * 10); // refresh ipfs access token link every 10-min

  const handleDelete = () => {
    dispatch(updateProjectDataRoomObject({ ...object, isArchived: true, status: 'Archieved' }));
  };

  const handleUndoDelete = () => {
    dispatch(updateProjectDataRoomObject({ ...object, isArchived: false, status: 'Draft' }));
    dispatch(
      addNotification({
        message: 'Item has been restored to draft-mode',
        severity: 'success',
        duration: 3000,
      }),
    );
  };

  const handleStatusChange = () => {
    const status = object.status === 'Published' ? 'Draft' : 'Published';
    dispatch(updateProjectDataRoomObject({ ...object, status }));
  };

  const handleCategoryChange = (category: string) => {
    dispatch(updateProjectDataRoomObject({ ...object, category }));
  };

  const onUploadIPFSFile = (file: File) => {
    dispatch(uploadDataObject({ file }));
  };

  const handlePolicyUpdate = (policy: TokenGatePolicy) => {
    dispatch(updateTokenGatePolicyConfig(policy));
  };

  return (
    <>
      <UndoDeleteBanner
        isOpen={object.isArchived}
        handleUndoDelete={handleUndoDelete}
      />
      <DashboardCard
        subtitle={'Data Room Manager'}
        rightContainer={
          <UtilityPublishControls
            isPublished={object.status === 'Published' ? true : false}
            handleStatusChange={handleStatusChange}
            handleDelete={handleDelete}
          />
        }
      >
        <Grid
          container
          columns={{ xs: 2, sm: 8, md: 8 }}
        >
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 8 }}
          >
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
            >
              <div className={'CAKE__data-room-editor__status'}>
                <p className={'CAKE__data-room-editor__status__title'}>Status:</p>
                {object.status === 'Published' ? (
                  <p className={'CAKE__data-room-editor__status__pill--published'}>Published</p>
                ) : (
                  <p className={'CAKE__data-room-editor__status__pill--draft'}>{object.status}</p>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 8 }}
            sx={{ margin: '10px 0', alignItems: 'end' }}
            direction="row"
          >
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
            >
              <AutocompleteSingleSelect
                label="Select a Category"
                value={object.category ? object.category : 'Other'}
                options={projectDataRomCategories}
                onUpdate={handleCategoryChange}
                errorMsg="Please add a category"
              />
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 8 }}
            sx={{ margin: '10px 0', alignItems: 'end' }}
            direction="row"
          >
            <Grid
              item
              xs={2}
              sm={7}
              md={7.5}
            >
              <FileUploadInput
                title={object.ipfsFile.name}
                uploadState={isDataObjectLoading ? UploadStatus.LOADING : 'A'}
                path={object.ipfsFile.url || ''}
                onUpload={(file: File) => onUploadIPFSFile(file)}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={0.25}
              md={0.15}
            >
              <a
                href={object.ipfsFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton aria-label="view">
                  <OpenInNewRounded />
                </IconButton>
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 8 }}
            sx={{ marginTop: '10px' }}
          >
            <Grid
              item
              xs={2}
              sm={8}
              md={8}
            >
              <div className={'CAKE__data-room-editor__token-gate-settings'}>
                <p className={'CAKE__data-room-editor__token-gate-settings__title'}>
                  Set Token-Gated Access Requirements
                </p>
              </div>
              <TokenGatePolicyConfigForm
                tokenGatePolicy={utilityPolicy}
                traits={projectCollectionTraits}
                handlePolicyUpdate={handlePolicyUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
      </DashboardCard>
    </>
  );
};

export default DataRoomObjectEditor;
