import { Table, TableBody, TableContainer } from '@mui/material';

import ProjectRecipientItem from '~components/projects/ProjectSplitPayment/components/ProjectRecipientItem';
import ProjectRecipientsTableHead from '~components/projects/ProjectSplitPayment/components/ProjectRecipientsTableHead';
import ProjectRecipientSummaryItem from '~components/projects/ProjectSplitPayment/components/ProjectRecipientSummaryItem';
import type { RoyaltyShareType } from '~types/ProjectType';

type ProjectRecipientsTableProps = {
  recipients: Array<RoyaltyShareType>;
  onUpdateRecipients: (recipients: Array<RoyaltyShareType>) => void;
};

const ProjectRecipientsTable = ({ recipients, onUpdateRecipients }: ProjectRecipientsTableProps) => {
  const totalShare = recipients.reduce((acc, curr) => acc + curr.amount, 0);

  const onUpdateItem = (item, idx) => {
    const temp = [...recipients];
    temp.splice(idx, 1, item);
    onUpdateRecipients(temp);
  };

  const onDeleteItem = (idx) => {
    const temp = [...recipients];
    temp.splice(idx, 1);
    onUpdateRecipients(temp);
  };

  return (
    <TableContainer>
      <Table
        aria-labelledby="projects"
        size={'small'}
      >
        <ProjectRecipientsTableHead />
        <TableBody>
          {recipients.map((item, idx) => (
            <ProjectRecipientItem
              key={`project-recipient-item-${item.address}-${item.amount}`}
              {...item}
              onUpdate={(item) => onUpdateItem(item, idx)}
              onDelete={() => onDeleteItem(idx)}
              isShareError={totalShare !== 100}
            />
          ))}
          <ProjectRecipientSummaryItem totalShare={totalShare} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectRecipientsTable;
