import Masonry from '@mui/lab/Masonry';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ContractDeploymentCard from '~components/dashboard/ContractDeploymentCard';
import ContractSettingsCard from '~components/dashboard/ContractSettingsCard';
import WhitelistSettingsCard from '~components/dashboard/WhitelistSettingsCard/WhitelistSettingsCard';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import { GOERLI_NETWORK_ID, MAINNET_NETWORK_ID } from '~constants/networks/networks';
import {
  selectProjectConfig,
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import { selectWhitelistConfigIsLoading } from '~features/whitelist-config/whitelist-config.selectors';
import { getWhitelistConfig } from '~features/whitelist-config/whitelist-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectContractView = () => {
  const dispatch = useAppDispatch();

  const projectConfig = useAppSelector(selectProjectConfig);
  const isLoadingProjectConfig = useAppSelector(selectProjectConfigIsLoading);
  const isErrorProjectConfig = useAppSelector(selectProjectConfigError);

  const isLoadingWhitelistConfig = useAppSelector(selectWhitelistConfigIsLoading);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  const [contractAddress, setContractAddress] = useState<string>('');
  const [chainId, setChainId] = useState<number | null>(GOERLI_NETWORK_ID);
  const [showNetworkToggle, setShowNetworkToggle] = useState<boolean>(false);

  useEffect(() => {
    if (projectConfig) {
      if (projectConfig.contract.livenet.contractAddress) {
        setChainId(MAINNET_NETWORK_ID);
        setShowNetworkToggle(true);
      } else if (projectConfig.contract.testnet.contractAddress) {
        setContractAddress(projectConfig.contract.testnet.contractAddress);
      }
    }
  }, [projectConfig]);

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
    dispatch(getWhitelistConfig(projectId));
  }, []);

  const handleNetworkChange = () => {
    return chainId === 1 ? setChainId(GOERLI_NETWORK_ID) : setChainId(MAINNET_NETWORK_ID);
  };

  if (isLoadingProjectConfig || isLoadingWhitelistConfig) {
    return <PageLoader />;
  }

  if (isErrorProjectConfig || projectConfig === null) {
    return <NotFoundView />;
  }

  return (
    <DashboardPageLayout>
      <Box
        marginTop={4}
        marginRight={8}
      >
        <Masonry
          columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          spacing={4}
        >
          <ContractDeploymentCard
            chainId={chainId}
            showNetworkToggle={showNetworkToggle}
            handleNetworkChange={handleNetworkChange}
          />
          <WhitelistSettingsCard />
          <ContractSettingsCard
            executionType="read"
            chainId={chainId}
            contractAddress={contractAddress}
          />
          <ContractSettingsCard
            executionType="write"
            chainId={chainId}
            contractAddress={contractAddress}
          />
        </Masonry>
      </Box>
    </DashboardPageLayout>
  );
};

export default ProjectContractView;
