import '~components/layout/headers/Header/Header.scss';

import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';

import UserMenu from '~components/layout/headers/Header/components/UserMenu';
import { PublicRoutes } from '~constants/routes';
import { useWallet } from '~context/WalletContext';
import { selectIsLoggedIn } from '~features/auth/auth.selectors';
import useAppSelector from '~hooks/useAppSelector';

type HeaderProps = {
  onOpenWalletConnection?: () => void;
};

const Header = ({ onOpenWalletConnection }: HeaderProps) => {
  const navigate = useNavigate();
  const isUserLoggedIn = useAppSelector(selectIsLoggedIn);
  const { walletAddress } = useWallet();

  return (
    <AppBar position="static">
      <Toolbar>
        <Box
          component="div"
          sx={{ flexGrow: 1 }}
        >
          <Link
            style={{ textDecoration: 'none', color: 'white' }}
            to={'/dashboard/projects'}
          >
            <Typography variant="h6">Cake App</Typography>
          </Link>
        </Box>
        {isUserLoggedIn ? (
          <Stack
            flexDirection={'row'}
            gap={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Button
              variant="outlined"
              onClick={walletAddress ? undefined : onOpenWalletConnection}
              color="inherit"
              size={'small'}
            >
              {walletAddress &&
                `${walletAddress.slice(0, 4)}...${walletAddress.slice(walletAddress.length - 4, walletAddress.length)}`}
              {!walletAddress && 'Connect wallet'}
            </Button>
            <UserMenu />
          </Stack>
        ) : (
          <Stack
            flexDirection={'row'}
            gap={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {/* <Button
              variant="text"
              onClick={() => navigate(PublicRoutes.SIGNUP)}
              color="inherit"
              size={"small"}
            >
              {"Sign up"}
            </Button> */}
            <Button
              variant="outlined"
              onClick={() => navigate(PublicRoutes.LOGIN)}
              color="inherit"
            >
              {'Login'}
            </Button>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
