import '~components/inputs/TokenGatePolicyConfigForm/components/TraitInput/TraitInput.scss';

import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';

import AutocompleteSingleSelect from '~components/inputs/AutocompleteSingleSelect/AutocompleteSingleSelect';
import tokenGateInputOperators from '~constants/token-gate/TokenGateOperators';
import type { TokenGateOperatorInputType, TokenGatePolicy, TokenGateRule } from '~types/TokenGateTypes';

type TraitInputProps = {
  tokenGatePolicy: TokenGatePolicy;
  data?: TokenGateRule;
  isNewRule: boolean;
  traits: Array<string>;
  ruleSet: Set<TokenGateRule>;
  handlePolicyUpdate: (policy: TokenGatePolicy) => void;
  handleDeleteNewRule: () => void;
};

const TraitInput = ({
  tokenGatePolicy,
  data,
  isNewRule,
  traits,
  ruleSet,
  handlePolicyUpdate,
  handleDeleteNewRule,
}: TraitInputProps) => {
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [trait, setTrait] = useState<string>(data ? data.name : traits[0]);
  const [operator, setOperator] = useState<TokenGateOperatorInputType>(
    data
      ? tokenGateInputOperators.filter((operator) => operator.value === data.operator)[0]
      : tokenGateInputOperators[0],
  );
  const [traitValue, setTraitValue] = useState<string>(data ? data.value : '');
  const [isValidRule, setIsValidRule] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<string>('');

  useEffect(() => {
    if (isUpdated && isValidRule && trait && operator && traitValue) {
      ruleSet.add({ name: trait, operator: operator.value, value: traitValue });
      handlePolicyUpdate({
        ...tokenGatePolicy,
        traits: {
          ...tokenGatePolicy.traits,
          rules: [...Array.from(ruleSet)],
        },
      });
      setTraitValue('');
      setTrait(traits ? traits[0] : '');
      handleDeleteNewRule(); // prevents new empty rule from automatically appearing after update
    }
  }, [trait, operator, traitValue]);

  const handleDeleteRule = () => {
    const rules = tokenGatePolicy.traits.rules.filter(
      (rule) => JSON.stringify(rule) !== JSON.stringify({ name: trait, operator: operator.value, value: traitValue }),
    );
    handlePolicyUpdate({
      ...tokenGatePolicy,
      traits: {
        ...tokenGatePolicy.traits,
        rules,
      },
    });
    setTraitValue('');
  };

  const handleSelectedTrait = (value: string) => {
    setTrait(value);
    setIsUpdated(true);
  };

  const handleSelectedOperator = (value: string) => {
    const selectedOperator = tokenGateInputOperators.filter((item) => item.label === value);
    setOperator(selectedOperator[0]);
    validateRule(selectedOperator[0], traitValue);
    setIsUpdated(true);
  };

  const handleSelectedValue = (value: string) => {
    setTraitValue(value !== undefined ? String(value) : value);
    validateRule(operator, value);
    setIsUpdated(true);
  };

  const validateRule = (currOperator, currValue) => {
    if (currOperator && ['gte', 'gt', 'lte', 'lt'].includes(currOperator.value) && !Number(currValue)) {
      setIsValidRule(false);
      setValidationError('Invalid rule based on provided value');
    } else {
      setIsValidRule(true);
    }
  };

  return (
    <Grid
      container
      gap={2}
    >
      <Grid item>
        <AutocompleteSingleSelect
          label={'Select a Trait'}
          value={trait}
          options={traits}
          errorMsg={'Select a Trait'}
          onUpdate={handleSelectedTrait}
          width={'400px'}
        />
      </Grid>
      <Grid item>
        <AutocompleteSingleSelect
          label={'Select a Trait'}
          value={operator ? operator.label : null}
          options={tokenGateInputOperators.map((item) => item.label)}
          errorMsg={''}
          onUpdate={handleSelectedOperator}
          width={'300px'}
        />
        {!operator && !isValidRule && <span className={'CAKE__trait-input__error-message'}>Select a Rule</span>}
        {!isValidRule && <span className={'CAKE__trait-input__error-message'}>{validationError}</span>}
        {!operator && <span className={'CAKE__trait-input__error-message'}>Select a Rule</span>}
      </Grid>
      <Grid item>
        <AutocompleteSingleSelect
          label={'Select a Value'}
          value={traitValue}
          options={[]}
          errorMsg={'Specify a Value'}
          onUpdate={handleSelectedValue}
          width={'300px'}
        />
      </Grid>
      <Grid item>
        <IconButton onClick={isNewRule ? handleDeleteNewRule : handleDeleteRule}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TraitInput;
