import type { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { createNewStreamUser } from '~features/streams/streams.slice';
import { selectInvitationId } from '~features/user-invitation/user-invitation.selectors';
import {
  invitationSignup,
  invitationSingupError,
  invitationSingupSuccess,
  validateInvitation,
  validateInvitationError,
  validateInvitationSuccess,
} from '~features/user-invitation/user-invitation.slice';
import type { ValidateInvitationType } from '~features/user-invitation/user-invitation.types';
import { postAPI } from '~features/utils/api/api.sagas';

function* validateInvitationSaga(action: PayloadAction<string>): Iterator<any> {
  const invitation = action.payload;
  const res: Response = yield postAPI('invitation/verify', { invitation });
  const data: ValidateInvitationType = yield res.json();

  if (data && data.isValid && !data.isUser) {
    yield put(validateInvitationSuccess());
  } else if (data && !data.isValid) {
    yield put(validateInvitationError());
  } else if (data && data.isUser && data.isValid) {
    console.log('User already exists - to be implemented');
  }
}

function* invitationSignupSaga(action: PayloadAction<{ user: string; password: string }>) {
  const { user, password } = action.payload;
  const invitationId = yield select(selectInvitationId);
  const res: Response = yield postAPI('user', { email: user, password, invitationId });
  const data = yield res.json();

  if (res.status === 200 && data.error) {
    yield put(invitationSingupError(data.error));
    return;
  }
  if (res.status === 200 && data) {
    yield put(invitationSingupSuccess());
    yield put(
      createNewStreamUser({ userId: data.user_id.split('|')[1], userName: data.nickname, profileImage: data.picture }),
    );
  }
}

export default function* userInvitationSaga(): Iterator<any> {
  yield all([
    yield takeLatest(validateInvitation.type, validateInvitationSaga),
    yield takeLatest(invitationSignup.type, invitationSignupSaga),
  ]);
}
