import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Badge, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  selectContractDeployerIsLoading,
  selectContractIsVerified,
} from '~features/contract-deployer/contract-deployer.selectors';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppSelector from '~hooks/useAppSelector';

type ViewContractButtonProps = {
  chainId: number;
};

const ViewContractButton = ({ chainId }: ViewContractButtonProps) => {
  const projectConfig = useAppSelector(selectProjectConfig);
  const contractIsDeploying = useAppSelector(selectContractDeployerIsLoading);
  const contractVerificationStates = useAppSelector(selectContractIsVerified);

  const { contract } = projectConfig;
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (chainId === 1) {
      contractVerificationStates.livenet ? setIsVerified(true) : setIsVerified(false);
    } else if (chainId === 5) {
      contractVerificationStates.testnet ? setIsVerified(true) : setIsVerified(false);
    }
  }, [contractVerificationStates, chainId]);

  const handleContractView = () => {
    let baseURL;
    let contractAddress;

    if (chainId === 5) {
      baseURL = `${process.env.REACT_APP_GOERLI_ETHERSCAN_BASE_URL}/address`;
      contractAddress = contract.testnet.contractAddress;
    } else if (chainId === 1) {
      baseURL = `${process.env.REACT_APP_MAINNET_ETHERSCAN_BASE_URL}/address`;
      contractAddress = contract.livenet.contractAddress;
    }

    window.open(`${baseURL}/${contractAddress}`, '_blank', 'noopener,noreferrer');
    return;
  };

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {!contract.testnet.contractAddress && (
          <Button
            disabled
            size="small"
            variant="outlined"
          >
            View Contract
          </Button>
        )}

        {contract.testnet.contractAddress && !contractIsDeploying && isVerified && (
          <Badge badgeContent={<CheckCircleIcon color={'success'} />}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleContractView}
            >
              View Contract
            </Button>
          </Badge>
        )}

        {contract.testnet.contractAddress && !contractIsDeploying && !isVerified && (
          <Badge badgeContent={<WarningRoundedIcon color={'warning'} />}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleContractView}
            >
              View Contract
            </Button>
          </Badge>
        )}

        {contract.testnet.contractAddress && contractIsDeploying && (
          <Button
            disabled
            size="small"
            variant="outlined"
          >
            View Contract
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default ViewContractButton;
