import { Button } from '@mui/material';

import { addNotification } from '~features/notifications/notifications.slice';
import useAppDispatch from '~hooks/useAppDispatch';

type ConfirmUpdatesButtonProps = {
  whitelistAddressMetadata: any;
  handleWhitelistDispatch: any;
  setSearchValue: any;
  setIsMerkleDialogOpen: any;
};

const ConfirmUpdatesButton = ({
  whitelistAddressMetadata,
  handleWhitelistDispatch,
  setSearchValue,
  setIsMerkleDialogOpen,
}: ConfirmUpdatesButtonProps) => {
  const dispatch = useAppDispatch();
  const onConfirmWalletChanges = () => {
    const walletUpdates = { add: [], remove: [] };

    Object.keys(whitelistAddressMetadata).forEach((address: string) => {
      if (whitelistAddressMetadata[address]['updateType'] === 'remove') {
        walletUpdates['remove'].push(address);
      } else if (whitelistAddressMetadata[address]['updateType'] === 'add') {
        walletUpdates['add'].push(address);
      }
    });

    if (walletUpdates['add'].length || walletUpdates['remove'].length) {
      setSearchValue('');
      handleWhitelistDispatch(walletUpdates);
      setIsMerkleDialogOpen(true);
    } else {
      dispatch(
        addNotification({
          message: 'No Changes Have Been Made!',
          severity: 'warning',
          duration: 5000,
        }),
      );
    }
  };

  return (
    <Button
      sx={{ margin: '0 0 0 10px' }}
      variant="contained"
      color="secondary"
      onClick={onConfirmWalletChanges}
    >
      Confirm
    </Button>
  );
};

export default ConfirmUpdatesButton;
