import { Button, Card, CardActions, CardContent, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

type AuthCardProps = {
  onConfirm: (user: string, password: string) => void;
};

const AuthCard = ({ onConfirm }: AuthCardProps) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const onConfirmButtonClicked = () => {
    onConfirm(user, password);
  };

  return (
    <Card sx={{ maxWidth: 400, minWidth: 300 }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography
          paddingBottom={2}
          variant="h6"
          color={'primary'}
          gutterBottom
        >
          Create Account
        </Typography>
        <Stack
          gap={4}
          paddingBottom={2}
        >
          <TextField
            label="Email"
            variant="standard"
            color={'primary'}
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="standard"
            color={'primary'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          sx={{ width: '100%' }}
          color={'primary'}
          variant={'contained'}
          onClick={onConfirmButtonClicked}
        >
          Sign up
        </Button>
      </CardActions>
    </Card>
  );
};

export default AuthCard;
