import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { updateProjectFieldConfigSuccess } from '~features/project-config/project-config.slice';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { selectWhitelistConfig } from '~features/whitelist-config/whitelist-config.selectors';
import {
  cancelWhitelistConfigLoad,
  getWhitelistConfig,
  setWhitelistConfig,
  setWhitelistConfigError,
  updateWhitelistConfig,
  updateWhitelistConfigError,
  updateWhitelistConfigSuccess,
} from '~features/whitelist-config/whitelist-config.slice';
import type { WhitelistAPIResponseType, WhitelistType } from '~types/WhitelistType';

window.Buffer = window.Buffer || Buffer;

function* fetchProjectWhitelistSaga(action: PayloadAction<string>): Iterator<any> {
  const projectId: string = action.payload;
  const whitelist: string = yield select(selectWhitelistConfig);

  if (whitelist) {
    yield put(cancelWhitelistConfigLoad());
    return;
  }

  try {
    const path = `project/${projectId}/whitelist`;
    const res: Response = yield call(fetchProtectedAPI, path);
    const data: WhitelistType = yield res.json();

    if (data) {
      yield put(setWhitelistConfig({ _id: projectId, whitelist: data }));
    } else {
      yield put(setWhitelistConfigError('Whitelist not found'));
    }
  } catch (e) {
    console.log(e);
    yield put(setWhitelistConfigError(e.toString()));
  }
}

function* updateProjectWhitelistSaga(action: PayloadAction<string[]>) {
  const currentProjectWhitelistConfig = yield select(selectWhitelistConfig);
  const updatedProjecWhitelistConfig = {
    ...currentProjectWhitelistConfig,
    ...action.payload,
  };

  const path = `project/${currentProjectWhitelistConfig._id}/whitelist`;
  try {
    const res: Response = yield call(putProtectedAPI, path, updatedProjecWhitelistConfig);

    if (res.status === 200) {
      const data: WhitelistAPIResponseType = yield res.json();

      yield put(updateWhitelistConfigSuccess({ ...currentProjectWhitelistConfig, whitelist: data.whitelist }));
      yield put(updateProjectFieldConfigSuccess({ field: 'merkleroot', value: data.merkleroot }));
    } else {
      yield put(updateWhitelistConfigError(`Update failed with status code ${res.status}`));
    }
  } catch (e) {
    console.log(e);
    yield put(updateWhitelistConfigError(e.toString()));
  }
}

export default function* whitelistSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getWhitelistConfig.type, fetchProjectWhitelistSaga),
    yield takeLatest(updateWhitelistConfig.type, updateProjectWhitelistSaga),
  ]);
}
