import '~components/buttons/InputIconButton/InputIconButton.scss';

import type { MouseEventHandler } from 'react';
import React from 'react';

type InputIconButtonProps = {
  color?: string;
  children: JSX.Element;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const InputIconButton = ({ color, children, onClick }: InputIconButtonProps) => (
  <div
    className={'CAKE__input-icon-button'}
    onClick={onClick}
    style={{ color }}
  >
    {React.cloneElement(children, { style: { width: 18, height: 18 } })}
  </div>
);

export default InputIconButton;
