// eslint-disable-next-line absolutely-import/import
import './ImageDropzone.scss';

import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Typography } from '@mui/material';
import { default as classNames, default as cx } from 'classnames';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

type ImageDropzoneProps = {
  onDropFile: (files: Array<File>) => void;
  title?: string;
  image?: string;
  isUploading?: boolean;
  variant?: 'small' | 'default';
};

const ImageDropzone = ({ onDropFile, title, image, isUploading = false, variant = 'default' }: ImageDropzoneProps) => {
  const isSmall = variant === 'small';

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropFile });
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const inputProps = getInputProps({ multiple: false, accept: 'image/png, image/jpeg' });
  delete inputProps.multiple;

  return (
    <Box
      className={cx({
        'CAKE__image-dropzone': true,
        'CAKE__image-dropzone--small': isSmall,
        'CAKE__image-dropzone--active': isDragActive,
      })}
      {...getRootProps()}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {isUploading && (
        <Box
          className={classNames({
            'CAKE__image-dropzone__upload': true,
            'CAKE__image-dropzone__upload--small': isSmall,
          })}
        >
          <CircularProgress size={isSmall ? 18 : 24} />
        </Box>
      )}
      {image && !isUploading && (
        <img
          className={'CAKE__image-dropzone__image'}
          src={image}
        />
      )}
      {!isUploading && (
        <>
          <Box
            className={classNames({
              'CAKE__image-dropzone__info': true,
              'CAKE__image-dropzone__info--small': isSmall,
              'CAKE__image-dropzone__info--hidden': Boolean(image) && !isMouseOver,
              'CAKE__image-dropzone__info--with-image': Boolean(image) && isMouseOver,
            })}
          >
            <AddIcon
              color={'inherit'}
              fontSize={'inherit'}
            />
            {!isSmall && (
              <Typography
                className={'CAKE__image-dropzone__title'}
                variant={'body2'}
              >
                {title}
              </Typography>
            )}
          </Box>
          <input {...inputProps} />
        </>
      )}
    </Box>
  );
};

export default ImageDropzone;
