import { Button, Grid } from '@mui/material';

import DashboardCard from '~components/dashboard/DashboardCard';
import EditableTextField from '~components/inputs/EditableTextField';
import FileUploadInput from '~components/inputs/FileUploadInput';
import {
  selectAgreementConfig,
  selectAgreementConfigIsLoading,
} from '~features/agreements-config/agreements-config.selectors';
import { createAgreement, uploadFile } from '~features/agreements-config/agreements-config.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { UploadStatus } from '~types/miscs/UploadStatus';

const EssentialSettingsCard = () => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const agreementConfig = useAppSelector(selectAgreementConfig);
  const isAgreementLoading = useAppSelector(selectAgreementConfigIsLoading);
  const { url } = agreementConfig;
  const { dapp } = projectConfig;
  const { description } = dapp;

  const onUpdateDescription = (value: string) => {
    dispatch(updateProjectConfig({ ...projectConfig, dapp: { ...dapp, description: value } }));
  };

  const handleAddUserAgreement = () => {
    dispatch(createAgreement());
  };

  const onUploadFile = (file: File) => {
    dispatch(uploadFile({ file }));
  };

  return (
    <DashboardCard title={'Project description'}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 8 }}
      >
        <Grid
          item
          xs={2}
          sm={12}
          md={12}
        >
          <EditableTextField
            title="Description"
            value={description || ''}
            multiline
            onUpdate={onUpdateDescription}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={12}
          md={12}
          zeroMinWidth
        >
          {projectConfig.dapp.mintAgreementId && (
            <FileUploadInput
              title="Legal Document"
              path={url || ''}
              uploadState={isAgreementLoading ? UploadStatus.LOADING : 'A'}
              onUpload={(file: File) => onUploadFile(file)}
            />
          )}
          {!projectConfig.dapp.mintAgreementId && <Button onClick={handleAddUserAgreement}>Add User Agreement</Button>}
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default EssentialSettingsCard;
