import '~components/dashboard/CollectionArtworksCard/CollectionArtworksCard.scss';

import { Box } from '@mui/material';

import CollectionViewer from '~components/collections/CollectionViewer';
import DashboardCard from '~components/dashboard/DashboardCard';
import FileDropzone from '~components/inputs/FileDropzone';
import { uploadItems } from '~features/collection-upload/collection-upload.slice';
import useAppDispatch from '~hooks/useAppDispatch';

const CollectionArtworksCard = () => {
  const dispatch = useAppDispatch();

  const onUploadFiles = (acceptedFiles: Array<File>) => {
    dispatch(uploadItems(acceptedFiles));
  };

  return (
    <DashboardCard
      className={'CAKE__collection-artworks-card'}
      containerClassName={'CAKE__collection-artworks-card__container'}
    >
      <Box className={'CAKE__collection-artworks-card__inner-container'}>
        <Box className={'CAKE__collection-artworks-card__viewer-container'}>
          <CollectionViewer />
        </Box>
        <Box className={'CAKE__collection-artworks-card__dropzone-container'}>
          <FileDropzone
            title="Drop images and metadata here"
            onDropFiles={onUploadFiles}
          />
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default CollectionArtworksCard;
