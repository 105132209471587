import '~components/inputs/EditableTextField/EditableTextField.scss';

import { Stack } from '@mui/material';
import classNames from 'classnames';

import { ConfirmationIcon } from '~icons/common';
import useInput from '~utils/hooks/useInput';

type EditableTextFieldProps = {
  autoFocus?: boolean;
  debounce?: number;
  dense?: boolean;
  errorMessage?: string;
  multiline?: boolean;
  onUpdate: (value: string) => void;
  placeholder?: string;
  textAlign?: string;
  title?: string;
  validator?: (value: string) => boolean;
  value: string;
  variant?: 'outlined' | 'standard';
};

const EditableTextField = ({
  autoFocus = false,
  debounce = 1000,
  dense = false,
  errorMessage = '',
  multiline = false,
  onUpdate,
  placeholder,
  textAlign,
  title,
  variant,
  validator,
  value,
}: EditableTextFieldProps) => {
  const { currentValue, isValid, onValueChange } = useInput({ debounce, onUpdate, validator, value });

  return (
    <Stack className={'CAKE__editable-text-field'}>
      {!dense && (
        <span
          className={classNames({
            'CAKE__editable-text-field__title': true,
            'CAKE__editable-text-field__title--standard': variant === 'standard',
            'CAKE__editable-text-field__title--error': !isValid,
          })}
        >
          {title}
        </span>
      )}
      <Stack
        className={classNames({
          'CAKE__editable-text-field__input-container': true,
          'CAKE__editable-text-field__input-container--error': !isValid,
          'CAKE__editable-text-field__input-container--dense': dense,
          'CAKE__editable-text-field__input-container--standard': variant === 'standard',
        })}
      >
        {!multiline && (
          <input
            autoFocus={autoFocus}
            className={classNames({
              'CAKE__editable-text-field__input-container__input': true,
              'CAKE__editable-text-field__input-container__input--error': !isValid,
              'CAKE__editable-text-field__input-container__input--right': textAlign === 'right',
            })}
            value={currentValue || ''}
            onChange={onValueChange}
            placeholder={placeholder}
          />
        )}
        {multiline && (
          <textarea
            autoFocus={autoFocus}
            rows={5}
            className={
              'CAKE__editable-text-field__input-container__input CAKE__editable-text-field__input-container__input--multiline'
            }
            value={currentValue || ''}
            onChange={onValueChange}
          />
        )}
        <Stack className={'CAKE__editable-text-field__right-container'}>
          {validator && isValid && currentValue?.length > 0 && <ConfirmationIcon />}
        </Stack>
      </Stack>
      {!isValid && errorMessage && (
        <span
          className={classNames({
            'CAKE__editable-text-field__error-message': true,
            'CAKE__editable-text-field__error-message--dense': dense,
          })}
        >
          {errorMessage}
        </span>
      )}
    </Stack>
  );
};

export default EditableTextField;
