import '~components/dashboard/Feed/Feed.scss';

import { Button, Card, CardContent, CardMedia, TextField } from '@mui/material';
import { useState } from 'react';

import FileUploadIconButton from '~components/buttons/FileUploadIconButton';
import { selectUserPostAttachment } from '~features/streams/streams.selectors';
import { addStreamActivity, setUserPostAttachment, uploadUserPostImage } from '~features/streams/streams.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const UserPost = () => {
  const dispatch = useAppDispatch();
  const attachement = useAppSelector(selectUserPostAttachment);
  const [commentText, setCommentText] = useState('');

  const handleCommentText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
  };

  const handleFileUpload = (file: File) => {
    dispatch(uploadUserPostImage({ file }));
  };

  const handleCommentSubmit = () => {
    if (commentText) {
      dispatch(
        addStreamActivity({
          verb: 'userPost',
          object: {
            content: commentText,
            media: attachement ? attachement : null,
          },
        }),
      );
      dispatch(setUserPostAttachment({ location: '' }));
    }
    setCommentText('');
  };

  return (
    <Card sx={{ borderRadius: '0px' }}>
      <CardContent className={'CAKE__feed__activityCard__add-comment__container'}>
        {attachement && (
          <CardMedia
            className={'CAKE__feed__activityCard__content-post-image'}
            component="img"
            height="250"
            image={attachement}
            alt={'postImage'}
          />
        )}
        <TextField
          className={'CAKE__feed__activityCard__add-comment__text-input'}
          placeholder="Comment"
          value={commentText}
          onChange={handleCommentText}
          multiline
          rows={2}
        ></TextField>
        <div className={'CAKE__feed__activityCard__add-comment__button-container'}>
          <Button
            variant="outlined"
            onClick={handleCommentSubmit}
          >
            Add Comment
          </Button>
          <FileUploadIconButton onUpload={handleFileUpload} />
        </div>
      </CardContent>
    </Card>
  );
};

export default UserPost;
