import '~components/collections/CollectionItemModal/CollectionItemModal.scss';

import { Box, Button, Modal } from '@mui/material';
import { useState } from 'react';

import CollectionItemContent from '~components/collections/CollectionItemModal/components/CollectionItemContent';
import type CollectionItemType from '~types/CollectionItemType';

type CollectionItemModalProps = {
  token: CollectionItemType;
  open: boolean;
  onAccept: (token: CollectionItemType) => void;
  onClose: () => void;
  onDelete: (token: CollectionItemType) => void;
};

const CollectionItemModal = ({ token, open, onAccept, onClose, onDelete }: CollectionItemModalProps) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [currentToken, setCurrentToken] = useState<CollectionItemType>(token);

  const onAcceptChanges = () => {
    onAccept(currentToken);
  };

  const onDeleteToken = () => {
    onDelete(currentToken);
  };

  const onChangeToken = (newToken: CollectionItemType) => {
    if (!isChanged) {
      setIsChanged(true);
    }
    setCurrentToken(newToken);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={'CAKE__collection-item-modal'}>
        <Box className={'CAKE__collection-item-modal__content'}>
          <CollectionItemContent
            token={currentToken}
            onChange={onChangeToken}
          />
        </Box>
        <Box className={'CAKE__collection-item-modal__footer'}>
          <Box>
            <Button
              color={'error'}
              onClick={onDeleteToken}
            >
              DELETE
            </Button>
          </Box>
          <Box>
            <Button onClick={onClose}>CANCEL</Button>
            <Button
              onClick={onAcceptChanges}
              disabled={!isChanged}
            >
              ACCEPT
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CollectionItemModal;
