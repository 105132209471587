// eslint-disable-next-line absolutely-import/import
import './ProjectDrawerFooter.scss';

import { Avatar, Box, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';

import { selectUserGravatar, selectUserNickname } from '~features/auth/auth.selectors';
import { logout } from '~features/auth/auth.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { LogoutIcon, SettingsIcon } from '~icons/common';

type ProjectDrawerFooterProps = {
  small: boolean;
};

const ProjectDrawerFooter = ({ small }: ProjectDrawerFooterProps) => {
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(selectUserGravatar);
  const userNickname = useAppSelector(selectUserNickname);

  return (
    <Box
      className={classNames({
        'CAKE__project-drawer-footer': true,
        'CAKE__project-drawer-footer--hidden': small,
      })}
    >
      <Box className={'CAKE__project-drawer-footer__side-container'}>
        <Avatar
          className={'CAKE__project-drawer-footer__avatar'}
          variant="rounded"
          src={userAvatar}
        ></Avatar>
        <Typography
          variant={'body2'}
          className={classNames({
            'CAKE__project-drawer-footer__element': true,
            'CAKE__project-drawer-footer__name': true,
            'CAKE__project-drawer-footer__element--hidden': small,
          })}
        >
          {userNickname}
        </Typography>
      </Box>
      <Box
        className={classNames({
          'CAKE__project-drawer-footer__side-container': true,
          'CAKE__project-drawer-footer__element': true,
          'CAKE__project-drawer-footer__element--hidden': small,
        })}
      >
        <IconButton
          onClick={() => console.log('settings')}
          size="small"
        >
          <SettingsIcon />
        </IconButton>
        <IconButton
          onClick={() => dispatch(logout())}
          size="small"
        >
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ProjectDrawerFooter;
