import { Card, Typography } from '@mui/material';

const FeedEmpty = () => {
  return (
    <Card className={'CAKE__feed__activityCard--empty'}>
      <Typography> There is not activity for this project </Typography>
    </Card>
  );
};

export default FeedEmpty;
