import type { PayloadAction } from '@reduxjs/toolkit';
import Pubnub from 'pubnub';
import { buffers, eventChannel } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { selectIsLoggedIn, selectUserId } from '~features/auth/auth.selectors';
import { setIsLoggedIn } from '~features/auth/auth.slice';
import { pubsubEvents } from '~features/pubsub/pubsub.helpers';
import { selectSubscribedChannels } from '~features/pubsub/pubsub.selectors';
import { subscribeChannels, unsubscribeChannels, updateChannelSubscribtion } from '~features/pubsub/pubsub.slice';

let pubnub = null;

const initPubsubClient = (userId) =>
  eventChannel((emitter) => {
    pubnub = new Pubnub({
      publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
      userId,
      restore: true,
    });

    const events = pubsubEvents(emitter, pubnub);
    pubnub.addListener(events);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, buffers.expanding());

function* initPubsubSaga() {
  const userId = yield select(selectUserId);
  const isLoggedIn = yield select(selectIsLoggedIn);

  if (!userId || !isLoggedIn) {
    return;
  }
  const channel = yield call(initPubsubClient, userId);

  while (true) {
    try {
      const action = yield take(channel);
      yield put(action);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ event: 'pubsub failed', error });
    }
  }
}

function* subscribeChannelsSaga(action: PayloadAction<Array<string>>) {
  const requestedChannels = yield action.payload;
  const subscribedChannels = yield select(selectSubscribedChannels);
  const newChannels = requestedChannels.filter((item: string) => !subscribedChannels.includes(item));

  pubnub.subscribe({
    channels: newChannels,
    withPresence: true,
  });

  yield put(updateChannelSubscribtion([...subscribedChannels, ...newChannels]));
}

function* unsubscribeChannelsSaga(action: PayloadAction<Array<string>>) {
  const requestedChannels = yield action.payload;
  const subscribedChannels = yield select(selectSubscribedChannels);
  const updatedChannels = subscribedChannels.filter((item: string) => !requestedChannels.includes(item));

  yield pubnub.unsubscribe({
    channels: requestedChannels,
  });

  yield put(updateChannelSubscribtion(updatedChannels));
}

export default function* pubsubSaga() {
  yield all([
    yield takeLatest(setIsLoggedIn.type, initPubsubSaga),
    yield takeLatest(subscribeChannels.type, subscribeChannelsSaga),
    yield takeLatest(unsubscribeChannels.type, unsubscribeChannelsSaga),
  ]);
}
