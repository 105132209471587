import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import BasicSettings from '~components/dashboard/ContentItemEditor/components/BasicSettings';
import ContentSettings from '~components/dashboard/ContentItemEditor/components/ContentSettings';
import MarkdownSettings from '~components/dashboard/ContentItemEditor/components/MarkdownSettings';
import SubmarineDocumentsSettings from '~components/dashboard/ContentItemEditor/components/SubmarineDocumentsSettings';
import TokenGatePolicySettings from '~components/dashboard/ContentItemEditor/components/TokenGatePolicySettings';
import DashboardCard from '~components/dashboard/DashboardCard';
import type { FileUploadKey } from '~components/inputs/FileUploadInput';
import UndoDeleteBanner from '~components/miscs/UndoDeleteBanner/UndoDeleteBanner';
import UtilityPublishControls from '~components/miscs/UtilityPublishControls/UtilityPublishControls';
import { addNotification } from '~features/notifications/notifications.slice';
import {
  deleteIPFSImage,
  getProjectContent,
  updateProjectContentItem,
  uploadImage,
  uploadIPFSImage,
} from '~features/project-content/project-content.slice';
import {
  getTokenGatePolicyConfig,
  resetTokenGatePolicyConfig,
  updateTokenGatePolicyConfig,
} from '~features/token-gate-policy/token-gate-policy.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import type { ContentItemType } from '~types/ContentType';
import type { TokenGatePolicy } from '~types/TokenGateTypes';
import useInterval from '~utils/Interval/Interval';

type ContentItemEditorProps = {
  contentItem: ContentItemType;
};

const ContentItemEditor = ({ contentItem }: ContentItemEditorProps) => {
  const dispatch = useAppDispatch();
  const [contentData, setContentData] = useState<string>(contentItem.content);
  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectContent({ id: projectId }));
    dispatch(getTokenGatePolicyConfig({ id: contentItem.policyId }));
    return () => {
      dispatch(resetTokenGatePolicyConfig());
    };
  }, []);

  useInterval(() => dispatch(getProjectContent({ id: projectId })), 1000 * 60 * 60 * 10); // refresh ipfs access token link every 10-min

  const handleDelete = () => {
    dispatch(updateProjectContentItem({ ...contentItem, isArchieved: true, status: 'Archieved' }));
  };

  const handleUndoDelete = () => {
    dispatch(updateProjectContentItem({ ...contentItem, isArchieved: false, status: 'Draft' }));
    dispatch(
      addNotification({
        message: 'Item has been restored to draft-mode',
        severity: 'success',
        duration: 3000,
      }),
    );
  };

  const onUpdateTitle = (value: string) => {
    dispatch(updateProjectContentItem({ ...contentItem, title: value }));
  };

  const onUpdateAuthor = (value: string) => {
    dispatch(updateProjectContentItem({ ...contentItem, author: { ...contentItem.author, name: value } }));
  };

  const handleStatusChange = () => {
    const status = contentItem.status === 'Published' ? 'Draft' : 'Published';
    dispatch(updateProjectContentItem({ ...contentItem, status }));
  };

  const onUploadFile = (file: File, key: FileUploadKey) => {
    dispatch(uploadImage({ file, key }));
  };

  const onUpdateDescription = (value: string) => {
    dispatch(updateProjectContentItem({ ...contentItem, description: value }));
  };

  const onUpdateSlug = (value: string) => {
    dispatch(updateProjectContentItem({ ...contentItem, slug: value }));
  };

  const onUpdateTags = (tags: any) => {
    dispatch(updateProjectContentItem({ ...contentItem, tags }));
  };

  const onUploadIPFSFile = (file: File) => {
    dispatch(uploadIPFSImage({ file }));
  };

  const onDeleteIPFSFile = (id: string) => {
    dispatch(deleteIPFSImage({ id }));
  };

  const handleChangeMarkdown = (text: string) => {
    setContentData(text);
    debouncedMarkdownValueChange(text);
  };

  const handleContentUpdate = (value: string) => {
    dispatch(updateProjectContentItem({ ...contentItem, content: value }));
  };

  const debouncedMarkdownValueChange = useMemo(() => debounce(handleContentUpdate, 2000), [handleContentUpdate]);

  const handlePolicyUpdate = (policy: TokenGatePolicy) => {
    dispatch(updateTokenGatePolicyConfig(policy));
  };

  return (
    <>
      <UndoDeleteBanner
        isOpen={contentItem.isArchieved}
        handleUndoDelete={handleUndoDelete}
      />
      <DashboardCard
        subtitle={'Content Editor'}
        rightContainer={
          <UtilityPublishControls
            isPublished={contentItem.status === 'Published' ? true : false}
            handleStatusChange={handleStatusChange}
            handleDelete={handleDelete}
          />
        }
      >
        <BasicSettings
          contentItem={contentItem}
          onUpdateTitle={onUpdateTitle}
          onUploadFile={onUploadFile}
          onUpdateAuthor={onUpdateAuthor}
          onUpdateDescription={onUpdateDescription}
        />
        <MarkdownSettings
          data={contentData}
          handleChangeMarkdown={handleChangeMarkdown}
        />
        <ContentSettings
          contentItem={contentItem}
          onUpdateSlug={onUpdateSlug}
          onUpdateTags={onUpdateTags}
        />
        <SubmarineDocumentsSettings
          contentItem={contentItem}
          onUploadIPFSFile={onUploadIPFSFile}
          onDeleteIPFSFile={onDeleteIPFSFile}
        />
        <TokenGatePolicySettings handlePolicyUpdate={handlePolicyUpdate} />
      </DashboardCard>
    </>
  );
};

export default ContentItemEditor;
