import '~components/collections/CollectionViewer/CollectionViewer.scss';

import { Box, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import CollectionItemModal from '~components/collections/CollectionItemModal';
import CollectionItem from '~components/collections/CollectionViewer/components/CollectionItem';
import CollectionViewerEmptyState from '~components/collections/CollectionViewer/components/CollectionViewerEmptyState';
import CollectionViewerHeader from '~components/collections/CollectionViewer/components/CollectionViewerHeader';
import ComponentLoader from '~components/miscs/ComponentLoader';
import {
  selectCollectionIsNextPage,
  selectCollectionIsPageLoading,
  selectCollectionTokens,
} from '~features/collection-items/collection-items.selectors';
import {
  clearCollection,
  deleteCollectionItem,
  getCollection,
  getCollectionNextPage,
  updateCollectionItem,
} from '~features/collection-items/collection-items.slice';
import { selectIsCollectionUpladInProgress } from '~features/collection-upload/collection-upload.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type CollectionItemType from '~types/CollectionItemType';

const CollectionViewer = () => {
  const dispatch = useAppDispatch();
  const tokens = useAppSelector(selectCollectionTokens);
  const isNextPageLoading = useAppSelector(selectCollectionIsPageLoading);
  const isNextPage = useAppSelector(selectCollectionIsNextPage);
  const isUploading = useAppSelector(selectIsCollectionUpladInProgress);
  const [currentToken, setCurrentToken] = useState<CollectionItemType>(null);

  const areTokens: boolean = tokens.length > 0;
  const isCollectionView: boolean = areTokens || isUploading;

  useEffect(() => {
    if (!areTokens) {
      dispatch(getCollection());
    }
    return () => {
      dispatch(clearCollection());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditCollectionItem = (token: CollectionItemType) => {
    setCurrentToken(token);
  };

  const onAcceptCollectionItem = (token: CollectionItemType) => {
    dispatch(updateCollectionItem(token));
    setCurrentToken(null);
  };

  const onDeleteCollectionItem = (token: CollectionItemType) => {
    dispatch(deleteCollectionItem(token));
    setCurrentToken(null);
  };
  const onCloseCollectionItemModal = () => {
    setCurrentToken(null);
  };

  const onScrollHandler = (values) => {
    if (isNextPageLoading && !isNextPage) {
      return;
    }
    const shouldGetPage = values.scrollHeight * values.top > values.scrollHeight - 300;
    if (!shouldGetPage) {
      return;
    }

    dispatch(getCollectionNextPage());
  };

  console.log({ isUploading, isCollectionView });
  return (
    <div className={'CAKE__collection-viewer'}>
      {currentToken && (
        <CollectionItemModal
          token={currentToken}
          open={!!currentToken}
          onAccept={onAcceptCollectionItem}
          onClose={onCloseCollectionItemModal}
          onDelete={onDeleteCollectionItem}
        />
      )}
      {!areTokens && !isNextPageLoading && !isCollectionView && <CollectionViewerEmptyState />}
      {!areTokens && isNextPageLoading && <ComponentLoader isOpen={isNextPageLoading} />}
      {isCollectionView && (
        <>
          <CollectionViewerHeader />
          {areTokens && (
            <Box className={'CAKE__collection-viewer__container'}>
              <Scrollbars
                autoHide
                onScrollFrame={onScrollHandler}
              >
                <Grid
                  container
                  spacing={2}
                  paddingX={1}
                  paddingY={1}
                  columns={{ xs: 4, sm: 8, md: 12, lg: 16, xl: 24 }}
                >
                  {tokens.map((item: CollectionItemType, idx: number) => (
                    <Grid
                      item
                      xs={4}
                      key={`collection-item-${idx}`}
                    >
                      <CollectionItem
                        token={item}
                        onEdit={onEditCollectionItem}
                      />
                    </Grid>
                  ))}
                </Grid>
                {isNextPageLoading && (
                  <Box className={'CAKE__collection-viewer__loading-container'}>
                    <CircularProgress disableShrink />
                  </Box>
                )}
              </Scrollbars>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default CollectionViewer;
