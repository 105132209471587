import '~components/dashboard/Feed/Feed.scss';

import { CardMedia, Typography } from '@mui/material';

import type { FeedAggregateActivityType } from '~types/Streams';
import isVideo from '~utils/regex/isVideoURL';

type CakeConfigActivityContentProps = {
  data: FeedAggregateActivityType;
};

const CakeConfigActivityContent = ({ data }: CakeConfigActivityContentProps) => {
  return (
    <div>
      <Typography
        variant="body1"
        color="text"
      >
        {data.activities[0].object.data.content['event']}
      </Typography>
      <Typography fontSize={'14px'}>
        {data.activities[0].object.data.content['description'] && data.activities[0].object.data.content['description']}
      </Typography>
      <Typography pt={2}>
        {data.activities[0].object.data['link'] && (
          <a
            className={'CAKE__feed__activityCard__content--activityLink'}
            href={data.activities[0].object.data['link']}
            target="_blank"
            rel="noreferrer"
          >
            View here
          </a>
        )}
      </Typography>
      {data.activities[0].object.data['media'] && isVideo(data.activities[0].object.data['media']) ? (
        <video
          autoPlay
          loop
          muted={true}
          src={data.activities[0].object.data['media']}
        />
      ) : data.activities[0].object.data['media'] ? (
        <CardMedia
          className={'CAKE__feed__activityCard__content-post-image'}
          component="img"
          height="194"
          sx={{ objectFit: 'contain' }}
          image={data.activities[0].object.data['media']}
          alt={'new-activity-image'}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CakeConfigActivityContent;
