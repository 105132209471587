import AddCommentIcon from '@mui/icons-material/AddComment';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { CardActions, Collapse, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityComment from '~components/dashboard/Feed/components/ActivityComment/ActivityComment';
import AddComment from '~components/dashboard/Feed/components/AddComment/AddComment';
import { selectUserId } from '~features/auth/auth.selectors';
import { addActivityReaction, removeStreamReaction } from '~features/streams/streams.slice';
import useAppSelector from '~hooks/useAppSelector';
import type { FeedAggregateActivityType } from '~types/Streams';

type ActivityFooterProps = {
  data: FeedAggregateActivityType;
};

const ActivityFooter = ({ data }: ActivityFooterProps) => {
  const dispatch = useDispatch();
  const userId = useAppSelector(selectUserId);
  const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [userLikeId, setUserLikeId] = useState<string>('');
  const [likes, setLikes] = useState<number>(0);
  const [comments, setComments] = useState<number>(0);

  const handleCommentClick = () => setIsCommentOpen(!isCommentOpen);

  const handleLikeClick = () => {
    if (isLiked) {
      dispatch(
        removeStreamReaction({
          reactionId: userLikeId,
          activityId: data.activities[0].id,
          isChild: false,
        }),
      );
    } else {
      dispatch(
        addActivityReaction({
          kind: 'like',
          activityId: data.activities[0].id,
        }),
      );
    }
    setIsLiked(!isLiked);
  };

  useEffect(() => {
    if (data.activities[0].reaction_counts.like || data.activities[0].reaction_counts.like === 0) {
      setLikes(data.activities[0].reaction_counts.like);
    }

    if (data.activities[0].reaction_counts.comment) {
      setComments(data.activities[0].reaction_counts.comment);
    }

    if (data.activities[0].own_reactions.like) {
      for (let i = 0; data.activities[0].own_reactions.like.length; i++) {
        if (data.activities[0].own_reactions.like[i].user_id === userId.split('|')[1]) {
          setUserLikeId(data.activities[0].own_reactions.like[i].id);
          setIsLiked(true);
          return;
        }
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CardActions disableSpacing>
        <IconButton
          className={'CAKE__feed__activityCard__reaction-icon'}
          aria-label="like"
          onClick={handleLikeClick}
          sx={{ color: isLiked ? 'green' : 'none' }}
        >
          <ThumbUpAltOutlinedIcon />
          <Typography>{likes ? likes : ''}</Typography>
        </IconButton>
        <IconButton
          className={'CAKE__feed__activityCard__reaction-icon'}
          aria-label="comment"
          onClick={handleCommentClick}
        >
          <AddCommentIcon />
          <Typography>{comments ? comments : ''}</Typography>
        </IconButton>
      </CardActions>
      <Collapse
        in={isCommentOpen}
        timeout="auto"
        unmountOnExit
      >
        <AddComment activityId={data.activities[0].id} />
        {data.activities[0].latest_reactions.comment &&
          data.activities[0].latest_reactions.comment.map((comment) => {
            return (
              <ActivityComment
                key={comment.id}
                comment={comment}
              />
            );
          })}
      </Collapse>
    </>
  );
};

export default ActivityFooter;
