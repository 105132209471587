import { TableCell, TableRow, Typography } from '@mui/material';

type ProjectRecipientSummaryItemProps = {
  totalShare: number;
};

const ProjectRecipientSummaryItem = ({ totalShare }: ProjectRecipientSummaryItemProps) => {
  return (
    <TableRow
      className={'CAKE__project-table__summary-item'}
      tabIndex={-1}
      key={'project-recipient-item-summary'}
      sx={{ 'td, th': { border: 0 } }}
    >
      <TableCell
        component="th"
        scope="row"
        padding="none"
        rowSpan={2}
      >
        <Typography
          fontSize={'14px'}
          fontWeight={800}
          paddingLeft={1}
        >
          {'Total'}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography
          color={totalShare === 100 ? 'none' : 'error'}
          fontSize={'14px'}
          fontWeight={800}
          paddingLeft={1}
        >{`${totalShare}%`}</Typography>
      </TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  );
};

export default ProjectRecipientSummaryItem;
