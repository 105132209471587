import { useEffect } from 'react';

export default function useDebounce<T extends (...params: any[]) => void>(
  handler: T,
  watchedValue: Array<any>,
  delay: number,
) {
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      handler();
    }, delay);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [...watchedValue, delay]);
}
