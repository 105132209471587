import { Table, TableBody, TableContainer } from '@mui/material';

import ProjectItem from '~components/projects/ProjectsTable/components/ProjectItem';
import ProjectsTableHead from '~components/projects/ProjectsTable/components/ProjectsTableHead';
import type { ProjectType } from '~types/ProjectType';

type ProjectsTableProps = {
  projects: Array<ProjectType>;
  onSelectItem: (project: ProjectType) => void;
};

const ProjectsTable = ({ projects, onSelectItem }: ProjectsTableProps) => {
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="projects"
        size={'medium'}
      >
        <ProjectsTableHead />
        <TableBody>
          {projects.map((item: ProjectType) => (
            <ProjectItem
              key={`project-item-${item._id}`}
              project={item}
              onClick={onSelectItem}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectsTable;
