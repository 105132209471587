import { Stack, Typography } from '@mui/material';

import NetworkSwitch from '~components/miscs/NetworkToggle/NetworkSwitch';

type NetworkToggleProps = {
  showToggle: boolean;
  chainId: number | null;
  handleNetworkChange: any;
};

const NetworkToggle = ({ showToggle, chainId, handleNetworkChange }: NetworkToggleProps) => {
  if (!showToggle) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Typography>Test</Typography>
      <NetworkSwitch
        checked={chainId === 1 ? true : false}
        inputProps={{ 'aria-label': 'ant design' }}
        onChange={handleNetworkChange}
      />
      <Typography>Production</Typography>
    </Stack>
  );
};

export default NetworkToggle;
