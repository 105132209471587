import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContractTransactionsTable from '~components/dashboard/ContractTransactionsTable/ContractTransactionsTable';
import MintAnalyticsChart from '~components/dashboard/MintAnalyticsChart/MintAnalyticsChart';
import WalletDistributionChart from '~components/dashboard/WalletDistributionChart/WalletDistributionChart';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import {
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectAnalyticsView = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const isError = useAppSelector(selectProjectConfigError);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return <NotFoundView />;
  }

  return (
    <DashboardPageLayout>
      <Box
        style={{ display: 'flex', flexDirection: 'column' }}
        marginRight={8}
      >
        <Box m={4}>
          <MintAnalyticsChart />
        </Box>
        <Box m={4}>
          <WalletDistributionChart />
        </Box>
        <Box m={4}>
          <ContractTransactionsTable />
        </Box>
      </Box>
    </DashboardPageLayout>
  );
};

export default ProjectAnalyticsView;
