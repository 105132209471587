import { useEffect } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import ProjectControls from '~components/projects/ProjectControls';
import ProjectsTable from '~components/projects/ProjectsTable';
import PrivateRoutes from '~constants/routes/private-routes.consts';
import { selectAllProjects, selectAllProjectsIsLoading } from '~features/projects-list/projects-list.selectors';
import { getAllProjects } from '~features/projects-list/projects-list.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import useSmartNavigate from '~hooks/useSmartNavigate';
import type { ProjectType } from '~types/ProjectType';

const ProjectsListView = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useSmartNavigate();

  const projects: Array<ProjectType> = useAppSelector(selectAllProjects);
  const isLoading: boolean = useAppSelector(selectAllProjectsIsLoading);

  useEffect(() => {
    dispatch(getAllProjects());
  }, []);

  const onSelectProject = (project: ProjectType) => {
    navigate(PrivateRoutes.DASHBOARD_PROJECT_OVERVIEW, {
      id: project._id,
    });
  };

  const onAddProject = () => {
    navigate(PrivateRoutes.PROJECT_BUILDER);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <DashboardPageLayout>
      <DashboardCard>
        <ProjectControls onAddProject={onAddProject} />
        <ProjectsTable
          projects={projects}
          onSelectItem={onSelectProject}
        />
      </DashboardCard>
    </DashboardPageLayout>
  );
};

export default ProjectsListView;
