import { useNavigate } from 'react-router-dom';

type Options = Record<string, string>;

/**
 * Wrapper around react router's useNavigate hook to allow you to
 * more easily use routes of the form /dashboard/project/utility/:id/drops/:drop_id.
 * You can pass an options object to replace the values corresponding to each variable segment.
 */
const useSmartNavigate = () => {
  const navigate = useNavigate();

  return {
    createHref,
    navigate: smartNavigate,
  };

  function createHref(route: string, options?: Options) {
    return getFormattedRoute(route, options);
  }

  function smartNavigate(route: string, options?: Options) {
    return navigate(getFormattedRoute(route, options));
  }

  function getFormattedRoute(route: string, options?: Options) {
    if (!options) {
      return route;
    }

    return Object.keys(options).reduce((acc, current) => {
      return acc.replace(`:${current}`, options[current]);
    }, route);
  }
};

export default useSmartNavigate;
