import queryString from 'query-string';

const getBatterRequestPath = (path: string, params?: { [key: string]: Array<string | number> }) => {
  const url = process.env.REACT_APP_BATTER_API_URL || '';
  const port = process.env.REACT_APP_BATTER_API_PORT || '';
  const version = process.env.REACT_APP_BATTER_API_VERSION || '';

  let requestURL = `${url}:${port}/${version}/${path}`;
  if (params) {
    requestURL += `?${queryString.stringify(params)}`;
  }
  return requestURL;
};

export { getBatterRequestPath };
