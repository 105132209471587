import '~components/layout/NoMenuLayout/NoMenuLayout.scss';

import { Box } from '@mui/system';
import type { PropsWithChildren } from 'react';

import logo from '~images/common/logo/logo_wide.png';

type NoMenuLayoutProps = PropsWithChildren<Record<string, never>>;

const NoMenuLayout = ({ children }: NoMenuLayoutProps) => {
  return (
    <div className={'CAKE__no-menu-layout'}>
      <Box className={'CAKE__no-menu-layout__image--logo'}>
        <img src={logo} />
      </Box>

      <div className={'CAKE__no-menu-layout__content'}>{children}</div>
    </div>
  );
};

export default NoMenuLayout;
