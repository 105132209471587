import type { TokenGateOperatorInputType } from '~types/TokenGateTypes';

const tokenGateInputOperators: Array<TokenGateOperatorInputType> = [
  { value: 'eq', label: 'Equals' },
  { value: 'neq', label: 'Not Equal' },
  { value: 'gte', label: 'Greater Than or Equal' },
  { value: 'gt', label: 'Greater Than' },
  { value: 'lte', label: 'Less Than or Eqaul' },
  { value: 'lt', label: 'Less Than' },
];

export default tokenGateInputOperators;
