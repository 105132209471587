import '~components/dashboard/MintAnalyticsChart/MintAnalyticsChart.scss';

import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import AnalyticsRefreshButton from '~components/buttons/AnalyticsRefreshButton';
import DashboardCard from '~components/dashboard/DashboardCard';
import NetworkToggle from '~components/miscs/NetworkToggle/NetworkToggle';
import PageLoader from '~components/miscs/PageLoader';
import { GOERLI_NETWORK_ID, MAINNET_NETWORK_ID } from '~constants/networks/networks';
import { selectFormattedMintAnalytics } from '~features/analytics/analytics.selectors';
import { getMintAnalytics } from '~features/analytics/analytics.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const MintAnalyticsChart = () => {
  const dispatch = useAppDispatch();
  const [chainId, setChainId] = useState<number | null>(GOERLI_NETWORK_ID);
  const data = useAppSelector(selectFormattedMintAnalytics);
  const projectConfig = useAppSelector(selectProjectConfig);

  useEffect(() => {
    if (projectConfig && projectConfig._id) {
      dispatch(
        getMintAnalytics({
          projectId: projectConfig._id,
          contractAddress:
            chainId === 1
              ? projectConfig.contract.livenet.contractAddress
              : projectConfig.contract.testnet.contractAddress,
          refresh: true,
        }),
      );
    }

    // eslint-disable-next-line
  }, [chainId]);

  const handleNetworkChange = () => {
    return chainId === 1 ? setChainId(GOERLI_NETWORK_ID) : setChainId(MAINNET_NETWORK_ID);
  };

  if (!projectConfig) {
    return <PageLoader />;
  }

  return (
    <DashboardCard
      title={'Mint Tracker'}
      rightContainer={
        <div className={'CAKE__mint-analytics-chart__dashboard-card'}>
          <NetworkToggle
            showToggle={true}
            chainId={chainId}
            handleNetworkChange={handleNetworkChange}
          />
          <AnalyticsRefreshButton
            type="MINT_ANALYTICS"
            projectId={projectConfig._id}
            contractAddress={
              chainId === 1
                ? projectConfig.contract.livenet.contractAddress
                : projectConfig.contract.testnet.contractAddress
            }
          />
        </div>
      }
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{
          justifyContent: data && data.length ? 'end' : 'center',
          alignContent: data && data.length ? 'end' : 'center',
          minHeight: data && data.length ? '50%' : '150px',
        }}
      >
        {data && data.length ? (
          <ResponsiveContainer
            width="100%"
            height={300}
          >
            <AreaChart
              width={900}
              height={400}
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
            >
              <Area
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                fillOpacity={1}
                fill="#8884d8"
              />
              <Tooltip />
              <XAxis
                dataKey="name"
                interval="preserveStartEnd"
              />
              <YAxis />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <Typography>There are no mints for this contract.</Typography>
        )}
      </Grid>
    </DashboardCard>
  );
};

export default MintAnalyticsChart;
