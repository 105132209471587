import { Buffer } from 'buffer';
import FormData from 'form-data';
import { call } from 'redux-saga/effects';

window.Buffer = window.Buffer || Buffer;

const key = String(process.env.REACT_APP_PINATA_SUBMARINE_KEY || '');
const host = String(process.env.REACT_APP_PINATA_API_URL || '');

function* uploadFileToSubmarineIPFS(file: File) {
  const formData = new FormData();
  formData.append('files', file);
  formData.append('name', file.name);
  formData.append('pinToIPFS', 'false');

  const requestURL = `${host}/api/v1/content`;
  const formHeaders = formData.getHeaders ? formData.getHeaders() : { 'Content-Type': 'multipart/form-data' };

  try {
    const res: any = yield call<(info: RequestInfo, config: any) => any>(fetch, requestURL, {
      method: 'post',
      headers: {
        'x-api-key': key,
        formHeaders,
      },
      body: formData,
    });
    return res;
  } catch (e) {
    console.log({ e });
  }
}

export { uploadFileToSubmarineIPFS };
