import { List } from '@mui/material';
import React from 'react';
import { FixedSizeList } from 'react-window';

import WhitelistAddressItem from '~components/dashboard/WhitelistSettingsCard/components/WhitelistAddressItem';
import type { WhitelistAddressMetadata } from '~types/WhitelistType';

type WhitelistAddressTableProps = {
  searchInput: string;
  whitelistAddressMetadata: WhitelistAddressMetadata;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleAddressSelectToggle: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleAddSingleWallet: Function;
};

const WhitelistAddressTable = ({
  searchInput,
  whitelistAddressMetadata,
  handleAddressSelectToggle,
  handleAddSingleWallet,
}: WhitelistAddressTableProps) => {
  const [addresses, setAddresses] = React.useState([]);

  React.useEffect(() => {
    setAddresses([...Object.keys(whitelistAddressMetadata)]);
  }, [whitelistAddressMetadata]);

  if (searchInput.length !== 0) {
    return (
      <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: 300, overflow: 'auto' }}>
        <WhitelistAddressItem
          address={searchInput}
          whitelistAddressMetadata={whitelistAddressMetadata}
          handleAddressSelectToggle={handleAddressSelectToggle}
          handleAddSingleWallet={handleAddSingleWallet}
        />
      </List>
    );
  }

  return (
    <FixedSizeList
      height={300}
      itemCount={addresses.length}
      itemSize={50}
      width={'100%'}
    >
      {({ index, style }): JSX.Element => {
        return (
          <div style={style}>
            <WhitelistAddressItem
              key={addresses[index]}
              address={addresses[index]}
              whitelistAddressMetadata={whitelistAddressMetadata}
              // setToastIsOpen={setToastIsOpen}
              // setToastData={setToastData}
              handleAddressSelectToggle={handleAddressSelectToggle}
              handleAddSingleWallet={handleAddSingleWallet}
            />
          </div>
        );
      }}
    </FixedSizeList>
  );
};

export default WhitelistAddressTable;
