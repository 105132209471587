import type { PayloadAction } from '@reduxjs/toolkit';
import { redirect } from 'react-router-dom';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectId } from '~features/project-config/project-config.selectors';
import { PRODUCT_LIMIT } from '~features/project-drop/project-drop.consts';
import {
  deleteProjectDrop,
  deleteProjectDropError,
  deleteProjectDropSuccess,
  getProjectDrop,
  getProjectDropProducts,
  saveProjectDrop,
  saveProjectDropError,
  saveProjectDropSuccess,
  setProjectDrop,
  setProjectDropError,
  setProjectDropProducts,
  setProjectDropProductsError,
} from '~features/project-drop/project-drop.slice';
import {
  deleteProtectedAPI,
  fetchProtectedAPI,
  postProtectedAPI,
  putProtectedAPI,
} from '~features/utils/api/api.sagas';
import type { BatterDrop, SprinklesDrop } from '~types/DropType';

function* fetchProjectProducts(action: PayloadAction<{ id: string }>): Iterator<any> {
  const { id } = action.payload;

  try {
    const res: Response = yield call(fetchProtectedAPI, `project/${id}/shopify/products?limit=${PRODUCT_LIMIT}`);

    const data: any = yield res.json();

    if (data) {
      yield put(setProjectDropProducts(data));
    } else {
      yield put(setProjectDropProductsError('Products not found'));
    }
  } catch (e) {
    console.error(e);
    yield put(setProjectDropProductsError(e.toString()));
  }
}

function* fetchProjectDropSaga(action: PayloadAction<{ id: string; dropId: string }>): Iterator<any> {
  const { id, dropId } = action.payload;

  try {
    const res: Response = yield call(fetchProtectedAPI, `project/${id}/drops/${dropId}`, {
      options: ['withProducts'],
    });
    const data: any = yield res.json();

    if (data) {
      yield put(setProjectDrop(data));
    } else {
      throw new Error('Project not found');
    }
  } catch (e) {
    console.error(e);
    yield put(setProjectDropError(e.toString()));
  }
}

function* deleteProjectDropSaga(action: PayloadAction<{ dropId: string }>): Iterator<any> {
  try {
    const { dropId } = action.payload;
    const projectId: string = yield select(selectProjectId);

    const res: Response = yield call(deleteProtectedAPI, `project/${projectId}/drops/${dropId}`);

    if (res.status === 200) {
      yield res.json();

      yield put(
        addNotification({
          message: 'Successfully deleted the drop.',
          severity: 'successs',
          duration: 3000,
        }),
      );
      yield put(deleteProjectDropSuccess());

      redirect(`/dashboard/project/utility/${projectId}`);
    } else {
      throw new Error(`Failed with status code ${res.status}`);
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: e.toString(),
        severity: 'error',
        duration: 3000,
      }),
    );
    yield put(deleteProjectDropError(e.toString()));
  }
}

function* saveProjectDropSaga(
  action: PayloadAction<{ dropId?: string; data: Required<SprinklesDrop> }>,
): Iterator<any> {
  try {
    const { dropId, data } = action.payload;
    const projectId: string = yield select(selectProjectId);
    const shouldUpdateExisting = Boolean(dropId);

    const res: Response = yield call(
      shouldUpdateExisting ? putProtectedAPI : postProtectedAPI,
      `project/${projectId}/drops/${shouldUpdateExisting ? dropId : ''}`,
      data,
    );

    if (res.status === 200) {
      const data: BatterDrop = yield res.json();

      yield put(
        addNotification({
          message: 'Successfully saved the drop!',
          severity: 'successs',
          duration: 3000,
        }),
      );
      yield put(saveProjectDropSuccess(data));

      redirect(`/dashboard/project/utility/${projectId}/drops/${data._id}`);
    } else {
      throw new Error(`Failed with status code ${res.status}`);
    }
  } catch (e) {
    console.log(e);
    yield put(
      addNotification({
        message: e.toString(),
        severity: 'error',
        duration: 3000,
      }),
    );
    yield put(saveProjectDropError(e.toString()));
  }
}

export default function* projectDropSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getProjectDrop.type, fetchProjectDropSaga),
    yield takeLatest(getProjectDropProducts.type, fetchProjectProducts),
    yield takeLatest(saveProjectDrop.type, saveProjectDropSaga),
    yield takeLatest(deleteProjectDrop.type, deleteProjectDropSaga),
  ]);
}
