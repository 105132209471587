import { CardContent } from '@mui/material';
import { useCallback } from 'react';

import CakeActivityContent from '~components/dashboard/Feed/components/ActivityContent/components/CakeActivityContent';
import CakeConfigActivityContent from '~components/dashboard/Feed/components/ActivityContent/components/CakeConfigActivityContent';
import MintActivityContent from '~components/dashboard/Feed/components/ActivityContent/components/MintActivityContent';
import TransferActivityContent from '~components/dashboard/Feed/components/ActivityContent/components/TransferActivityContent';
import UserPostActivityContent from '~components/dashboard/Feed/components/ActivityContent/components/UserPostActivityContent';
import type { FeedAggregateActivityType } from '~types/Streams';

type ActivityContentProps = {
  data: FeedAggregateActivityType;
};

const ActivityContent = ({ data }: ActivityContentProps) => {
  const renderContent = useCallback(() => {
    switch (data.verb) {
      case 'transfer':
        return <TransferActivityContent data={data} />;
      case 'mint':
        return <MintActivityContent data={data} />;
      case 'cakeConfig':
        return <CakeConfigActivityContent data={data} />;
      case 'cake':
        return <CakeActivityContent data={data} />;
      case 'userPost':
        return <UserPostActivityContent data={data} />;
      default:
        return <CakeActivityContent data={data} />;
    }
  }, [data]);

  return <CardContent className={'CAKE__feed__activityCard__content'}>{renderContent()}</CardContent>;
};

export default ActivityContent;
