// eslint-disable-next-line absolutely-import/import
import './DashboardCard.scss';

import { Box, Stack, Typography } from '@mui/material';

type DashboardCardProps = {
  className?: string;
  containerClassName?: string;
  title?: string;
  subtitle?: string;
  rightContainer?: JSX.Element;
  leftContainer?: JSX.Element;
};

const DashboardCard = ({
  children,
  className = '',
  containerClassName = '',
  title,
  subtitle,
  rightContainer,
}: React.PropsWithChildren<DashboardCardProps>) => {
  return (
    <Box className={`CAKE__dashboard-card ${className}`}>
      <Stack
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          className={'CAKE__dashboard-card__header-container'}
          marginY={1.5}
        >
          <Box>
            {title && (
              <Typography
                className={'CAKE__dashboard-card__title'}
                variant={'h6'}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                className={'CAKE__dashboard-card__subtitle'}
                variant={'body2'}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          {rightContainer}
        </Box>
      </Stack>
      <Box className={`CAKE__dashboard-card__container ${containerClassName}`}>{children}</Box>
    </Box>
  );
};

export default DashboardCard;
