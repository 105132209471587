import getS3BaseURL from '~utils/api/getS3BaseURL';

const getS3RootProjectPath = (project: string) => {
  const baseURL: string = getS3BaseURL();
  const projectPrefix = String(process.env.REACT_APP_AWS_S3_PROJECT_PREFIX || '');

  const dir = `${projectPrefix ? `${projectPrefix}_` : ''}${project}`;
  const path = `${baseURL}${dir}`;
  return { dir, path };
};

export default getS3RootProjectPath;
