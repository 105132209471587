enum CampaignTypes {
  OneToOne = 'one-to-one',
  OneToMany = 'one-to-many',
}

type UnixDate = number;

type ShopifyProduct = {
  id: number;
  title: string;
  image?: string;
};

type OneToOneTrait = {
  traitType: string;
  traitValue: string;
};

type BatterDrop = {
  _id: string;
  productId: string;
  openWindowEpochTime: UnixDate;
  closeWindowEpochTime: UnixDate;
  isOneToOne: boolean;
  oneToOneTrait: OneToOneTrait | null;
  shopifyProduct: ShopifyProduct;
};

type SprinklesDrop = Omit<BatterDrop, '_id' | 'shopifyProduct'>;

export type { BatterDrop, SprinklesDrop };
export { CampaignTypes };
