import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack } from '@mui/material';

type ContentControlsProps = {
  onAddContentItem: () => void;
};

const ContentControls = ({ onAddContentItem }: ContentControlsProps) => {
  return (
    <Stack
      alignItems="flex-end"
      justifyContent="center"
    >
      <IconButton onClick={onAddContentItem}>
        <AddIcon color={'primary'} />
      </IconButton>
    </Stack>
  );
};

export default ContentControls;
