/* eslint-disable */
import React from 'react';

// import Papa from "papaparse";
import FileUploadButton from '~components/buttons/FileUploadButton/FileUploadButton';

type BulkUploadProps = {
  setIsMerkleDialogOpen: any;
};

const BulkUpload = ({ setIsMerkleDialogOpen }: BulkUploadProps) => {
  // const dispatch = useAppDispatch();
  // const whitelistConfig = useAppSelector(selectWhitelistConfig);

  const onUploadFile = (file: File) => {
    // let whitelist = {add:[], remove:[]}
    // Papa.parse(file, {
    //   header: false,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     results.data.map( (address:string) => {
    //       whitelist['add'].push(address[0].toLowerCase())
    //     })
    //     dispatch(updateWhitelistConfig({ ...whitelistConfig, whitelist: whitelist }));
    //     setIsMerkleDialogOpen(true)
    //   },
    //   error: function(err, file){
    //     addToastMessage('We were unable to upload your file.', 'error', 5000)
    //   }
    // });
  };

  return (
    <FileUploadButton
      title="Upload"
      accept=".csv"
      onUpload={onUploadFile}
    />
  );
};

export default BulkUpload;
