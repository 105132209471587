import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import CollectionArtworksCard from '~components/dashboard/CollectionArtworksCard';
import DashboardPageLayout from '~components/layout/DashboardPageLayout';
import PageLoader from '~components/miscs/PageLoader';
import {
  selectProjectConfigError,
  selectProjectConfigIsLoading,
} from '~features/project-config/project-config.selectors';
import { getProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import NotFoundView from '~views/NotFoundView';

const ProjectCollectionView = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectProjectConfigIsLoading);
  const isError = useAppSelector(selectProjectConfigError);

  const location = useLocation();
  const projectId = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(getProjectConfig({ id: projectId, force: false }));
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return <NotFoundView />;
  }

  return (
    <DashboardPageLayout>
      <Stack
        flexDirection={'row'}
        gap={2}
        height={'inherit'}
        marginRight={8}
      >
        <CollectionArtworksCard />
      </Stack>
    </DashboardPageLayout>
  );
};

export default ProjectCollectionView;
