import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { MouseEvent } from 'react';

import InputIconButton from '~components/buttons/InputIconButton';
import type TraitType from '~types/token/TraitType';

type TraitsTableType = {
  traits?: Array<TraitType>;
  onDelete: (item: TraitType, idx: number) => void;
  onEdit: (item: TraitType, idx: number) => void;
};

const TraitsTable = ({ traits, onDelete, onEdit }: TraitsTableType) => {
  const onDeleteHandler = (e: MouseEvent<HTMLDivElement>, item: TraitType, idx: number) => {
    e.stopPropagation();
    onDelete(item, idx);
  };
  return (
    <TableContainer sx={{ height: 'calc(100% - 40px)' }}>
      <Table
        stickyHeader
        size={'small'}
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(traits || []).map((item: TraitType, idx: number) => (
            <TableRow
              className={'CAKE__collection-item-content__traits__item'}
              key={`trait-${item.trait_type}`}
              onClick={() => onEdit(item, idx)}
            >
              <TableCell>{item.trait_type}</TableCell>
              <TableCell>{item.value}</TableCell>
              <TableCell width={'20px'}>
                <InputIconButton
                  onClick={(e) => onDeleteHandler(e, item, idx)}
                  color="#009688"
                >
                  <DeleteOutlineIcon />
                </InputIconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TraitsTable;
