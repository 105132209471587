import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { selectCurrentNotification, selectIsShortNotification } from '~features/notifications/notifications.selectors';
import { closeNotification } from '~features/notifications/notifications.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectCurrentNotification);
  const isShort = useAppSelector(selectIsShortNotification);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (notification) {
      setIsOpen(true);
    }
  }, [notification]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const handleExited = () => {
    dispatch(closeNotification());
  };

  return (
    notification && (
      <Snackbar
        open={isOpen}
        autoHideDuration={isShort ? 500 : notification.duration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <Alert
          severity={
            notification.severity === 'success'
              ? 'success'
              : notification.severity === 'error'
              ? 'error'
              : notification.severity === 'info'
              ? 'info'
              : 'warning'
          }
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    )
  );
};

export default Notifications;
