import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '~features';
import type { NotificationType } from '~types/miscs/NotificationType';

const selectNotifications = (state: RootState) => state.notifications.messages;

const selectCurrentNotification = createDraftSafeSelector(
  selectNotifications,
  (notifications: Array<NotificationType>) => (notifications.length ? notifications[0] : null),
);

const selectIsShortNotification = createDraftSafeSelector(
  selectNotifications,
  (notifications: Array<NotificationType>) => notifications.length > 1,
);

export { selectCurrentNotification, selectIsShortNotification };
