import { Stack, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';

type ProjectBuilderContainerProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
}>;

const ProjectBuilderContainer = ({ title, subtitle, children }: ProjectBuilderContainerProps) => {
  return (
    <Stack spacing={4}>
      {subtitle && <Typography>{subtitle}</Typography>}

      <Typography
        variant={'h3'}
        fontWeight={'bold'}
      >
        {title}
      </Typography>

      {children}
    </Stack>
  );
};

export default ProjectBuilderContainer;
