import '~components/dashboard/ContractTransactionsTable/ContractTransactionsTable.scss';

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import AnalyticsRefreshButton from '~components/buttons/AnalyticsRefreshButton';
import NetworkToggle from '~components/miscs/NetworkToggle/NetworkToggle';
import PageLoader from '~components/miscs/PageLoader';
import { GOERLI_NETWORK_ID, MAINNET_NETWORK_ID } from '~constants/networks/networks';
import { selectTransferData } from '~features/analytics/analytics.selectors';
import { getTransferData } from '~features/analytics/analytics.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { TransferDataType } from '~types/AnalyticsType';
import etherscanLinkBuilder from '~utils/url-builder/etherscanLinkBuilder/etherscanLinkBuilder';

const ContractTransactionsTable = () => {
  const projectConfig = useAppSelector(selectProjectConfig);
  const transactionsArray = useAppSelector(selectTransferData);
  const dispatch = useAppDispatch();

  const [chainId, setChainId] = useState<number | null>(GOERLI_NETWORK_ID);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tableHeaders = [
    { text: 'Transaction Hash', value: 'transaction.hash' },
    { text: 'Type', value: 'transfer.type' },
    { text: 'TokenId', value: 'tokenId' },
    { text: 'From', value: 'from' },
    { text: 'To', value: 'to' },
    { text: 'Value', value: 'value' },
    { text: 'Date', value: 'date' },
  ];

  useEffect(() => {
    if (projectConfig && projectConfig._id) {
      dispatch(
        getTransferData({
          projectId: projectConfig._id,
          contractAddress:
            chainId === 1
              ? projectConfig.contract.livenet.contractAddress
              : projectConfig.contract.testnet.contractAddress,
          refresh: true,
        }),
      );
    }

    // eslint-disable-next-line
  }, [chainId]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleNetworkChange = () => {
    return chainId === 1 ? setChainId(GOERLI_NETWORK_ID) : setChainId(MAINNET_NETWORK_ID);
  };

  if (!projectConfig) {
    return <PageLoader />;
  }

  return (
    <Card>
      <div className={'CAKE__contract-transaction-table__card-header'}>
        <NetworkToggle
          showToggle={true}
          chainId={chainId}
          handleNetworkChange={handleNetworkChange}
        />
        <AnalyticsRefreshButton
          type="TRANSACTION_DATA"
          projectId={projectConfig._id}
          contractAddress={
            chainId === 1
              ? projectConfig.contract.livenet.contractAddress
              : projectConfig.contract.testnet.contractAddress
          }
        />
      </div>
      <CardContent className={'CAKE__contract-transaction-table__card-content'}>
        {transactionsArray.length ? (
          <div className={'CAKE__contract-transaction-table__card-content inner-content'}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((item) => (
                    <TableCell key={item.value + Math.random()}>
                      <span>{item.text}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionsArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((obj: TransferDataType) => (
                    <TableRow
                      hover
                      key={obj.transactionHash}
                    >
                      <TableCell align="left">
                        <a
                          className={'CAKE__contract-transaction-table__card-content--link'}
                          href={etherscanLinkBuilder({
                            chainId,
                            type: 'txn',
                            data: { transactionHash: obj.transactionHash },
                          })}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.transactionHash}
                        </a>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          className={classnames({
                            'CAKE__contract-transaction-table__card-content--type-pill': true,
                            'CAKE__contract-transaction-table__card-content--type-pill--isMint': obj['isMint'],
                          })}
                        >
                          {obj.isMint ? 'Mint' : 'Transfer'}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <a
                          className={'CAKE__contract-transaction-table__card-content--link'}
                          href={etherscanLinkBuilder({
                            chainId,
                            type: 'tokenId',
                            data: { tokenId: obj.tokenId, address: obj.contractAddress },
                          })}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.tokenId}
                        </a>
                      </TableCell>
                      <TableCell align="left">
                        <a
                          className={'CAKE__contract-transaction-table__card-content--link'}
                          href={etherscanLinkBuilder({ chainId, type: 'address', data: { address: obj.from } })}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.from}
                        </a>
                      </TableCell>
                      <TableCell align="left">
                        <a
                          className={'CAKE__contract-transaction-table__card-content--link'}
                          href={etherscanLinkBuilder({ chainId, type: 'address', data: { address: obj.to } })}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.to}
                        </a>
                      </TableCell>
                      <TableCell align="center">{Number(obj.value) / Number(10 ** 18)}</TableCell>
                      <TableCell align="left">{new Date(obj.date).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={'CAKE__contract-transaction-table__default-content'}>
            <Typography>There are no transactions for this contract.</Typography>
          </div>
        )}
      </CardContent>
      {transactionsArray.length ? (
        <CardActions className={'CAKE__contract-transaction-table__card-content actions'}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={transactionsArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardActions>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default ContractTransactionsTable;
