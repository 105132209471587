import '~components/inputs/AutocompleteMultiSelect/AutocompleteMultiSelect.scss';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

type AutocompleteMultiSelectProps = {
  title?: string;
  dense?: boolean;
  options: Array<string>;
  selected: any; // set to any to avoid type errors with MUI Select value property
  onUpdateSelectedInputs: (tags: any) => void;
};

const AutocompleteMultiSelect = ({
  title,
  dense,
  options,
  selected,
  onUpdateSelectedInputs,
}: AutocompleteMultiSelectProps) => {
  const handleOptionsConfig = (values: Array<string>) => {
    onUpdateSelectedInputs(values);
  };

  return (
    <Stack className={'CAKE__autocomplete-multi-select'}>
      {!dense && <span className={'CAKE__autocomplete-multi-select__title'}>{title}</span>}
      <Stack
        spacing={3}
        sx={{ width: 500 }}
      >
        <Autocomplete
          multiple
          id="tags-filled"
          options={options}
          value={selected || null}
          defaultValue={selected}
          freeSolo
          onChange={(event, newValue) => {
            handleOptionsConfig(newValue);
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </Stack>
  );
};

export default AutocompleteMultiSelect;
