import { Grid } from '@mui/material';

import TokenGatePolicyConfigForm from '~components/inputs/TokenGatePolicyConfigForm';
import { selectCollectionTraitTypes } from '~features/collection-items/collection-items.selectors';
import { selectTokenGatePolicy } from '~features/token-gate-policy/token-gate-policy.selectors';
import useAppSelector from '~hooks/useAppSelector';

type TokenGatePolicySettingsProps = {
  handlePolicyUpdate;
};

const TokenGatePolicySettings = ({ handlePolicyUpdate }: TokenGatePolicySettingsProps) => {
  const projectCollectionTraits = useAppSelector(selectCollectionTraitTypes);
  const utilityPolicy = useAppSelector(selectTokenGatePolicy);

  return (
    <Grid
      container
      columns={{ xs: 2, sm: 8, md: 8 }}
      sx={{ marginTop: '10px' }}
    >
      <Grid
        item
        xs={2}
        sm={8}
        md={8}
      >
        <div className={'Cake__content-item__token-gate-settings'}>
          <p className={'Cake__content-item__token-gate-settings-title'}>Set Token-Gated Access Requirements</p>
        </div>
        <TokenGatePolicyConfigForm
          tokenGatePolicy={utilityPolicy}
          traits={projectCollectionTraits}
          handlePolicyUpdate={handlePolicyUpdate}
        />
      </Grid>
    </Grid>
  );
};

export default TokenGatePolicySettings;
