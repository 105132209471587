import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { useWallet } from '~context/WalletContext';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectWeb3ConfigContractFunctionAbi } from '~features/web3/web3.selectors';
import { callClearWeb3TxnStatus, callWeb3Contract, sendWeb3Contract } from '~features/web3/web3.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { ContractABIType } from '~types/Web3Type';

type ContractExecutableProps = {
  abi: ContractABIType;
  executionType: string;
  contractAddress: string;
  chainId: number | null;
  contractAbi: string;
};

const ContractExecutable = ({ abi, executionType, contractAddress, chainId, contractAbi }: ContractExecutableProps) => {
  const dispatch = useAppDispatch();
  const functions = useAppSelector(selectWeb3ConfigContractFunctionAbi);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>('');
  const [status, setStatus] = useState('');
  const [inputData, setInputData] = useState([]);
  const { walletAddress, provider, walletChainId } = useWallet();

  useEffect(() => {
    const abiFunctionInputs: object[] = abi.inputs.map((input: any) => {
      return { name: input.name, value: '' };
    });
    setInputData(abiFunctionInputs);
  }, []);

  useEffect(() => {
    // retrieve and store the abi function's redux state value
    const result = functions[executionType].find((item) => item['name'] === abi.name);
    setData(result['data']);
    if (result['txnStatus']) {
      setStatus(result['txnStatus'].status);
    }
  }, [abi.name, executionType, functions]);

  useEffect(() => {
    if (status) {
      if (status === 'success') {
        dispatch(
          addNotification({
            message: 'Transaction was successful!',
            severity: 'success',
            duration: 5000,
          }),
        );
        setStatus('');
      } else if (status === 'fail' || status === 'error') {
        dispatch(
          addNotification({
            message: 'Transaction failed!',
            severity: 'error',
            duration: 5000,
          }),
        );
      }
    }
  }, [status]);

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        dispatch(callClearWeb3TxnStatus(abi.name));
      }, 5000);
    }
  }, [status]);

  const handleInputChange = (event) => {
    const newInputData = inputData;
    const inputToUpdate = newInputData.find((item) => item.name === event.target.getAttribute('data-function'));
    inputToUpdate.value = event.target.value;
    setInputData([...newInputData]);
  };

  const handleFunctionExecution = async () => {
    try {
      if (!walletAddress) {
        dispatch(
          addNotification({
            message: 'Connect Your Wallet!',
            severity: 'warning',
            duration: 5000,
          }),
        );
      } else if (walletChainId !== '0x' + chainId) {
        const walletNetworkMsg =
          walletChainId === '0x1'
            ? 'Mainnet'
            : walletChainId === '0x5'
            ? 'Goerli'
            : walletChainId === '0x11155111'
            ? 'Sepolia'
            : 'an unknown network';
        const contractNetworkMsg = chainId === 1 ? 'Mainnet' : 'Goerli';
        dispatch(
          addNotification({
            message: `You are connected to the wrong network. Your wallet is connected to ${walletNetworkMsg} but you are trying to interact with a contract on ${contractNetworkMsg}.`,
            severity: 'warning',
            duration: 5000,
          }),
        );
      } else if (walletAddress && walletChainId === '0x' + chainId) {
        const inputValues = inputData.reduce((prev, curr) => [...prev, curr.value], []);

        if (executionType.toLocaleLowerCase() === 'read') {
          dispatch(
            callWeb3Contract({
              provider,
              abi: JSON.parse(contractAbi),
              contractAddress,
              functionName: abi.name,
              inputs: inputValues,
            }),
          );
          setOpen(true);
        }
        if (executionType.toLocaleLowerCase() === 'write') {
          dispatch(
            sendWeb3Contract({ provider, walletAddress, contractAddress, abi, walletChainId, inputs: inputValues }),
          );
          setOpen(true);
        }
      } else {
        throw new Error('Error in conditional logic');
      }
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          message:
            'We are unable to process your request to your contract at the moment. Our team has been notifiied and will be looking into this issue.',
          severity: 'error',
          duration: 5000,
        }),
      );
    }
  };

  const viewTxnInBlockExplorer = () => {
    let baseURL;
    if (walletChainId === '0x5') {
      baseURL = 'https://goerli.etherscan.io/tx';
    } else if (walletChainId === '0x1') {
      baseURL = 'https://etherscan.io/tx/';
    }
    window.open(`${baseURL}/${data}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <Accordion style={{ margin: '10px 0 0 0' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ margin: '0', minHeight: '36px' }}
      >
        <Typography sx={{ margin: '0' }}>{abi.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack className={'CAKE__editable-text-field'}>
          <span className={'CAKE__editable-text-field__title'}>{}</span>
          {abi.inputs.map((input, index) => {
            return (
              <Stack
                key={index}
                style={{ margin: '4px 0 0 0' }}
                className={'CAKE__editable-text-field__input-container'}
              >
                <input
                  className={'CAKE__editable-text-field__input-container__input'}
                  value={inputData[index] ? inputData[index]['value'] : ''}
                  data-function={inputData[index] ? inputData[index].name : ''}
                  placeholder={`${input.name} <${input.type}>`}
                  onChange={handleInputChange}
                />
              </Stack>
            );
          })}
        </Stack>
        <Collapse in={open}>
          {executionType.toLowerCase() === 'read' && (
            <Typography style={{ margin: '10px 0 0 0', overflowWrap: 'anywhere' }}>{data}</Typography>
          )}
        </Collapse>
        <Stack
          direction="row"
          spacing={1}
          style={{ margin: '10px 0' }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={handleFunctionExecution}
          >
            {executionType}
          </Button>
          {executionType.toLowerCase() === 'write' && data && (
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={viewTxnInBlockExplorer}
            >
              See Transaction
            </Button>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ContractExecutable;
