import '~components/layout/DashboardPageLayout/DashboardPageLayout.scss';

import { Box, Stack } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';

type DashboardPageLayoutProps = {
  scrollHandler?: (values: any) => void;
};

const DashboardPageLayout = ({ children, scrollHandler }: React.PropsWithChildren<DashboardPageLayoutProps>) => {
  return (
    <Stack className={'CAKE__dashboard-page-layout'}>
      <Scrollbars
        onScrollFrame={scrollHandler}
        className={'CAKE__dashboard-page-layout__scroll'}
        autoHide
      >
        <Box
          className={'CAKE__dashboard-page-layout__container'}
          paddingX={{ sm: 2, md: 6, lg: 8 }}
        >
          <Box className={'CAKE__dashboard-page-layout__inner-container'}>{children}</Box>
        </Box>
      </Scrollbars>
    </Stack>
  );
};

export default DashboardPageLayout;
