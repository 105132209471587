import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Stack } from '@mui/system';

import QRCodeImage from '~components/miscs/QRCodeViewer/components/QRCodeImage';

type QRCodeModalProps = {
  url: string;
  isOpen: boolean;
  onClose: () => void;
};

const QRCodeModal = ({ url, isOpen, onClose }: QRCodeModalProps) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogContent>
        <Stack
          width={'100%'}
          justifyContent="center"
          alignItems={'center'}
        >
          <QRCodeImage url={url} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeModal;
