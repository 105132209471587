import '~components/dashboard/GallerySettingsCard/components/GalleryImage/GalleryImage.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import cx from 'classnames';
import { useState } from 'react';

import isVideo from '~utils/regex/isVideoURL';

type GalleryImageProps = {
  url: string;
  onDelete: () => void;
};

const GalleryImage = ({ url, onDelete }: GalleryImageProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <div
      className={'CAKE__gallery-image'}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        className={cx({
          'CAKE__gallery-image__delete': true,
          'CAKE__gallery-image__delete--mouseover': isMouseOver,
        })}
        onClick={() => onDelete()}
      >
        <DeleteIcon color={'primary'} />
      </div>
      {isVideo(url) ? (
        <video
          className={cx({
            'CAKE__gallery-image__image': true,
            'CAKE__gallery-image__image--mouseover': isMouseOver,
          })}
          autoPlay
          loop
          muted={true}
          src={url}
        />
      ) : (
        <img
          className={cx({
            'CAKE__gallery-image__image': true,
            'CAKE__gallery-image__image--mouseover': isMouseOver,
          })}
          src={url}
          alt="img"
          loading="lazy"
        />
      )}
    </div>
  );
};

export default GalleryImage;
