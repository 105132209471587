import '~components/layout/PublicPageLayout/PublicPageLayout.scss';

import React from 'react';

import Header from '~components/layout/headers/Header';

type PublicPageLayoutProps = {
  children: React.ReactNode;
};
const PublicPageLayout = ({ children }: PublicPageLayoutProps) => {
  return (
    <div className={'CAKE__public-page-layout'}>
      <Header />
      {children}
    </div>
  );
};

export default PublicPageLayout;
