import { Table, TableBody, TableContainer } from '@mui/material';

import DataRoomTableHead from '~components/dashboard/DataRoomObjectsTable/components/DataRoomTableHead';
import ObjectItem from '~components/dashboard/DataRoomObjectsTable/components/ObjectItem';
import type DataRoomObjectType from '~types/DataRoomTypes';

type DataRoomObjectsTableProps = {
  objects: Array<DataRoomObjectType>;
  onSelectItem: (object: DataRoomObjectType) => void;
};

const DataRoomObjectsTable = ({ objects, onSelectItem }: DataRoomObjectsTableProps) => {
  console.log(objects);
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="content"
        size={'medium'}
      >
        <DataRoomTableHead />
        <TableBody>
          {objects.map((item: DataRoomObjectType) => (
            <ObjectItem
              key={`content-item-${item._id}`}
              object={item}
              onClick={onSelectItem}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataRoomObjectsTable;
