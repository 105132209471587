import type { Breadcrumb } from '@sentry/react';
import * as Sentry from '@sentry/react';
import type { Transaction } from '@sentry/tracing';
import { BrowserTracing } from '@sentry/tracing';

import type MonitoringEventType from '~types/MonitoringEventType';
import { reduxActionTransformer, reduxStateTranformer } from '~utils/monitoring/monitoring.helpers';

const init = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN, //pass as env vars
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', process.env.REACT_APP_BATTER_API_URL, /^\//],
        traceXHR: true,
      }),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
  });
};

const getMonitoringReduxEnhancer = () => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: reduxActionTransformer,
    stateTransformer: reduxStateTranformer,
  });
  return sentryReduxEnhancer;
};

const setUser = (user) => {
  Sentry.setUser({ name: user.name, email: user.email, sub: user.sub });
};

const setSession = (sessionId) => {
  setTag('sid', sessionId);
};

const setTag = (tagName: string, tagValue: any) => {
  Sentry.setTag(tagName, tagValue);
};

const setTags = (tags: { [name: string]: any }) => {
  Object.entries(tags).forEach(([name, value]) => setTag(name, value));
};

const addEvent = (monitoringEvent: MonitoringEventType) => {
  Sentry.addBreadcrumb(monitoringEvent as Breadcrumb);
};

const startTransaction = (options): Transaction => {
  const hubTransaction = Sentry.getCurrentHub().getScope().getTransaction();
  if (hubTransaction) {
    hubTransaction.finish();
  }
  const transaction: Transaction = Sentry.startTransaction(options) as Transaction;
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
  return transaction;
};

const finishTransaction = (transaction: Transaction) => {
  transaction.finish();
};

function* executeGeneratorWithTransaction(generator, transactionOptions, action) {
  const transaction: Transaction = startTransaction(transactionOptions);
  try {
    yield generator(action);
  } catch (e) {
    throw new Error(`executeGeneratorWithTransaction, name: ${transactionOptions.name}`);
  }

  finishTransaction(transaction);
}

const Monitoring = {
  addEvent,
  executeGeneratorWithTransaction,
  finishTransaction,
  getMonitoringReduxEnhancer,
  init,
  setSession,
  setTag,
  setTags,
  setUser,
  startTransaction,
};
export default Monitoring;
