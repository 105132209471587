import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put } from 'redux-saga/effects';

import {
  getTokenGatePolicyConfig,
  setTokenGatePolicyConfig,
  updateTokenGatePolicyConfig,
} from '~features/token-gate-policy/token-gate-policy.slice';
import { fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { takeLatestWithMonitoring } from '~features/utils/saga-utils';
import type { TokenGatePolicy } from '~types/TokenGateTypes';

function* fetchTokenGatePolicySaga(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;

  try {
    const res: Response = yield call(fetchProtectedAPI, `token-gate-policy/${id}`);
    const data = yield res.json();
    if (data) {
      yield put(setTokenGatePolicyConfig(data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateTokenGatePolicyConfiSaga(action: PayloadAction<TokenGatePolicy>) {
  try {
    const res: Response = yield call(putProtectedAPI, `token-gate-policy/${action.payload._id}`, action.payload);
    if (res.status === 200) {
      yield put(setTokenGatePolicyConfig(action.payload));
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* tokenGatePolicyConfigSagas() {
  yield all([
    yield takeLatestWithMonitoring(getTokenGatePolicyConfig.type, fetchTokenGatePolicySaga, 'blog.item.create'),
    yield takeLatestWithMonitoring(
      updateTokenGatePolicyConfig.type,
      updateTokenGatePolicyConfiSaga,
      'blog.item.create',
    ),
  ]);
}
