/* eslint-disable */

import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

import WalletType from '~constants/wallet/WalletType';
import {
  addWalletEventListener,
  connect,
  getCurrentChainId,
  isEthereum,
  removeWalletEventListener,
  resolveSelectedProvider,
  WalletMessage,
} from '~context/WalletContext/Wallet.helpers';
import type WalletContextType from '~context/WalletContext/WalletContextType';
import isMobile from '~utils/isMobile';

const WalletContext = createContext<WalletContextType>({
  connectWallet: () => {},
  selectProvider: () => {},
  provider: {},
  walletAddress: '',
  walletChainId: '',
});

WalletContext.displayName = 'WalletContext';

type WalletProviderProps = {
  children: JSX.Element;
};

const WalletProvider = ({ children }: WalletProviderProps) => {
  const [provider, setProvider] = useState<any>();
  const [address, setAddress] = useState<string>('');
  const [chainId, setChainId] = useState<string>('');

  const onAccountChanged = (accounts: Array<string>) => {
    console.log('Accounts have changed!');
    setProvider('');
    if (accounts?.length > 0) {
      setAddress(accounts[0]);
    } else {
      setAddress('');
    }
  };

  const onChainChanged = (chainId: string) => {
    // console.log({ chainId })
    window.location.reload();
  };

  const selectProvider = async (selectedProvider: any) => {
    console.log({ selectedProvider, const: WalletType.COINBASE, res: selectedProvider === WalletType.COINBASE });
    // handle mobile provider deeplink redirect
    if (!isEthereum() && isMobile()) {
      if (selectedProvider === WalletType.COINBASE) {
        window.open(`https://go.cb-w.com/dapp?cb_url=${window.location.href}`, '_blank');
        return;
      } else {
        window.open(`https://metamask.app.link/dapp/${window.location.href}`, '_blank');
        return;
      }
    }

    // handle desktop no-provider extension redirect
    if (!isEthereum() && !isMobile()) {
      if (selectedProvider === WalletType.COINBASE) {
        window.open('https://www.coinbase.com/wallet', '_blank');
        return;
      } else {
        window.open('https://metamask.io/download/', '_blank');
        return;
      }
    }

    // handle desktop provider assignment
    console.log(`Selected Provider: ${selectedProvider}`);
    const resolvedProvider = resolveSelectedProvider(selectedProvider);
    setProvider(resolvedProvider);
  };

  const connectWallet = async () => {
    /*
      Connected to the correct injected provider specified by the
      user selection in the provider modal
    */

    // connect to injected provider for desktop
    if (provider) {
      const { res, address } = await connect(provider);
      switch (res) {
        case WalletMessage.CONNECTION_SUCCESS:
          console.log('Setting Address');
          setAddress(address);
          break;
        case WalletMessage.CONNECTION_FAILED:
          console.log('failed');
          // ignore for now
          break;
      }

      const currentChainId = await getCurrentChainId(provider);
      setChainId(currentChainId);
      console.log('Adding Metamask event listener');
      addWalletEventListener(provider, 'accountsChanged', onAccountChanged);
      addWalletEventListener(provider, 'chainChanged', onChainChanged);
    } else {
      console.log('No Provider');
      setChainId('no provider');
    }
  };

  useEffect(() => {
    connectWallet();
    return () => {
      console.log('Adding Metamask event listener');
      removeWalletEventListener(provider, 'accountsChanged', onAccountChanged);
      removeWalletEventListener(provider, 'chainChanged', onChainChanged);
    };
  }, [provider]);

  const values: WalletContextType = {
    connectWallet,
    selectProvider,
    provider,
    walletAddress: address,
    walletChainId: chainId,
  };

  return <WalletContext.Provider value={values}>{children}</WalletContext.Provider>;
};

WalletProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useWallet = (): WalletContextType => {
  const context = useContext(WalletContext);

  if (context === undefined) {
    throw new Error('useWalletContext must be used within a MetamaskProvider');
  }
  return context;
};

export { useWallet, WalletProvider };
