import '~components/dashboard/WalletDistributionChart/WalletDistributionChart.scss';

import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import AnalyticsRefreshButton from '~components/buttons/AnalyticsRefreshButton';
import DashboardCard from '~components/dashboard/DashboardCard';
import renderActiveShape from '~components/dashboard/WalletDistributionChart/components/RenderActiveShape';
import TopWalletHoldersTable from '~components/dashboard/WalletDistributionChart/components/TopWalletHoldersTable';
import NetworkToggle from '~components/miscs/NetworkToggle/NetworkToggle';
import PageLoader from '~components/miscs/PageLoader';
import { GOERLI_NETWORK_ID, MAINNET_NETWORK_ID } from '~constants/networks/networks';
import { selectFormattedWalletDistribution } from '~features/analytics/analytics.selectors';
import { getWalletDistribution } from '~features/analytics/analytics.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const WalletDistributionChart = () => {
  const dispatch = useAppDispatch();
  const [chainId, setChainId] = useState<number | null>(GOERLI_NETWORK_ID);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const data = useAppSelector(selectFormattedWalletDistribution);
  const projectConfig = useAppSelector(selectProjectConfig);

  useEffect(() => {
    if (projectConfig && projectConfig._id) {
      dispatch(
        getWalletDistribution({
          projectId: projectConfig._id,
          contractAddress:
            chainId === 1
              ? projectConfig.contract.livenet.contractAddress
              : projectConfig.contract.testnet.contractAddress,
          refresh: true,
        }),
      );
    }

    // eslint-disable-next-line
  }, [chainId]);

  const handleNetworkChange = () => {
    return chainId === 1 ? setChainId(GOERLI_NETWORK_ID) : setChainId(MAINNET_NETWORK_ID);
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  if (!projectConfig) {
    return <PageLoader />;
  }

  return (
    <DashboardCard
      title={'Wallet Distribution'}
      rightContainer={
        <div className={'CAKE__wallet-distribution-chart__dashboard-card'}>
          <NetworkToggle
            showToggle={true}
            chainId={chainId}
            handleNetworkChange={handleNetworkChange}
          />
          <AnalyticsRefreshButton
            type="WALLET_DISTRIBUTION"
            projectId={projectConfig._id}
            contractAddress={
              chainId === 1
                ? projectConfig.contract.livenet.contractAddress
                : projectConfig.contract.testnet.contractAddress
            }
          />
        </div>
      }
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{
          justifyContent: data && data.length ? 'space-evenly' : 'center',
          alignContent: data && data.length ? 'center' : 'center',
          minHeight: data && data.length ? '100%' : '150px',
        }}
      >
        {data && data.length ? (
          <>
            <TopWalletHoldersTable chainId={chainId} />
            <PieChart
              width={500}
              height={400}
            >
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                innerRadius={100}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][
                        index % ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'].length
                      ]
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          </>
        ) : (
          <Typography>There is no wallet data for this contract.</Typography>
        )}
      </Grid>
    </DashboardCard>
  );
};

export default WalletDistributionChart;
