import '~components/dashboard/Feed/Feed.scss';

import { Card } from '@mui/material';

import ActivityContent from '~components/dashboard/Feed/components/ActivityContent/ActivityContent';
import ActivityFooter from '~components/dashboard/Feed/components/ActivityFooter';
import ActivityHeader from '~components/dashboard/Feed/components/ActivityHeader';
import type { FeedAggregateActivityType } from '~types/Streams';

type FeedItemProps = {
  data: FeedAggregateActivityType;
};

const FeedItem = ({ data }: FeedItemProps) => {
  return (
    <Card
      className={'CAKE__feed__activityCard'}
      sx={{ borderRadius: '0px' }}
    >
      <ActivityHeader
        profileImage={data ? data.activities[0].actor.data.profileImage : null}
        updated={data.updated_at}
        userName={data ? data.activities[0].actor.data.name : null}
      />
      <ActivityContent data={data} />
      {!['mint', 'transfer'].includes(data.verb) && ( // do not show reactions for aggregated activities
        <ActivityFooter data={data} />
      )}
    </Card>
  );
};

export default FeedItem;
