import QrCodeIcon from '@mui/icons-material/QrCode';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import QRCodeModal from '~components/miscs/QRCodeViewer/components/QRCodeModal';

type QRCodeViewerProps = {
  url?: string;
};

const QRCodeViewer = ({ url }: QRCodeViewerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <IconButton
        color="primary"
        onClick={toggleModal}
      >
        <QrCodeIcon />
      </IconButton>
      <QRCodeModal
        url={url}
        isOpen={isOpen}
        onClose={toggleModal}
      />
    </div>
  );
};

export default QRCodeViewer;
