import '~components/inputs/ColorPicker/ColorPicker.scss';

import { Box, Popover, Typography } from '@mui/material';
import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { BlockPicker } from 'react-color';

type ColorPickerProps = {
  color: string;
  onUpdate?: (currentColor: string) => void;
  title?: string;
};
const ColorPicker = ({ color, onUpdate, title }: ColorPickerProps) => {
  const [currentColor, setCurrentColor] = useState<string>(color || '#FFFFFF');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setCurrentColor(color || '#FFFFFF');
  }, [color]);

  const onChange = (color) => {
    setCurrentColor(color.hex);
  };

  const onOpen = (e: MouseEvent) => {
    setAnchorEl(isOpen ? null : e.currentTarget);
    setIsOpen(!isOpen);
  };

  const onClosePicker = () => {
    setAnchorEl(null);
    setIsOpen(false);
    if (onUpdate && color !== currentColor) {
      onUpdate(currentColor);
    }
  };

  return (
    <>
      <Box
        className={'CAKE__color-picker__swatch'}
        sx={{
          backgroundColor: currentColor,
          color: parseInt(currentColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff',
        }}
        onClick={onOpen}
      >
        {title && <Typography variant="body2">{title}</Typography>}
        <Typography variant="body2">{currentColor.toUpperCase()}</Typography>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClosePicker}
        sx={{ top: 8 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <BlockPicker
          color={currentColor}
          onChangeComplete={onChange}
        />
      </Popover>
    </>
  );
};

export default ColorPicker;
