import getS3BaseURL from '~utils/api/getS3BaseURL';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

const getS3TokenFilePath = (project: string, subpath = '') => {
  const baseURL: string = getS3BaseURL();

  const { dir } = getS3RootProjectPath(project);
  const path = `${baseURL}${dir}${subpath}`;

  return { dir: `${dir}${subpath}`, path };
};

export default getS3TokenFilePath;
