import { Stack } from '@mui/material';
import { useState } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import { FileUploadKey } from '~components/inputs/FileUploadInput';
import ImageDropzone from '~components/inputs/ImageDropzone';
import { selectProjectDappConfig, selectUploadStatus } from '~features/project-config/project-config.selectors';
import { uploadImage } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { UploadStatus } from '~types/miscs/UploadStatus';

const BrandingSettingsCard = () => {
  const dispatch = useAppDispatch();
  const [isLightMode] = useState<boolean>(true);
  const { backgroundImage, logo } = useAppSelector(selectProjectDappConfig);
  const uploadStatuses = useAppSelector(selectUploadStatus);

  const onUploadFile = (files: Array<File>, key: FileUploadKey) => {
    dispatch(uploadImage({ file: files[0], key }));
  };

  return (
    <DashboardCard
      title={'Branding'}
      subtitle={'Please select your brand logos.'}
    >
      <Stack
        flexDirection={'row'}
        gap={'20px'}
      >
        <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_BACKGROUND_LOGO : FileUploadKey.DARK_BACKGROUND_LOGO)
          }
          title={'Background logo'}
          image={backgroundImage[isLightMode ? 'light' : 'dark']}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_BACKGROUND_LOGO : FileUploadKey.DARK_BACKGROUND_LOGO] ===
            UploadStatus.LOADING
          }
        />
        <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_PRIMARY_LOGO : FileUploadKey.DARK_PRIMARY_LOGO)
          }
          title={'Primary logo'}
          image={logo[isLightMode ? 'light' : 'dark'].primary}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_PRIMARY_LOGO : FileUploadKey.DARK_PRIMARY_LOGO] ===
            UploadStatus.LOADING
          }
        />
        <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_SECONDARY_LOGO : FileUploadKey.DARK_SECONDARY_LOGO)
          }
          title={'Secondary logo'}
          image={logo[isLightMode ? 'light' : 'dark'].secondary}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_SECONDARY_LOGO : FileUploadKey.DARK_SECONDARY_LOGO] ===
            UploadStatus.LOADING
          }
        />
        <ImageDropzone
          onDropFile={(files: Array<File>) =>
            onUploadFile(files, isLightMode ? FileUploadKey.LIGHT_TITLE_LOGO : FileUploadKey.DARK_TITLE_LOGO)
          }
          title={'Title logo'}
          image={logo[isLightMode ? 'light' : 'dark'].title}
          isUploading={
            uploadStatuses[isLightMode ? FileUploadKey.LIGHT_TITLE_LOGO : FileUploadKey.DARK_TITLE_LOGO] ===
            UploadStatus.LOADING
          }
        />
      </Stack>
    </DashboardCard>
  );
};

export default BrandingSettingsCard;
