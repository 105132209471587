// eslint-disable-next-line absolutely-import/import
import './ProjectDrawerHeader.scss';

import { Box } from '@mui/material';

import PrivateRoutes from '~constants/routes/private-routes.consts';
import useSmartNavigate from '~hooks/useSmartNavigate';
import { CakeLogo } from '~icons/common';

const ProjectDrawerHeader = () => {
  const { navigate } = useSmartNavigate();
  return (
    <Box className={'CAKE__project-drawer-header'}>
      <Box
        className={'CAKE__project-drawer-header__home'}
        onClick={() => navigate(PrivateRoutes.DASHBOARD_PROJECTS_LIST)}
      >
        <CakeLogo />
      </Box>
    </Box>
  );
};

export default ProjectDrawerHeader;
