import '~components/dashboard/Feed/Feed.scss';

import { Button } from '@mui/material';

type UpdateNotificationProps = {
  executeScroll: () => void;
};

const UpdateNotification = ({ executeScroll }: UpdateNotificationProps) => {
  return (
    <div className={'CAKE__feed__notification-container'}>
      <div className={'CAKE__feed__notification'}>
        <Button
          className={'CAKE__feed__notification-button'}
          onClick={executeScroll}
          variant={'contained'}
        >
          New Activity Event Added
        </Button>
      </div>
    </div>
  );
};

export default UpdateNotification;
