import { Button, CircularProgress, Stack } from '@mui/material';

type DeployButtonProps = {
  contract: any;
  network: string;
  isDeploying: boolean;
  onDeployContract: (network: string) => void;
};

const DeployButton = ({ contract, onDeployContract, network, isDeploying }: DeployButtonProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      style={{ margin: '10px 0' }}
    >
      {!contract.livenet.contractAddress && isDeploying && network === 'goerli' && (
        <Button
          disabled
          size="small"
          variant="contained"
          endIcon={<CircularProgress size={'1rem'} />}
        >
          Deploying to TestNet
        </Button>
      )}
      {!contract.livenet.contractAddress && !isDeploying && (
        <Button
          size="small"
          variant="contained"
          onClick={() => onDeployContract('goerli')}
        >
          Deploy to TestNet
        </Button>
      )}
      {contract.testnet.contractAddress && !contract.livenet.contractAddress && !isDeploying && (
        <Button
          size="small"
          variant="outlined"
          onClick={() => onDeployContract('mainnet')}
        >
          Deploy to Mainnet
        </Button>
      )}
      {contract.testnet.contractAddress &&
        !contract.livenet.contractAddress &&
        isDeploying &&
        network === 'mainnet' && (
          <Button
            disabled
            size="small"
            variant="outlined"
            endIcon={<CircularProgress size={'1rem'} />}
          >
            Deploying to Mainnet
          </Button>
        )}
    </Stack>
  );
};

export default DeployButton;
