import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  selectCollectionIsNextPage,
  selectCollectionPageLoaded,
  selectCollectionTokensMap,
  selectCollectionTokensSelection,
} from '~features/collection-items/collection-items.selectors';
import { selectCollectionTraitsAggregated } from '~features/collection-items/collection-items.selectors';
import {
  addCollectionItem,
  deleteCollectionItem,
  deleteCollectionItemError,
  deleteCollectionItemSuccess,
  deleteSelectedCollectionItems,
  deleteSelectedCollectionItemsError,
  deleteSelectedCollectionItemsSuccess,
  getCollection,
  getCollectionNextPage,
  getCollectionSuccess,
  getCollectionTraits,
  setCollectionTraits,
  unselectCollectionItem,
  updateCollectionItem,
  updateCollectionItemError,
  updateCollectionItemSuccess,
  updateCollectionTraits,
} from '~features/collection-items/collection-items.slice';
import { updateItemImageSuccess, uploadItemFinished } from '~features/collection-upload/collection-upload.slice';
import { selectProjectCollectionId, selectProjectId } from '~features/project-config/project-config.selectors';
import { deleteProtectedAPI, fetchProtectedAPI, putProtectedAPI } from '~features/utils/api/api.sagas';
import { deleteFileFromS3 } from '~features/utils/s3storage/s3storage.sagas';
import type CollectionItemType from '~types/CollectionItemType';
import type AggregateTraitType from '~types/token/AggregateTraitType';
import type UploadTokenValidationType from '~types/token/UploadTokenValidationType';

// eslint-disable-next-line absolutely-import/import
import { COLLECTION_PAGE_SIZE } from './collection-items.consts';

function* getCollectionSaga(action: PayloadAction<{ page: string }>) {
  const collectionId = yield select(selectProjectCollectionId);
  const page = action.payload?.page || 0;
  if (!collectionId) {
    return;
  }
  const params = {
    'collection-id': [collectionId],
    page: [page],
    limit: [COLLECTION_PAGE_SIZE],
  };
  const res: Response = yield call(fetchProtectedAPI, 'tokens', params);
  const data = yield res.json();

  yield put(getCollectionSuccess(data));
  yield put(getCollectionTraits());
}

function* getCollectionTraitsSaga() {
  const collectionId = yield select(selectProjectCollectionId);

  try {
    if (collectionId) {
      const res: Response = yield call(fetchProtectedAPI, `collection/${collectionId}/traits`);
      const data = yield res.json();
      yield put(
        setCollectionTraits(
          data.map((item: any) => ({ trait_type: item._id.trait, value: item._id.value, count: item.count })),
        ),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateCollectionTraitsSaga(action: PayloadAction<CollectionItemType>) {
  const currentAggregatedCollectionTraits = yield select(selectCollectionTraitsAggregated);
  const token = action.payload;
  let newTokenTraits = [];

  if (token.attributes) {
    newTokenTraits = token.attributes.map((attribute: any) => {
      const aggreggateTrait = currentAggregatedCollectionTraits.filter(
        (item: AggregateTraitType) => item.trait_type === attribute.trait_type && item.value === attribute.value,
      );
      return { ...aggreggateTrait, count: aggreggateTrait.count + 1 };
    });
  }

  setCollectionTraits([...currentAggregatedCollectionTraits, ...newTokenTraits]);
}

function* getCollectionNextPageSaga() {
  const page = yield select(selectCollectionPageLoaded);
  const isNext = yield select(selectCollectionIsNextPage);
  if (isNext) {
    yield put(getCollection({ page: page }));
  }
}

function* deleteCollectionItemSaga(action: PayloadAction<CollectionItemType>) {
  const token = action.payload;

  if (!token._id) {
    return;
  }

  const projectId = yield select(selectProjectId);
  const res: Response = yield call(deleteProtectedAPI, `project/${projectId}/tokens/${token._id}`);
  const data = yield res.json();

  if (data?._id === token._id && token.image) {
    yield call(deleteFileFromS3, token.image);
  }

  if (data?._id !== token._id) {
    yield put(unselectCollectionItem(token));
    yield put(deleteCollectionItemError());
  } else {
    yield put(deleteCollectionItemSuccess(token));
  }
  return data;
}

function* deleteSelectedCollectionItemsSaga() {
  const selection = yield select(selectCollectionTokensSelection);

  const ids: string = Object.keys(selection).reduce((acc, curr) => (acc.length ? `${acc},${curr}` : curr));

  if (!ids || ids.length === 0) {
    return;
  }

  const projectId = yield select(selectProjectId);
  const res: Response = yield call(deleteProtectedAPI, `project/${projectId}/tokens/${ids}`);

  if (res.status === 200) {
    yield put(deleteSelectedCollectionItemsSuccess(selection));
  } else {
    yield put(deleteSelectedCollectionItemsError());
  }
}

function* updateCollectionItemSaga(action: PayloadAction<CollectionItemType>) {
  console.log(action.payload);
  const token: CollectionItemType = action.payload;
  delete token.refresh;

  if (!token._id) {
    yield put(updateCollectionItemError());
    return;
  }

  const projectId = yield select(selectProjectId);
  const res: Response = yield call(putProtectedAPI, `project/${projectId}/tokens/${token._id}`, token);
  const data = yield res.json();

  if (!data) {
    yield put(updateCollectionItemError());
  } else {
    yield put(updateCollectionItemSuccess(token));
    yield put(updateCollectionTraits(token));
  }
  return data;
}

function* handleUploadItemFinished(action: PayloadAction<UploadTokenValidationType>) {
  const { name, metadata, isMetadataValid } = action.payload;
  const tokens = yield select(selectCollectionTokensMap);

  const existingToken = tokens[name];
  const newToken = {
    name,
    isMetadataValid,
    ...metadata,
  };

  if (existingToken) {
    yield put(updateCollectionItem(newToken));
  } else {
    yield put(addCollectionItem(newToken));
  }
}

function* handleItemImageUpdateSaga(action: PayloadAction<CollectionItemType>) {
  const token = action.payload;
  yield put(updateCollectionItemSuccess(token));
}

export default function* campaignSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getCollection.type, getCollectionSaga),
    yield takeLatest(getCollectionNextPage.type, getCollectionNextPageSaga),
    yield takeLatest(updateCollectionItem.type, updateCollectionItemSaga),
    yield takeEvery(deleteCollectionItem.type, deleteCollectionItemSaga),
    yield takeLatest(deleteSelectedCollectionItems.type, deleteSelectedCollectionItemsSaga),
    yield takeEvery(uploadItemFinished.type, handleUploadItemFinished),
    yield takeLatest(updateItemImageSuccess.type, handleItemImageUpdateSaga),
    yield takeLatest(getCollectionTraits.type, getCollectionTraitsSaga),
    yield takeLatest(updateCollectionTraits.type, updateCollectionTraitsSaga),
  ]);
}
