import { Button, SnackbarContent } from '@mui/material';
import { Stack } from '@mui/system';

type UndoDeleteBannerProps = {
  isOpen: boolean;
  handleUndoDelete: () => void;
};

const UndoDeleteBanner = ({ isOpen, handleUndoDelete }: UndoDeleteBannerProps) => {
  if (isOpen) {
    return (
      <Stack
        spacing={2}
        className={'CAKE__undo-delete-banner__container'}
      >
        <SnackbarContent
          message="This item as been temporarily deleted. You may still restore it to draft mode"
          action={
            <Button
              variant="contained"
              onClick={handleUndoDelete}
            >
              Restore
            </Button>
          }
        />
      </Stack>
    );
  } else {
    return <></>;
  }
};

export default UndoDeleteBanner;
