import '~views/projects/ProjectBuilderView/ProjectBuilderView.scss';

import { Box, Button, Grid, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import V2TextInput from '~components/inputs/V2TextInput';
import WalletAddressFieldInput from '~components/inputs/WalletAddressFieldInput';
import PageLoader from '~components/miscs/PageLoader';
import Stepper from '~components/miscs/Stepper';
import ProjectBasicInfoForm from '~components/projects/ProjectBasicInfoForm';
import ProjectBuilderContainer from '~components/projects/ProjectBuilderContainer';
import ProjectBuilderImageUpload from '~components/projects/ProjectBuilderImageUpload';
import ProjectLaunchDatesForm from '~components/projects/ProjectLaunchDatesForm';
import ProjectSplitPayment from '~components/projects/ProjectSplitPayment';
import {
  selectProjectBuilderIsLoading,
  selectProjectBuilderProjectConfig,
  selectProjectBuilderStep,
} from '~features/project-builder/project-builder.selectors';
import { setProjectConfig, setStep } from '~features/project-builder/project-builder.slice';
import { nextStep, prevStep, resetProjectConfig } from '~features/project-builder/project-builder.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { ProjectType } from '~types/ProjectType';
import { ProjectBuilderSteps } from '~views/projects/ProjectBuilderView/ProjectBuilderView.constants';
import {
  canMoveToNextStep,
  getStepCount,
  projectConfigCompleted,
} from '~views/projects/ProjectBuilderView/ProjectBuilderView.helpers';

const ProjectBuilderView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentStep: number = useAppSelector(selectProjectBuilderStep);
  const isLoading: boolean = useAppSelector(selectProjectBuilderIsLoading);
  const projectConfig: ProjectType = useAppSelector(selectProjectBuilderProjectConfig);
  const isLastStep = currentStep >= getStepCount() - 1;

  useEffect(() => {
    dispatch(resetProjectConfig());
  }, []);

  useEffect(() => {
    if (projectConfig._id) {
      navigate(`/dashboard/projects/${projectConfig._id}`);
    }
  }, [projectConfig]);

  const onProjectConfigUpdated = (config: ProjectType) => {
    dispatch(setProjectConfig(config));
  };

  const onContractUpdate = (config: Partial<Record<keyof ProjectType['contract'], any>>) => {
    onProjectConfigUpdated({
      ...projectConfig,
      contract: {
        ...projectConfig.contract,
        ...config,
      },
    });
  };

  if (isLoading) {
    return <PageLoader />;
  }

  const getCurrentStep = () => {
    switch (currentStep) {
      case ProjectBuilderSteps.BASIC_INFO:
        return (
          <ProjectBuilderContainer
            subtitle="Let's start building your project!"
            title="What is the title of the project?"
          >
            <ProjectBasicInfoForm
              config={projectConfig}
              onConfigUpdated={onProjectConfigUpdated}
            />
          </ProjectBuilderContainer>
        );

      case ProjectBuilderSteps.SPLIT_PAYMENT:
        return (
          <ProjectSplitPayment
            config={projectConfig}
            onConfigUpdated={onProjectConfigUpdated}
          />
        );
      case ProjectBuilderSteps.SUPER_ADMIN_ADDRESS:
        return (
          <ProjectBuilderContainer title="What wallet address will serve as the primary admin of this project?">
            <WalletAddressFieldInput
              required
              errorText={'Wallet address not valid'}
              label={'Superadmin Wallet Address'}
              value={projectConfig.contract.superAdmin}
              variant="standard"
              onUpdate={(walletAddress) => {
                const { royalty } = projectConfig.contract;
                const updateRoyalty =
                  !royalty.length || (royalty.length === 1 && royalty[0].address === projectConfig.contract.superAdmin);

                if (updateRoyalty) {
                  onContractUpdate({ superAdmin: walletAddress, royalty: [{ address: walletAddress, amount: 100 }] });
                } else {
                  onContractUpdate({ superAdmin: walletAddress });
                }
              }}
            />
          </ProjectBuilderContainer>
        );
      case ProjectBuilderSteps.LAUNCH_DATES:
        return (
          <ProjectBuilderContainer title="Do you have a date set for launching your project?">
            <ProjectLaunchDatesForm
              config={projectConfig}
              onConfigUpdated={onProjectConfigUpdated}
            />
          </ProjectBuilderContainer>
        );
      case ProjectBuilderSteps.MAX_MINT_TOTAL:
        return (
          <ProjectBuilderContainer title="Do you want to set a limit on the number of tokens a single wallet can purchase when your project launches?">
            <V2TextInput
              label={'Max mint total'}
              variant="standard"
              value={String(projectConfig.contract.maxMintTotal || 0)}
              type="number"
              onUpdate={(value) => {
                if (isNaN(Number.parseFloat(value))) {
                  return;
                }

                onContractUpdate({ maxMintTotal: Number(value) });
              }}
            />
          </ProjectBuilderContainer>
        );
      case ProjectBuilderSteps.MAX_MINT_TXN:
        return (
          <ProjectBuilderContainer title="Do you want to set a limit on the number of tokens a single wallet can purchase in a single transaction?">
            <V2TextInput
              label={'Max mint txn'}
              variant="standard"
              value={String(projectConfig.contract.maxMintTxn || 0)}
              type="number"
              onUpdate={(value) => {
                if (isNaN(Number.parseFloat(value))) {
                  return;
                }

                onContractUpdate({ maxMintTxn: Number(value) });
              }}
            />
          </ProjectBuilderContainer>
        );
    }
  };

  return (
    <Grid
      className={'CAKE__project-builder-view'}
      container
      spacing={2}
    >
      <Grid
        item
        sm={6}
        className={'CAKE__project-builder-view__left'}
      >
        <Box className={'CAKE__project-builder-view__left__content'}>
          <Stepper
            currentStep={currentStep}
            setStep={(step) => dispatch(setStep(step))}
            stepCount={getStepCount()}
          />

          {getCurrentStep()}
        </Box>

        <Box className={'CAKE__project-builder-view__left__buttons'}>
          <Button
            onClick={() => dispatch(prevStep())}
            variant={'outlined'}
            color="secondary"
            disabled={currentStep === 0}
          >
            Back
          </Button>

          <Stack
            direction="row"
            spacing={2}
          >
            {!isLastStep && (
              <>
                <Button
                  variant={'outlined'}
                  onClick={() => dispatch(nextStep())}
                  color="secondary"
                >
                  Unsure, will answer later
                </Button>
                <Button
                  onClick={() => dispatch(nextStep())}
                  disabled={!canMoveToNextStep(projectConfig, currentStep)}
                  variant={'contained'}
                >
                  Next
                </Button>
              </>
            )}

            {isLastStep && (
              <Button
                onClick={() => dispatch(nextStep())}
                variant={'contained'}
                disabled={!projectConfigCompleted(projectConfig)}
              >
                Save Project
              </Button>
            )}
          </Stack>
        </Box>
      </Grid>

      <Grid
        item
        sm={6}
        className={'CAKE__project-builder-view__right'}
      >
        <ProjectBuilderImageUpload />
      </Grid>
    </Grid>
  );
};

export default ProjectBuilderView;
