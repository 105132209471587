import type { RootState } from '~features';

const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;

const selectIsLoading = (state: RootState) => state.auth.isLoading;

const selectUserEmail = (state: RootState) => state.auth.user?.email;

const selectUserId = (state: RootState) => state.auth.user?.sub;

const selectUserNickname = (state: RootState) => state.auth.user?.nickname;

const selectUserGravatar = (state: RootState) => state.auth.user?.picture;

const selectSessionId = (state: RootState) => state.auth.sid;

export {
  selectIsLoading,
  selectIsLoggedIn,
  selectSessionId,
  selectUserEmail,
  selectUserGravatar,
  selectUserId,
  selectUserNickname,
};
// isAuthenticated
// getUser
// getToken
