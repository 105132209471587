import type { RootState } from '~features';
import type { WhitelistType } from '~types/WhitelistType';

const selectWhitelistConfig = (state: RootState): WhitelistType => state.whitelistConfig.whitelist;

const selectWhitelistConfigIsLoading = (state: RootState): boolean => state.whitelistConfig.isLoading;

const selectWhitelistConfigError = (state: RootState): string | null => state.whitelistConfig.error;

export { selectWhitelistConfig, selectWhitelistConfigError, selectWhitelistConfigIsLoading };
