import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import reducers from '~features/reducers';
import rootSaga from '~features/sagas';
import Monitoring from '~utils/monitoring';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];
const enhancers = [Monitoring.getMonitoringReduxEnhancer()];

const store = configureStore({
  reducer: reducers,
  middleware,
  enhancers,
});

sagaMiddleware.run(rootSaga);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
