import { Avatar, CardHeader, Typography } from '@mui/material';
import moment from 'moment';

type ActivityHeaderProps = {
  profileImage?: string;
  updated?: string;
  userName: string;
};

const ActivityHeader = ({ profileImage, updated, userName }: ActivityHeaderProps) => {
  return (
    <CardHeader
      className={'CAKE__feed__activityCard__header'}
      avatar={
        <Avatar
          src={profileImage}
          alt="Cake"
        />
      }
      action={
        <Typography>
          {updated ? (
            moment(new Date(`${updated}Z`).toLocaleString(), 'MM/DD/YYYY, hh:mm:ss a').fromNow()
          ) : (
            <div className={'CAKE__feed__activityCard__loading-subheader'}></div>
          )}
        </Typography>
      }
      title={
        <Typography>
          {userName ? (
            userName.charAt(0).toUpperCase() + userName.slice(1)
          ) : (
            <div className={'CAKE__feed__activityCard__loading-subheader'}></div>
          )}
        </Typography>
      }
    />
  );
};

export default ActivityHeader;
