import { Button, Grid } from '@mui/material';
import { useState } from 'react';

import TraitInput from '~components/inputs/TokenGatePolicyConfigForm/components/TraitInput/TraitInput';
import type { TokenGatePolicy, TokenGateRule } from '~types/TokenGateTypes';

type TraitInputFormProps = {
  tokenGatePolicy: TokenGatePolicy;
  traits: Array<string>;
  handlePolicyUpdate: (policy: TokenGatePolicy) => void;
};

const TraitInputForm = ({ tokenGatePolicy, traits, handlePolicyUpdate }: TraitInputFormProps) => {
  const [addRuleIds, setAddRuleId] = useState<number[]>([]);
  const policyTraitRuleSet = new Set(tokenGatePolicy.traits.rules);

  const handleAddRule = () => {
    setAddRuleId([...addRuleIds, Math.random() * 1000000]);
  };

  const handleDeleteNewRule = () => {
    addRuleIds.splice(0, 1);
    setAddRuleId([...addRuleIds]);
  };

  return (
    <Grid
      container
      gap={2}
      direction={'column'}
    >
      {tokenGatePolicy.traits.rules.length ? (
        tokenGatePolicy.traits.rules.map((rule: TokenGateRule) => {
          return (
            <Grid
              key={Math.random() * 1000}
              item
            >
              <TraitInput
                data={rule}
                isNewRule={false}
                ruleSet={policyTraitRuleSet}
                tokenGatePolicy={tokenGatePolicy}
                traits={traits}
                handlePolicyUpdate={handlePolicyUpdate}
                handleDeleteNewRule={handleDeleteNewRule}
              />
            </Grid>
          );
        })
      ) : (
        <></>
      )}
      {addRuleIds.map((id: number) => {
        return (
          <Grid
            key={id}
            item
          >
            <TraitInput
              tokenGatePolicy={tokenGatePolicy}
              isNewRule={true}
              traits={traits}
              ruleSet={policyTraitRuleSet}
              handlePolicyUpdate={handlePolicyUpdate}
              handleDeleteNewRule={handleDeleteNewRule}
            />
          </Grid>
        );
      })}
      <Grid item>
        <Button
          variant={'outlined'}
          onClick={handleAddRule}
        >
          Add Rule
        </Button>
      </Grid>
    </Grid>
  );
};
export default TraitInputForm;
