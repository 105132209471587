// eslint-disable-next-line absolutely-import/import
import './ProjectNameCard.scss';

import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';

import { FileUploadKey } from '~components/inputs/FileUploadInput';
import ImageDropzone from '~components/inputs/ImageDropzone';
import QRCodeViewer from '~components/miscs/QRCodeViewer';
import { selectProjectConfig, selectUploadStatus } from '~features/project-config/project-config.selectors';
import { updateProjectConfig, uploadImage } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { PencilIcon } from '~icons/common';
import { UploadStatus } from '~types/miscs/UploadStatus';
import dappUrlGenerator from '~utils/url-builder/dappUrlGenerator/dappUrlGenerator';

// eslint-disable-next-line absolutely-import/import
import DashboardCard from '../DashboardCard';
// eslint-disable-next-line absolutely-import/import
import ProjectNameField from './components/ProjectNameField/ProjectNameField';

const ProjectNameCard = () => {
  const dispatch = useAppDispatch();
  const [isEditName, setIsEdit] = useState<boolean>(false);

  const uploadStatuses = useAppSelector(selectUploadStatus);
  const projectConfig = useAppSelector(selectProjectConfig);
  const { name, dapp, slug } = projectConfig;
  const { favicon } = dapp;

  const onUploadFile = (files: Array<File>, key: FileUploadKey) => {
    dispatch(uploadImage({ file: files[0], key }));
  };

  const onUpdateDisplayName = (value: string) => {
    dispatch(updateProjectConfig({ ...projectConfig, name: value, slug: value.toLowerCase().replaceAll(' ', '-') }));
  };

  return (
    <DashboardCard>
      <Box className={'CAKE__project-name-card'}>
        <ImageDropzone
          onDropFile={(files: Array<File>) => onUploadFile(files, FileUploadKey.FAVICON)}
          image={favicon}
          variant={'small'}
          isUploading={uploadStatuses[FileUploadKey.FAVICON] === UploadStatus.LOADING}
        />
        {!isEditName && (
          <>
            <Typography variant={'h6'}>{name}</Typography>
            <IconButton
              onClick={() =>
                setIsEdit((prev) => {
                  console.log({ prev });
                  return true;
                })
              }
            >
              <PencilIcon />
            </IconButton>
          </>
        )}
        {isEditName && (
          <ProjectNameField
            onChange={onUpdateDisplayName}
            onClickOutside={() => setIsEdit(false)}
            projectName={name}
          />
        )}
        <QRCodeViewer url={dappUrlGenerator(slug)} />
      </Box>
    </DashboardCard>
  );
};

export default ProjectNameCard;
