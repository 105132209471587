import '~components/inputs/FileDropzone/FileDropzone.scss';

import { Stack } from '@mui/system';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';

type FileDropzoneProps = {
  title?: string;
  onDropFiles: (files: Array<File>) => void;
};

const FileDropzone = ({ title = 'Drop files here', onDropFiles }: FileDropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropFiles });

  return (
    <Stack
      className={cx({
        'CAKE__file-dropzone': true,
        'CAKE__file-dropzone--active': isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>{title}</p>
    </Stack>
  );
};

export default FileDropzone;
