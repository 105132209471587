import { FileUploadKey } from '~components/inputs/FileUploadInput';
import type { ContentItemType } from '~types/ContentType';

const updateContentImagePath = (contentItem: ContentItemType, path: string, key: string) => {
  switch (key) {
    case FileUploadKey.CONTENT_ITEM_AVATAR:
      return {
        ...contentItem,
        author: {
          ...contentItem.author,
          image: path,
        },
      };
    case FileUploadKey.CONTENT_ITEM_BANNER:
      return {
        ...contentItem,
        bannerImage: path,
      };
  }
  return contentItem;
};

export { updateContentImagePath };
