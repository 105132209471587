import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import EditableTextField from '~components/inputs/EditableTextField';
import type { FAQEntry } from '~types/ProjectType';

type FAQSettingsModalProps = {
  isOpen: boolean;
  faq: FAQEntry;
  onCancel: () => void;
  onSave: (item: FAQEntry) => void;
};

const FAQSettingsModal = ({ isOpen, faq, onCancel, onSave }: FAQSettingsModalProps) => {
  const [question, setQuestion] = useState<string | null>(faq.question || '');
  const [answer, setAnswer] = useState<string | null>(faq.answer || '');

  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{'FAQ Settings'}</DialogTitle>
      <DialogContent>
        <Box width={'100%'}>
          <EditableTextField
            title={'Question'}
            value={question}
            multiline
            onUpdate={setQuestion}
          />
        </Box>
        <Box width={'100%'}>
          <EditableTextField
            title={'Answer'}
            value={answer}
            multiline
            onUpdate={setAnswer}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => onSave({ question, answer })}
          disabled={answer.length === 0 || question.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FAQSettingsModal;
