/* eslint-disable absolutely-import/import */
import './ProjectDrawer.scss';

import { Box, Drawer, Stack } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';

import ProjectDrawerFooter from './components/ProjectDrawerFooter';
import ProjectDrawerHeader from './components/ProjectDrawerHeader';
import ProjectDrawerMenu from './components/ProjectDrawerMenu';

const ProjectDrawer = () => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      variant="permanent"
      open={open}
      anchor={'right'}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Box className={'CAKE__project-drawer'}>
        <Stack
          className={classNames({
            'CAKE__project-drawer__container': true,
            'CAKE__project-drawer__container--open': open,
          })}
        >
          <ProjectDrawerHeader />
          <ProjectDrawerMenu small={!open} />
        </Stack>
        <ProjectDrawerFooter small={!open} />
      </Box>
    </Drawer>
  );
};

export default ProjectDrawer;
