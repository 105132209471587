import '~components/dashboard/Feed/Feed.scss';

import { Button, Card, CardContent, TextField } from '@mui/material';
import { useState } from 'react';

import { addActivityReaction } from '~features/streams/streams.slice';
import useAppDispatch from '~hooks/useAppDispatch';

type AddCommentProps = {
  activityId: string;
};

const AddComment = ({ activityId }: AddCommentProps) => {
  const dispatch = useAppDispatch();
  const [commentText, setCommentText] = useState('');

  const handleCommentText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
  };

  const handleCommentSubmit = () => {
    if (commentText) {
      dispatch(addActivityReaction({ kind: 'comment', activityId, data: { text: commentText } }));
    }
    setCommentText('');
  };

  return (
    <Card sx={{ borderRadius: '0px' }}>
      <CardContent className={'CAKE__feed__activityCard__add-comment__container'}>
        <TextField
          className={'CAKE__feed__activityCard__add-comment__text-input'}
          placeholder="Comment"
          value={commentText}
          onChange={handleCommentText}
          multiline
          rows={2}
        ></TextField>
        <Button
          variant="outlined"
          onClick={handleCommentSubmit}
        >
          Add Comment
        </Button>
      </CardContent>
    </Card>
  );
};

export default AddComment;
