import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { addNotification } from '~features/notifications/notifications.slice';
import { creationError } from '~features/project-builder/project-builder.consts';
import { isFirstStep, isLastStep } from '~features/project-builder/project-builder.helpers';
import {
  selectProjectBuilderImage,
  selectProjectBuilderProjectConfig,
  selectProjectBuilderStep,
} from '~features/project-builder/project-builder.selectors';
import {
  createProject,
  createProjectError,
  createProjectSuccess,
  nextStep,
  prevStep,
  resetProjectConfig,
  setStep,
} from '~features/project-builder/project-builder.slice';
import { addStreamActivity, setProjectFollowing } from '~features/streams/streams.slice';
import { postProtectedAPI } from '~features/utils/api/api.sagas';
import { uploadFileToS3 } from '~features/utils/s3storage/s3storage.sagas';
import type { ProjectType } from '~types/ProjectType';
import type { S3FileUploadResponse } from '~types/S3';
import getS3RootProjectPath from '~utils/api/getS3RootProjectPath';

function* createProjectSaga(): Iterator<any> {
  const projectConfig: ProjectType = yield select(selectProjectBuilderProjectConfig);
  const imageUpload = yield select(selectProjectBuilderImage);

  const payload = {
    ...projectConfig,
    isDraft: true,
    slug: projectConfig.name.toLowerCase().replaceAll(' ', '-'),
  };

  try {
    if (imageUpload) {
      const { dir } = getS3RootProjectPath(projectConfig.name);
      const imageData: S3FileUploadResponse = yield uploadFileToS3(imageUpload, dir);

      payload.dapp = {
        ...payload.dapp,
        backgroundImage: { dark: imageData.location, light: imageData.location },
      };
    }

    const res: Response = yield call(postProtectedAPI, 'project', payload);
    const data: any = yield res.json();

    if (res.status === 200 && data) {
      yield put(createProjectSuccess(data._id));
      yield put(setProjectFollowing({ id: data._id }));
      yield put(
        addStreamActivity({
          verb: 'cakeConfig',
          object: {
            content: {
              event: 'New Project Created',
            },
          },
          projectId: data._id,
        }),
      );
    } else {
      yield put(addNotification(creationError));
      yield put(createProjectError('Project creation failed'));
    }
  } catch (e) {
    console.log(e);
    yield put(addNotification(creationError));
    yield put(createProjectError(e.toString()));
  }
}

function* handleNextStepSaga(): Iterator<any> {
  const currentStep: number = yield select(selectProjectBuilderStep);
  if (isLastStep(currentStep)) {
    yield yield put(createProject());
    yield delay(1000);
    yield put(resetProjectConfig());
  } else {
    yield put(setStep(currentStep + 1));
  }
}

function* handlePrevStepSaga(): Iterator<any> {
  const currentStep: number = yield select(selectProjectBuilderStep);
  if (!isFirstStep(currentStep)) {
    yield put(setStep(currentStep - 1));
  }
}
export default function* projectBuilderSaga(): Iterator<any> {
  yield all([
    takeLatest(createProject.type, createProjectSaga),
    takeLatest(nextStep.type, handleNextStepSaga),
    takeLatest(prevStep.type, handlePrevStepSaga),
  ]);
}
