import Web3 from 'web3';

const getETHTransactionParams = async (provider, walletAddress, contractAddress, abi, chainId, params) => {
  const web3 = new Web3(provider);

  console.log({ walletAddress, contractAddress, abi, chainId, params });
  console.log(JSON.parse(abi));

  let encodedFunctionCall;

  encodedFunctionCall = web3.eth.abi.encodeFunctionCall(JSON.parse(abi), params);

  // const totalEth = (price * parseInt(num, 10)).toFixed(4)
  // const totalWei = web3.utils.toBN(web3.utils.toWei(`${totalEth}`, 'ether'));
  // console.log(`Value: ${totalWei}`)

  let gasEstimate = 200000;

  try {
    await web3.eth
      .estimateGas({
        from: walletAddress,
        to: contractAddress,
        data: encodedFunctionCall,
        // value: totalWei
      })
      .then((result) => {
        console.log(`Gas Estimate: ${result}`);
        gasEstimate = result;
      });
  } catch (e) {
    console.log(e);
    // reject(e)
  }

  return {
    nonce: '', // ignored by MetaMask
    gasPrice: '', // customizable by user during MetaMask confirmation.
    gas: `0x${gasEstimate.toString(16)}`, // customizable by user during MetaMask confirmation.
    to: contractAddress, // Required except during contract publications.
    from: walletAddress, // must match user's active address.
    value: '', // Only required to send ether to the recipient from the initiating external account. 0x${(totalWei).toString(16)}
    data: encodedFunctionCall, // Optional, but used for defining smart contract creation and interaction.
    chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
  };
};

export { getETHTransactionParams };
