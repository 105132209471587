import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';
import { useRef } from 'react';

type FileUploadButtonProps = {
  title: string;
  accept: string;
  onUpload: (file: File) => void;
};

const FileUploadButton = ({ title, onUpload, accept }: FileUploadButtonProps) => {
  const inputRef = useRef(null);

  const onUploadFile = () => {
    inputRef.current.click();
  };

  const onFileChoosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only runs updates if uploaded file name is diff from previous
    const file: File = e.target.files[0];
    onUpload(file);
  };

  return (
    <>
      <Button
        onClick={onUploadFile}
        variant={'outlined'}
      >
        {title}
        <FileUploadIcon />
      </Button>
      <input
        type="file"
        name=""
        id="input_file"
        accept={accept}
        ref={inputRef}
        hidden
        onChange={onFileChoosen}
      />
    </>
  );
};

export default FileUploadButton;
