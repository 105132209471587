import '~components/inputs/FileUploadAvatar/FileUploadAvatar.scss';

import { Avatar, Stack } from '@mui/material';
import classNames from 'classnames';
import { useRef } from 'react';

type FileUploadAvatarProps = {
  dense?: boolean;
  image: string;
  title?: string;
  onUpload: (file: File) => void;
};

const FileUploadAvatar = ({ dense = false, image, title, onUpload }: FileUploadAvatarProps) => {
  const inputRef = useRef(null);

  const onUploadFile = () => {
    inputRef.current.click();
  };

  const onFileChoosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = e.target.files[0];
    onUpload(file);
  };

  return (
    <Stack className={'CAKE__upload-avatar-field'}>
      {!dense && (
        <span
          className={classNames({
            'CAKE__upload-avatar-field__title': true,
          })}
        >
          {title}
        </span>
      )}
      <Stack
        className={classNames({
          'CAKE__upload-avatar-field__input-container': true,
          'CAKE__upload-avatar-field__input-container--dense': dense,
        })}
      >
        <Avatar
          className={'CAKE__upload-avatar-field__image'}
          alt="content-author"
          src={image}
          onClick={onUploadFile}
          sx={{ width: 75, height: 75 }}
        />
        <input
          ref={inputRef}
          accept="image/*"
          hidden
          id="avatar-image-upload"
          type="file"
          onChange={onFileChoosen}
        />
        <Stack
          className={classNames({
            'CAKE__upload-avatar-field__buttons-container': true,
            'CAKE__upload-avatar-field__buttons-container--wide': true,
          })}
        ></Stack>
      </Stack>
    </Stack>
  );
};

export default FileUploadAvatar;
