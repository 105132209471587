import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';

import EditableTextField from '~components/inputs/EditableTextField';
import type TraitType from '~types/token/TraitType';

type TraitEditModalProps = {
  isOpen: boolean;
  trait?: TraitType;
  onConfirm: (trait: TraitType) => void;
  onCancel: () => void;
};

const TraitEditModal = ({ isOpen, trait, onConfirm, onCancel }: TraitEditModalProps) => {
  const [currentTrait, setCurrentTrait] = useState<TraitType>(trait || { value: '' });

  const onDisplayTypeChange = (value: string) => {
    setCurrentTrait({
      ...currentTrait,
      display_type: value,
    });
  };
  const onTraitTypeChange = (value: string) => {
    setCurrentTrait({
      ...currentTrait,
      trait_type: value,
    });
  };

  const onTraitValueChange = (value: string) => {
    setCurrentTrait({
      ...currentTrait,
      value,
    });
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{'FAQ Settings'}</DialogTitle>
      <DialogContent>
        <Box width={'100%'}>
          <EditableTextField
            title={'display_type'}
            value={currentTrait.display_type || ''}
            onUpdate={onDisplayTypeChange}
          />
        </Box>
        <Box
          width={'100%'}
          sx={{ paddingTop: '16px' }}
        >
          <EditableTextField
            title={'trait_type'}
            value={currentTrait.trait_type || ''}
            onUpdate={onTraitTypeChange}
          />
        </Box>
        <Box
          width={'100%'}
          sx={{ paddingTop: '16px' }}
        >
          <EditableTextField
            title={'value'}
            value={(currentTrait.value || '').toString()}
            onUpdate={onTraitValueChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onConfirm(currentTrait)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TraitEditModal;
