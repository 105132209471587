import { QRCodeSVG } from 'qrcode.react';

type QRCodeProps = {
  url: string;
};

const QRCodeImage = ({ url }: QRCodeProps) => {
  return (
    <QRCodeSVG
      value={url}
      width={'500px'}
      height={'500px'}
    />
  );
};

export default QRCodeImage;
