import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import DeployButton from '~components/dashboard/ContractDeploymentCard/components/DeployButton';
import ViewContractButton from '~components/dashboard/ContractDeploymentCard/components/ViewContractButton';
import DashboardCard from '~components/dashboard/DashboardCard';
import EditableTextField from '~components/inputs/EditableTextField';
import NetworkToggle from '~components/miscs/NetworkToggle/NetworkToggle';
import {
  selectContractDeployerIsLoading,
  selectContractDeployerNetwork,
} from '~features/contract-deployer/contract-deployer.selectors';
import { callDeployContract } from '~features/contract-deployer/contract-deployer.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { updateProjectConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

type ContractDeploymentCardProps = {
  chainId: number | null;
  showNetworkToggle: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleNetworkChange: Function;
};

const ContractDeploymentCard = ({ chainId, showNetworkToggle, handleNetworkChange }: ContractDeploymentCardProps) => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(selectProjectConfig);
  const network = useAppSelector(selectContractDeployerNetwork);
  const isDeploying = useAppSelector(selectContractDeployerIsLoading);

  const { _id, contract, name } = projectConfig;

  const [isLive, setIsLive] = useState<boolean>(false); // value representing if contract deployed to mainnet

  useEffect(() => {
    contract.livenet?.contractAddress ? setIsLive(true) : setIsLive(false);
  }, []);

  const handleDisabledUpdates = () => {
    dispatch(
      addNotification({
        message:
          'Your contract is already deployed to Mainnet. Any updates to this field will not be reflected on the contract.',
        severity: 'error',
        duration: 10000,
      }),
    );
  };

  const onUpdateContractName = (value: string) => {
    if (isLive) {
      handleDisabledUpdates();
    } else {
      dispatch(updateProjectConfig({ ...projectConfig, name: value }));
    }
  };

  const onUpdateContractSymbol = (value: string) => {
    if (isLive) {
      handleDisabledUpdates();
    } else {
      dispatch(updateProjectConfig({ ...projectConfig, contract: { ...contract, symbol: value } }));
    }
  };

  const onUpdateContractSupply = (value: string) => {
    if (isLive) {
      handleDisabledUpdates();
    } else {
      if (!Number(value) && Number(value) !== 0) {
        dispatch(
          addNotification({
            message: 'Please provide a valid number to you contract supply.',
            severity: 'warning',
            duration: 5000,
          }),
        );
      } else {
        dispatch(updateProjectConfig({ ...projectConfig, contract: { ...contract, totalSupply: Number(value) } }));
      }
    }
  };

  const onDeployContract = (deploymentNetwork: string) => {
    dispatch(callDeployContract({ projectId: _id, network: deploymentNetwork, chainId }));
  };

  return (
    <Stack>
      <DashboardCard
        title={'Contract Settings'}
        rightContainer={
          <div className={'CAKE__contract-deployer__dashboard-card'}>
            <ViewContractButton chainId={chainId} />
            <NetworkToggle
              showToggle={showNetworkToggle}
              chainId={chainId}
              handleNetworkChange={handleNetworkChange}
            />
          </div>
        }
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 8 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
            >
              <EditableTextField
                title="Project Name"
                value={name || ''}
                onUpdate={onUpdateContractName}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              sx={{ marginTop: '10px' }}
            >
              <EditableTextField
                title="Symbol"
                value={contract.symbol || ''}
                onUpdate={onUpdateContractSymbol}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              sx={{ marginTop: '10px' }}
            >
              <EditableTextField
                title="Total Supply"
                value={contract.totalSupply ? String(contract.totalSupply) : ''}
                onUpdate={onUpdateContractSupply}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              sx={{ marginTop: '20px' }}
            >
              <DeployButton
                contract={contract}
                network={network}
                isDeploying={isDeploying}
                onDeployContract={onDeployContract}
              />
            </Grid>
          </Grid>
        </Grid>
      </DashboardCard>
    </Stack>
  );
};

export default ContractDeploymentCard;
