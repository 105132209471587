import '~components/dashboard/ContentItemEditor/components/SubmarineDocumentsSettings/SubmarineDocumentsSettings.scss';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { LoadingButton } from '@mui/lab';
import { Grid, IconButton } from '@mui/material';

import FileUploadButton from '~components/buttons/FileUploadButton';
import FileUploadInput from '~components/inputs/FileUploadInput';
import { selectIsItemUploading } from '~features/project-content/project-content.selectors';
import useAppSelector from '~hooks/useAppSelector';
import type { ContentItemType } from '~types/ContentType';
import { UploadStatus } from '~types/miscs/UploadStatus';

type SubmarineDocumentsSettingsProps = {
  contentItem: ContentItemType;
  onUploadIPFSFile: (file: File) => void;
  onDeleteIPFSFile: (id: string) => void;
};

const SubmarineDocumentsSettings = ({
  contentItem,
  onUploadIPFSFile,
  onDeleteIPFSFile,
}: SubmarineDocumentsSettingsProps) => {
  const isFileLoading = useAppSelector(selectIsItemUploading);

  return (
    <Grid
      container
      columns={{ xs: 2, sm: 8, md: 8 }}
      direction="row"
    >
      <div className={'CAKE__content-item__documents-settings'}>
        <p className={'CAKE__content-item__documents-settings__title'}>Token-Gated Documents</p>
        <p className={'CAKE__content-item__documents-settings__subtitle'}>
          Unlike images and embeds contained within your post, the files are visible only to authorized token holders.
        </p>
      </div>
      <Grid
        item
        xs={2}
        sm={4}
        md={4}
      >
        {contentItem.ipfsFiles.length ? (
          contentItem.ipfsFiles.map((item: any) => {
            return (
              <Grid
                key={item.name}
                container
                columns={{ xs: 2, sm: 8, md: 8 }}
                sx={{ margin: '10px 0', alignItems: 'end' }}
                direction="row"
              >
                <Grid
                  item
                  xs={2}
                  sm={7}
                  md={7.5}
                >
                  <FileUploadInput
                    title={item.name}
                    uploadState={isFileLoading ? UploadStatus.LOADING : 'A'}
                    path={item.url || ''}
                    onUpload={(file: File) => onUploadIPFSFile(file)}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={0.25}
                  md={0.35}
                  lg={0.25}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={() => onDeleteIPFSFile(item.id)}
                  >
                    <HighlightOffRoundedIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={0.25}
                  md={0.15}
                >
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton aria-label="view">
                      <OpenInNewRoundedIcon />
                    </IconButton>
                  </a>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <></>
        )}
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          {isFileLoading ? (
            <LoadingButton
              loading
              variant={'outlined'}
            >
              Submit
            </LoadingButton>
          ) : (
            <FileUploadButton
              title={'Upload'}
              accept="image/*,.pdf,video/*,audio/*"
              onUpload={(file: File) => onUploadIPFSFile(file)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubmarineDocumentsSettings;
