/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { BatterDrop, SprinklesDrop } from '~types/DropType';

type Product = BatterDrop['shopifyProduct'];

interface ProjectDropState {
  drop: BatterDrop | null;
  isLoading: boolean;
  isSaving: boolean;
  error: string | null;
  products: Record<string, Product>;
}

const initialState: ProjectDropState = {
  drop: null,
  isLoading: false,
  isSaving: false,
  error: null,
  products: {},
};

const projectDropState = createSlice({
  name: 'project-utility-manager',
  initialState,
  reducers: {
    getProjectDrop(state, action: PayloadAction<{ id: string; dropId: string }>) {
      state.isLoading = true;
      state.error = null;
    },
    setProjectDrop(state, action: PayloadAction<BatterDrop>) {
      state.drop = action.payload;
    },
    setProjectDropError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.drop = null;
    },
    getProjectDropProducts(state, action: PayloadAction<{ id: string }>) {
      state.isLoading = true;
    },
    setProjectDropProducts(state, action: PayloadAction<Record<string, Product>>) {
      state.products = action.payload;
      state.isLoading = false;
    },
    setProjectDropProductsError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.products = {};
    },
    deleteProjectDrop(state, action: PayloadAction<{ id: string }>) {
      state.isSaving = true;
    },
    deleteProjectDropSuccess(state) {
      state.drop = null;
      state.isSaving = false;
    },
    deleteProjectDropError(state, action: PayloadAction<string>) {
      state.isSaving = false;
    },
    saveProjectDrop(state, action: PayloadAction<{ dropId: string; data: Required<SprinklesDrop> }>) {
      state.isSaving = true;
    },
    saveProjectDropSuccess(state, action: PayloadAction<BatterDrop | null>) {
      state.drop = action.payload ? { ...(state.drop ?? {}), ...action.payload } : null;
      state.isSaving = false;
    },
    saveProjectDropError(state, action: PayloadAction<string>) {
      state.isSaving = false;
    },
  },
});

export const {
  getProjectDropProducts,
  getProjectDrop,
  setProjectDrop,
  setProjectDropError,
  setProjectDropProducts,
  setProjectDropProductsError,
  deleteProjectDrop,
  deleteProjectDropSuccess,
  deleteProjectDropError,
  saveProjectDrop,
  saveProjectDropSuccess,
  saveProjectDropError,
} = projectDropState.actions;

export default projectDropState.reducer;
