import { Navigate } from 'react-router-dom';

import PageLoader from '~components/miscs/PageLoader';
import { PublicRoutes } from '~constants/routes';
import { selectIsLoading, selectIsLoggedIn } from '~features/auth/auth.selectors';
import useAppSelector from '~hooks/useAppSelector';

type PrivateRouteProps = {
  children: JSX.Element;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const isLoading = useAppSelector(selectIsLoading);

  if (!isLoggedIn && !isLoading) {
    return <Navigate to={PublicRoutes.LOGIN} />;
  }

  return !isLoading ? children : <PageLoader />;
};

export default PrivateRoute;
