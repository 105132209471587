import type { RootState } from '~features';

const selectProjectBuilderProjectConfig = (state: RootState) => state.projectBuilder.project;

const selectProjectBuilderStep = (state: RootState) => state.projectBuilder.currentStep;

const selectProjectBuilderImage = (state: RootState) => state.projectBuilder.imageFile;

const selectProjectBuilderIsLoading = (state: RootState) => state.projectBuilder.status.isLoading;

export {
  selectProjectBuilderImage,
  selectProjectBuilderIsLoading,
  selectProjectBuilderProjectConfig,
  selectProjectBuilderStep,
};
