import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';

import { getMintAnalytics, getTransferData, getWalletDistribution } from '~features/analytics/analytics.slice';
import useAppDispatch from '~hooks/useAppDispatch';

type AnalyticsRefreshButtonProps = {
  type: string;
  projectId: string;
  contractAddress: string;
};

const AnalyticsRefreshButton = ({ type, projectId, contractAddress }: AnalyticsRefreshButtonProps) => {
  const dispatch = useAppDispatch();

  const handleRefresh = () => {
    if (type === 'MINT_ANALYTICS') {
      dispatch(
        getMintAnalytics({
          projectId,
          contractAddress,
          refresh: true,
        }),
      );
    } else if (type === 'WALLET_DISTRIBUTION') {
      dispatch(
        getWalletDistribution({
          projectId,
          contractAddress,
          refresh: true,
        }),
      );
    } else if (type === 'TRANSACTION_DATA') {
      dispatch(
        getTransferData({
          projectId,
          contractAddress,
          refresh: true,
        }),
      );
    }
  };

  return (
    <IconButton
      aria-label="refresh"
      onClick={handleRefresh}
    >
      <RefreshIcon />
    </IconButton>
  );
};

export default AnalyticsRefreshButton;
