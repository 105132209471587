import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0: Auth0Client = new Auth0Client({
  domain: (process.env.REACT_APP_AUTH0_DOMAIN || '').toString(),
  client_id: (process.env.REACT_APP_AUTH0_CLIENT_ID || '').toString(),
  redirect_uri: (process.env.REACT_APP_AUTH0_CALLBACK_URL || '').toString(),
  audience: (process.env.REACT_APP_AUTH0_AUDIENCE || '').toString(),
});

const getAuthClient = (): Auth0Client => {
  return auth0;
};

export { getAuthClient };
