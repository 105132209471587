/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { FeedSliceType } from '~types/Streams';

interface StreamsState extends FeedSliceType {
  client: any;
  authToken?: string;
  isNewFeedEventWaiting: boolean;
  activityGroupToActivityIdMap: any;
  activityIdToActivityGroupIdMap: any;
  feedTokenImagesMap: any;
  attachment: string;
  isFeedUpdating: boolean;
  feedIsLoading: boolean;
  error: string;
}

const initialState: StreamsState = {
  client: null,
  authToken: null,
  order: [],
  feedDataMap: {},
  activityGroupToActivityIdMap: {},
  activityIdToActivityGroupIdMap: {},
  feedTokenImagesMap: {},
  attachment: '',
  next: false,
  offset: 0,
  isNewFeedEventWaiting: false,
  isFeedUpdating: false,
  feedIsLoading: true,
  error: null,
};

const streamsSlice = createSlice({
  name: 'streams',
  initialState,
  reducers: {
    createNewStreamUser(
      state: StreamsState,
      action: PayloadAction<{ userId: string; userName: string; profileImage: string }>,
    ) {},
    initProjectStream(state: StreamsState) {},
    setFeedIsLoading(state: StreamsState, action: PayloadAction<boolean>) {
      state.feedIsLoading = action.payload;
    },
    setFeedError(state: StreamsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.feedIsLoading = false;
    },
    setProjectStreamConfig(state: StreamsState, action: PayloadAction<{ client: any; authToken: string }>) {
      console.log('configuring project stream connection');
      state.client = action.payload.client;
      state.authToken = action.payload.authToken;
    },
    subscribeToProjectChannel(
      state: StreamsState,
      action: PayloadAction<{ client: any; projectId: string; token: string }>,
    ) {},
    unsubscribeFromProjectStream(state: StreamsState) {},
    resetProjectStreamData(state: StreamsState) {
      state.order = initialState.order;
      state.feedDataMap = initialState.feedDataMap;
      state.activityGroupToActivityIdMap = initialState.activityGroupToActivityIdMap;
      state.activityIdToActivityGroupIdMap = initialState.activityIdToActivityGroupIdMap;
      state.feedTokenImagesMap = initialState.feedTokenImagesMap;
      state.attachment = initialState.attachment;
      state.next = initialState.next;
      state.feedIsLoading = initialState.feedIsLoading;
      state.error = initialState.error;
      state.isNewFeedEventWaiting = initialState.isNewFeedEventWaiting;
    },
    setProjectStreamData(
      state: StreamsState,
      action: PayloadAction<{
        activityOrder: string[];
        activities: any;
        activityGroupToActivityIdMap: any;
        activityIdToActivityGroupIdMap: any;
      }>,
    ) {
      state.order = action.payload.activityOrder;
      state.feedDataMap = action.payload.activities;
      state.activityGroupToActivityIdMap = action.payload.activityGroupToActivityIdMap;
      state.activityIdToActivityGroupIdMap = action.payload.activityIdToActivityGroupIdMap;
    },
    setProjectStreamNextPage(state: StreamsState, action: PayloadAction<any>) {
      state.next = action.payload;
    },
    setProjectFollowing(statee: StreamsState, action: PayloadAction<{ id: string }>) {},
    addStreamActivity(state, action: PayloadAction<any>) {},
    setNewFeedEventWaiting(state, action: PayloadAction<boolean>) {
      state.isNewFeedEventWaiting = action.payload;
    },
    fetchProjectFeed(state) {},
    setProjectFeedUpdateStatus(state, action: PayloadAction<boolean>) {
      state.isFeedUpdating = action.payload;
    },
    addActivityReaction(
      state,
      action: PayloadAction<{ kind: 'like' | 'comment'; activityId: string; data?: { text: string } }>,
    ) {},
    addChildActivityReaction(state, action: PayloadAction<{ kind: 'like'; activityId: string; reactionId: string }>) {},
    removeStreamReaction(state, action: PayloadAction<{ reactionId: string; activityId: string; isChild: boolean }>) {},
    uploadUserPostImage(state, action: PayloadAction<{ file: File }>) {},
    setUserPostAttachment(state, action: PayloadAction<{ location: string }>) {
      state.attachment = action.payload.location;
    },
    fetchProjectTokenImages(state) {},
    addTokenImageToMap(state, action: PayloadAction<{ tokenId: number; image: string }>) {
      state.feedTokenImagesMap = { ...state.feedTokenImagesMap, [action.payload.tokenId]: action.payload.image };
    },
  },
});

export const {
  createNewStreamUser,
  initProjectStream,
  setFeedIsLoading,
  setFeedError,
  setProjectStreamConfig,
  subscribeToProjectChannel,
  unsubscribeFromProjectStream,
  resetProjectStreamData,
  setProjectStreamData,
  setProjectStreamNextPage,
  setProjectFollowing,
  addStreamActivity,
  setNewFeedEventWaiting,
  fetchProjectFeed,
  setProjectFeedUpdateStatus,
  addActivityReaction,
  addChildActivityReaction,
  removeStreamReaction,
  uploadUserPostImage,
  setUserPostAttachment,
  fetchProjectTokenImages,
  addTokenImageToMap,
} = streamsSlice.actions;
export default streamsSlice.reducer;
