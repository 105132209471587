import { Box, Button, Drawer, Stack } from '@mui/material';
import { useState } from 'react';

import Header from '~components/layout/headers/Header';
import { useWallet } from '~context/WalletContext';

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const [isWalletDrawer, setIsWalletDrawer] = useState<boolean>(false);
  const { selectProvider } = useWallet();

  const onSelectProvider = (walletType: string) => {
    selectProvider(walletType);
    setIsWalletDrawer(false);
  };
  return (
    <Stack height={'100%'}>
      <Header onOpenWalletConnection={() => setIsWalletDrawer(true)} />
      <Box height={'100%'}>{children}</Box>
      <Drawer
        anchor={'right'}
        open={isWalletDrawer}
        onClose={() => setIsWalletDrawer(false)}
      >
        <Stack
          sx={{ color: 'white', backgroundColor: '#009688', height: '100%' }}
          padding={4}
          gap={2}
        >
          <Button
            variant="outlined"
            onClick={() => onSelectProvider('metamask')}
            color="inherit"
          >
            Metamask
          </Button>
          <Button
            variant="outlined"
            onClick={() => onSelectProvider('cb-wallet')}
            color="inherit"
          >
            Coinbase Wallet
          </Button>
        </Stack>
      </Drawer>
    </Stack>
  );
};

export default DashboardLayout;
