import '~components/dashboard/ContentItemEditor/components/BasicSettings/BasicSettings.scss';

import { Grid } from '@mui/material';

import EditableTextField from '~components/inputs/EditableTextField';
import FileUploadAvatar from '~components/inputs/FileUploadAvatar';
import FileUploadInput from '~components/inputs/FileUploadInput';
import { FileUploadKey } from '~components/inputs/FileUploadInput';
import type { ContentItemType } from '~types/ContentType';

type BasicSettingsProps = {
  contentItem: ContentItemType;
  onUpdateTitle: (value: string) => void;
  onUploadFile: (file: File, key: string) => void;
  onUpdateAuthor: (value: string) => void;
  onUpdateDescription: (value: string) => void;
};

const BasicSettings = ({
  contentItem,
  onUpdateTitle,
  onUploadFile,
  onUpdateAuthor,
  onUpdateDescription,
}: BasicSettingsProps) => {
  return (
    <Grid
      container
      columns={{ xs: 2, sm: 8, md: 8 }}
    >
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
      >
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          <div className={'CAKE__content-item__basic-settings__status'}>
            <p className={'CAKE__content-item__basic-settings__status__title'}>Status:</p>
            {contentItem.status === 'Published' ? (
              <p className={'CAKE__content-item__basic-settings__status__pill--published'}>Published</p>
            ) : (
              <p className={'CAKE__content-item__basic-settings__status__pill--draft'}>{contentItem.status}</p>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
      >
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          <EditableTextField
            title="Title"
            value={contentItem.title || ''}
            onUpdate={onUpdateTitle}
          />
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ margin: '10px 0' }}
      >
        <Grid
          item
          xs={1}
          sm={0.75}
          md={0.75}
        >
          <FileUploadAvatar
            title=""
            image={contentItem.author.image}
            onUpload={(file: File) => onUploadFile(file, FileUploadKey.CONTENT_ITEM_AVATAR)}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={3.5}
          md={3.5}
        >
          <EditableTextField
            title="Author"
            value={contentItem.author.name || ''}
            onUpdate={onUpdateAuthor}
          />
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ margin: '10px 0' }}
      >
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          <FileUploadInput
            title="Banner Image"
            uploadState={'A'}
            path={contentItem.bannerImage || ''}
            onUpload={(file: File) => onUploadFile(file, FileUploadKey.CONTENT_ITEM_BANNER)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 2, sm: 8, md: 8 }}
        sx={{ margin: '10px 0' }}
      >
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
        >
          <EditableTextField
            title="Description"
            multiline={true}
            value={contentItem.description || ''}
            onUpdate={onUpdateDescription}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicSettings;
