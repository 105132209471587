import '~components/inputs/V2TextInput/V2TextInput.scss';

import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import classNames from 'classnames';

import { CheckmarkIcon } from '~icons/common';
import theme from '~theme';
import type { UseInputProps } from '~utils/hooks/useInput';
import useInput from '~utils/hooks/useInput';

export type V2TextInputProps = UseInputProps &
  TextFieldProps & {
    errorText?: string;
    helperText?: string;
    noBorder?: boolean;
    textAlign?: 'right' | 'left';
    showSuccessIcon?: boolean;
  };

const V2TextInput = ({
  className = '',
  debounce,
  onUpdate,
  value,
  errorText,
  helperText,
  validator,
  noBorder,
  textAlign,
  showSuccessIcon,
  ...rest
}: V2TextInputProps) => {
  const { currentValue, isValid, hasValidated, onValueChange } = useInput({
    debounce,
    onUpdate,
    validator,
    value,
  });

  return (
    <TextField
      {...rest}
      className={classNames(className, {
        'CAKE__v2-text-input': true,
        'CAKE__v2-text-input--noborder': noBorder,
        'CAKE__v2-text-input--right': textAlign === 'right',
      })}
      InputProps={{
        endAdornment: showSuccessIcon && hasValidated && isValid && (
          <CheckmarkIcon
            width={24}
            height={24}
            fill={theme.palette.success.main}
          />
        ),
      }}
      error={!isValid}
      value={currentValue}
      helperText={isValid ? helperText : errorText}
      onChange={onValueChange}
    />
  );
};

export default V2TextInput;
