import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import type { MomentInput } from 'moment';
import moment from 'moment';

import type { ProjectType } from '~types/ProjectType';

type ProjectLaunchDatesFormProps = {
  config: ProjectType;
  onConfigUpdated: (config: ProjectType) => void;
};

const ProjectLaunchDatesForm = ({ config, onConfigUpdated }: ProjectLaunchDatesFormProps) => {
  const updateDate = (date: MomentInput, field: 'presale' | 'publicSale') => {
    onConfigUpdated({
      ...config,
      [field]: Number(date.valueOf()),
    });
  };

  const renderInput = (params: TextFieldProps) => (
    <TextField
      {...params}
      variant="standard"
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        label="Pre-sale date"
        value={moment(config.presale)}
        onChange={(val) => updateDate(val, 'presale')}
        renderInput={renderInput}
      />

      <DateTimePicker
        label="Public sale date"
        value={moment(config.publicSale)}
        onChange={(val) => updateDate(val, 'publicSale')}
        renderInput={renderInput}
      />
    </LocalizationProvider>
  );
};

export default ProjectLaunchDatesForm;
