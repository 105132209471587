type RedirectProps = {
  to: string;
};

const Redirect = ({ to }: RedirectProps) => {
  window.location.replace(to);
  return null;
};

export default Redirect;
