import V2TextInput from '~components/inputs/V2TextInput';
import type { V2TextInputProps } from '~components/inputs/V2TextInput/V2TextInput';
import walletAddressValidator from '~utils/web3/validators/wallet-address-validator';

type WalletAddressFieldInputProps = V2TextInputProps & {
  errorText?: string;
  helperText?: string;
};

const WalletAddressFieldInput = ({ onUpdate, value, errorText, helperText, ...rest }: WalletAddressFieldInputProps) => {
  return (
    <V2TextInput
      {...rest}
      showSuccessIcon
      value={value}
      errorText={errorText}
      helperText={helperText}
      onUpdate={onUpdate}
      validator={walletAddressValidator}
    />
  );
};

export default WalletAddressFieldInput;
