import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type MerklerootDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const MerklerootDialog = ({ open, handleClose }: MerklerootDialogProps) => {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{' Would You Like To Update Your Smart Contract?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Making updates to your whitelist will impact presale mint access.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {`Make sure you update your contract's with the merkleroot we provide to avoid any pre-sale access-related
            issues.`}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Note that updating your merkleroot will will require a small transaction fee to update your smart contract
            on the blockchain.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'outlined'}
            onClick={() => handleClose()}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MerklerootDialog;
