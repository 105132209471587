import '~views/user/SignupView/SignupView.scss';

import { Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthCard from '~components/miscs/AuthCard';
import PageLoader from '~components/miscs/PageLoader';
import { PublicRoutes } from '~constants/routes';
import {
  selectInvitationId,
  selectIsSignupFinished,
  selectIsSignupInProgress,
  selectSignupError,
} from '~features/user-invitation/user-invitation.selectors';
import { invitationSignup } from '~features/user-invitation/user-invitation.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const SignupView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInProgress = useAppSelector(selectIsSignupInProgress);
  const isSignupFinished = useAppSelector(selectIsSignupFinished);
  const signupError = useAppSelector(selectSignupError);
  const invitationId = useAppSelector(selectInvitationId);

  useEffect(() => {
    if (invitationId === null) {
      navigate(PublicRoutes.LOGIN);
    }
  }, [invitationId, navigate]);

  const onSignup = (user: string, password: string) => {
    dispatch(invitationSignup({ user, password }));
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width={'100%'}
      height={'100%'}
    >
      {isSignupFinished && (
        <Stack gap={2}>
          <Typography
            variant={'h6'}
            color={'primary'}
          >
            Signup completed
          </Typography>
          <Button
            onClick={() => navigate(PublicRoutes.LOGIN)}
            variant={'contained'}
          >
            Go to login page
          </Button>
        </Stack>
      )}
      {signupError && (
        <Typography
          variant={'h6'}
          color={'primary'}
        >
          {signupError}
        </Typography>
      )}
      {isInProgress && <PageLoader />}
      {!isInProgress && !signupError && !isSignupFinished && <AuthCard onConfirm={onSignup} />}
    </Stack>
  );
};

export default SignupView;
