import { useState } from 'react';

import useDebounce from '~utils/hooks/useDebounce';

export type UseInputProps = {
  debounce?: number;
  onUpdate: (value: string) => void;
  value: string;
  validator?: (value: string) => boolean;
};

const useInput = ({ debounce = 1000, onUpdate, validator, value }: UseInputProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [hasValidated, setHasValidated] = useState(false);

  const handleUpdate = () => {
    if (!currentValue) {
      return setHasValidated(false);
    }

    if (validator) {
      setIsValid(validator(currentValue));
      setHasValidated(true);
    }

    onUpdate(currentValue);
  };

  useDebounce(handleUpdate, [currentValue, validator], debounce);

  const onValueChange = (e) => {
    setCurrentValue(e.target.value);
  };

  return {
    currentValue,
    hasValidated,
    isValid,
    onValueChange,
  };
};

export default useInput;
