import { Stack, Typography } from '@mui/material';

const NotFoundView = () => (
  <Stack
    alignContent={'center'}
    justifyContent={'center'}
    width={'100%'}
    height={'100%'}
  >
    <Typography textAlign={'center'}>Page not found</Typography>
  </Stack>
);

export default NotFoundView;
