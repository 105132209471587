import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';

import DashboardCard from '~components/dashboard/DashboardCard';
import FAQDeletionModal from '~components/dashboard/FAQDeletionModal';
import FAQSettingsEntry from '~components/dashboard/FAQSettingsCard/components/FAQSettingsEntry';
import FAQSettingsModal from '~components/dashboard/FAQSettingsModal';
import { selectProjectDappConfig } from '~features/project-config/project-config.selectors';
import { updateProjectDappConfig } from '~features/project-config/project-config.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import type { FAQEntry } from '~types/ProjectType';

const FAQSettingsCard = () => {
  const dappConfig = useAppSelector(selectProjectDappConfig);
  const { faq } = dappConfig;
  const dispatch = useAppDispatch();
  const [editIndex, setEditIndex] = useState<number>(null);
  const [deleteIndex, setDeleteIndex] = useState<number>(null);

  const onAddItem = () => {
    setEditIndex(faq.length);
  };

  const onDeleteItem = (idx: number) => {
    setDeleteIndex(idx);
  };

  const onEditItem = (idx: number) => {
    setEditIndex(idx);
  };

  const onSaveItem = (item: FAQEntry) => {
    const modified = Array.from(faq);
    modified.splice(editIndex, 1, item);
    dispatch(
      updateProjectDappConfig({
        dapp: {
          ...dappConfig,
          faq: modified,
        },
        event: {
          content: {
            event: 'dApp Settings Update',
            description: 'New FAQ item added.',
          },
        },
      }),
    );
    setEditIndex(null);
  };

  const onModalClose = () => {
    setEditIndex(null);
  };

  const onDeleteCanceled = () => {
    setDeleteIndex(null);
  };

  const onDeleteConfirmed = () => {
    const modified = Array.from(faq);
    modified.splice(deleteIndex, 1);
    dispatch(
      updateProjectDappConfig({
        dapp: {
          ...dappConfig,
          faq: modified,
        },
        event: {
          content: {
            event: 'dApp Settings Update',
            description: 'FAQ item deleted.',
          },
        },
      }),
    );
    setDeleteIndex(null);
  };

  return (
    <DashboardCard title={'FAQ settings'}>
      <Stack>
        {faq &&
          faq.length > 0 &&
          faq.map((item: FAQEntry, idx: number) => (
            <FAQSettingsEntry
              key={idx}
              index={idx}
              faq={item}
              onEdit={() => onEditItem(idx)}
              onDelete={() => onDeleteItem(idx)}
            />
          ))}
      </Stack>
      <Box
        textAlign={'left'}
        pt={2}
      >
        <Button
          onClick={onAddItem}
          variant={'contained'}
          size={'small'}
        >
          Add
        </Button>
      </Box>
      {editIndex !== null && (
        <FAQSettingsModal
          isOpen={editIndex !== null}
          faq={faq[editIndex] || { question: '', answer: '' }}
          onCancel={onModalClose}
          onSave={onSaveItem}
        />
      )}
      {deleteIndex !== null && (
        <FAQDeletionModal
          isOpen={deleteIndex !== null}
          onCancel={onDeleteCanceled}
          onConfirm={onDeleteConfirmed}
        />
      )}
    </DashboardCard>
  );
};

export default FAQSettingsCard;
