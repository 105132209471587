import '~components/inputs/FileUploadInput/FileUploadInput.scss';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Skeleton, Stack } from '@mui/material';
import React from 'react';
import { useRef } from 'react';

import InputIconButton from '~components/buttons/InputIconButton';
import { UploadStatus } from '~types/miscs/UploadStatus';

type FileUploadInputProps = {
  path: string;
  title: string;
  uploadState: string;
  onUpload: (file: File) => void;
};

const FileUploadInput = ({ path, title, uploadState, onUpload }: FileUploadInputProps) => {
  const inputRef = useRef(null);

  const onUploadFile = () => {
    inputRef.current.click();
  };

  const onFileChoosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = e.target.files[0];
    onUpload(file);
  };

  return (
    <Stack>
      <span className={'CAKE__file-upload-input__title'}>{title}</span>
      {uploadState === UploadStatus.LOADING ? (
        <Skeleton height={38} />
      ) : (
        <>
          <Stack className={'CAKE__file-upload-input__container'}>
            <span className={'CAKE__file-upload-input__container__file-path'}>{path}</span>
            <Stack className={'CAKE__file-upload-input__buttons-container'}>
              <InputIconButton
                onClick={onUploadFile}
                color="#009688"
              >
                <FileUploadIcon />
              </InputIconButton>
            </Stack>
          </Stack>
          <input
            type="file"
            name=""
            id="input_file"
            accept="image/png, image/gif, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/quicktime, video/mp4"
            ref={inputRef}
            hidden
            onChange={onFileChoosen}
          />
        </>
      )}
    </Stack>
  );
};

export default FileUploadInput;
