import { Button, Stack } from '@mui/material';

type UtilityPublishControlsProps = {
  isPublished: boolean;
  handleStatusChange: () => void;
  handleDelete: () => void;
};

const UtilityPublishControls = ({ isPublished, handleStatusChange, handleDelete }: UtilityPublishControlsProps) => {
  return (
    <Stack
      flexDirection={'row'}
      gap={1}
    >
      <Button
        variant="outlined"
        onClick={handleStatusChange}
      >
        {isPublished ? 'Move to Draft' : 'Publish'}
      </Button>
      <Button
        variant="contained"
        onClick={handleDelete}
      >
        Delete
      </Button>
    </Stack>
  );
};

export default UtilityPublishControls;
