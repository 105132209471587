import { useRef } from 'react';

import FeedEmpty from '~components/dashboard/Feed/components/FeedEmpty/FeedEmpty';
import FeedError from '~components/dashboard/Feed/components/FeedError/FeedError';
import FeedItem from '~components/dashboard/Feed/components/FeedItem/FeedItem';
import UpdateNotification from '~components/dashboard/Feed/components/UpdateNotification';
import UserPost from '~components/dashboard/Feed/components/UserPost';
import {
  selectFeedError,
  selectFeedOrderAndDataObject,
  selectIsNewFeedEventWaiting,
} from '~features/streams/streams.selectors';
import { setNewFeedEventWaiting } from '~features/streams/streams.slice';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';

const Feed = () => {
  const dispatch = useAppDispatch();
  const feed = useAppSelector(selectFeedOrderAndDataObject);
  const isNewFeedEventWaiting = useAppSelector(selectIsNewFeedEventWaiting);
  const feedError = useAppSelector(selectFeedError);

  const topRef = useRef(null);

  const executeScroll = () => {
    topRef.current.scrollIntoView();
    dispatch(setNewFeedEventWaiting(false));
  };

  if (feedError) {
    return <FeedError />;
  }

  if (!feed.order.length) {
    return <FeedEmpty />;
  }

  return (
    <div ref={topRef}>
      {isNewFeedEventWaiting && <UpdateNotification executeScroll={executeScroll} />}
      <div className={'CAKE__feed__container__feed'}>
        <UserPost />
        {feed.order.map((i: string) => {
          return (
            <FeedItem
              key={i}
              data={feed.data[i]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Feed;
