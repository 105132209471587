import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';

import type { BatterDrop } from '~types/DropType';

type Props = {
  drop: BatterDrop;
  onEditDrop: (id: string) => void;
};

const DropListItem = ({ drop, onEditDrop }: Props) => {
  const currentUnixTime = new Date().getTime();
  const inProgress = drop.openWindowEpochTime <= currentUnixTime && drop.closeWindowEpochTime >= currentUnixTime;
  const completed = drop.closeWindowEpochTime < currentUnixTime;

  const formatDate = (unix: number) => {
    return moment(unix).format('lll');
  };

  return (
    <ListItem
      alignItems="flex-start"
      secondaryAction={
        !inProgress &&
        !completed && (
          <Tooltip title="Edit Drop">
            <IconButton
              onClick={() => onEditDrop(drop._id)}
              edge="end"
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )
      }
      disablePadding
    >
      <ListItemButton>
        <ListItemAvatar>
          <Avatar
            alt={`Item ${drop._id}`}
            src={drop.shopifyProduct?.image}
          />
        </ListItemAvatar>
        <Stack
          direction="row"
          spacing={2}
        >
          <ListItemText
            primary={drop.shopifyProduct?.title ?? 'Missing Product'}
            secondary={`${formatDate(drop.openWindowEpochTime)} - ${formatDate(drop.closeWindowEpochTime)}`}
            secondaryTypographyProps={{ fontSize: 16 }}
          />
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            paddingTop={3}
          >
            {inProgress && (
              <Chip
                label="In Progress"
                color="primary"
                variant="outlined"
              />
            )}

            {completed && (
              <Chip
                label="Completed"
                color="success"
              />
            )}
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default DropListItem;
