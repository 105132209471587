import '~components/dashboard/FAQSettingsCard/components/FAQSettingsEntry/FAQSettingsEntry.scss';

import InputIconButton from '~components/buttons/InputIconButton';
import { BinIcon, PencilIcon } from '~icons/common';
import type { FAQEntry } from '~types/ProjectType';

type FAQSettingsEntryProps = {
  faq: FAQEntry;
  index: number;
  onDelete: () => void;
  onEdit: () => void;
};

const FAQSettingsEntry = ({ index, faq, onDelete, onEdit }: FAQSettingsEntryProps) => {
  return (
    <div className={'CAKE__faq-settings-card-entry'}>
      <span className={'CAKE__faq-settings-card-entry__title'}>{`Question ${index + 1}`}</span>
      <div className={'CAKE__faq-settings-card-entry__field'}>
        <span className={'CAKE__faq-settings-card-entry__text'}>{faq.question}</span>
        <div className={'CAKE__faq-settings-card-entry__buttons'}>
          <InputIconButton
            onClick={onEdit}
            color="#009688"
          >
            <PencilIcon />
          </InputIconButton>
          <InputIconButton
            onClick={onDelete}
            color="#009688"
          >
            <BinIcon />
          </InputIconButton>
        </div>
      </div>
    </div>
  );
};

export default FAQSettingsEntry;
