import type { PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  selectMintAnalytics,
  selectTopWalletHolders,
  selectTransferData,
  selectWalletDistribution,
} from '~features/analytics/analytics.selectors';
import {
  getMintAnalytics,
  getTopWalletHolders,
  getTransferData,
  getWalletDistribution,
  setMintAnalytics,
  setTopWalletHolders,
  setTransferData,
  setWalletDistribution,
} from '~features/analytics/analytics.slice';
import { addNotification } from '~features/notifications/notifications.slice';
import { fetchProtectedAPI } from '~features/utils/api/api.sagas';
import type {
  AggregateMintsTypes,
  TopWalletHoldersType,
  TransferDataType,
  WalletDistributionType,
} from '~types/AnalyticsType';

window.Buffer = window.Buffer || Buffer;

function* fetchMintAnalyticsSaga(
  action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
): Iterator<any> {
  const { projectId, contractAddress, refresh } = action.payload;
  const currentAggregateMints: AggregateMintsTypes[] = yield select(selectMintAnalytics);

  if (!currentAggregateMints.length || refresh) {
    try {
      const res: Response = yield call(
        fetchProtectedAPI,
        `analytics/${projectId}/mints?contractAddress=${contractAddress}`,
      );
      const data: Array<AggregateMintsTypes> = yield res.json();

      if (data.length > 0) {
        yield put(setMintAnalytics(data));
      } else {
        yield put(setMintAnalytics([]));
      }
    } catch (e) {
      console.log(e);
      yield put(
        addNotification({
          message: 'We encountered an error trying to update your data.',
          severity: 'error',
          duration: 5000,
        }),
      );
    }
  }
}

function* fetchWalletDistributionSaga(
  action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
): Iterator<any> {
  const { projectId, contractAddress, refresh } = action.payload;
  const currentWalletDistribution: WalletDistributionType[] = yield select(selectWalletDistribution);

  if (!currentWalletDistribution.length || refresh) {
    try {
      const res: Response = yield call(
        fetchProtectedAPI,
        `analytics/${projectId}/wallet-distribution?contractAddress=${contractAddress}`,
      );
      const data: Array<WalletDistributionType> = yield res.json();

      if (data.length > 0) {
        yield put(setWalletDistribution(data));
      } else {
        yield put(setWalletDistribution([]));
      }
    } catch (e) {
      console.log(e);
      yield put(
        addNotification({
          message: 'We encountered an error trying to update your data.',
          severity: 'error',
          duration: 5000,
        }),
      );
    }
  }
}

function* fetchTransferDataSaga(
  action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
): Iterator<any> {
  const { projectId, contractAddress, refresh } = action.payload;
  const currentTransferData: TransferDataType[] = yield select(selectTransferData);

  if (!currentTransferData.length || refresh) {
    try {
      const res: Response = yield call(
        fetchProtectedAPI,
        `analytics/${projectId}/transfers?contractAddress=${contractAddress}`,
      );
      const data: Array<TransferDataType> = yield res.json();

      if (data.length > 0) {
        yield put(setTransferData(data));
      } else {
        yield put(setTransferData([]));
      }
    } catch (e) {
      console.log(e);
      yield put(
        addNotification({
          message: 'We encountered an error trying to update your data.',
          severity: 'error',
          duration: 5000,
        }),
      );
    }
  }
}

function* fetchTopWalletHoldersSaga(
  action: PayloadAction<{ projectId: string; contractAddress: string; refresh: boolean }>,
): Iterator<any> {
  const { projectId, contractAddress, refresh } = action.payload;
  const currentTopWalletHolderData: TopWalletHoldersType[] = yield select(selectTopWalletHolders);

  if (!currentTopWalletHolderData.length || refresh) {
    try {
      const res: Response = yield call(
        fetchProtectedAPI,
        `analytics/${projectId}/wallets?contractAddress=${contractAddress}`,
      );
      const data: Array<TopWalletHoldersType> = yield res.json();

      if (data.length > 0) {
        yield put(setTopWalletHolders(data));
      } else {
        yield put(setTopWalletHolders([]));
      }
    } catch (e) {
      console.log(e);
      yield put(setTopWalletHolders([]));
    }
  }
}

export default function* analyticsSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getMintAnalytics.type, fetchMintAnalyticsSaga),
    yield takeLatest(getWalletDistribution.type, fetchWalletDistributionSaga),
    yield takeLatest(getTransferData.type, fetchTransferDataSaga),
    yield takeLatest(getTopWalletHolders.type, fetchTopWalletHoldersSaga),
  ]);
}
