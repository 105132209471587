import FileUploadIcon from '@mui/icons-material/FileUpload';
import { IconButton } from '@mui/material';
import { useRef } from 'react';

type FileUploadIconButtonProps = {
  onUpload: (file: File) => void;
};

const FileUploadIconButton = ({ onUpload }: FileUploadIconButtonProps) => {
  const inputRef = useRef(null);

  const onUploadFile = () => {
    inputRef.current.click();
  };

  const onFileChoosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only runs updates if uploaded file name is diff from previous
    const file: File = e.target.files[0];
    onUpload(file);
  };

  return (
    <>
      <IconButton onClick={onUploadFile}>
        <input
          type="file"
          name=""
          id="input_file"
          accept="image/*"
          ref={inputRef}
          hidden
          onChange={onFileChoosen}
        />
        <FileUploadIcon />
      </IconButton>
    </>
  );
};

export default FileUploadIconButton;
