import { Grid, Stack } from '@mui/material';

import ContractExecutable from '~components/dashboard/ContractSettingsCard/components/ContractExecutable';
import DashboardCard from '~components/dashboard/DashboardCard';
import { selectProjectConfig } from '~features/project-config/project-config.selectors';
import { selectWeb3ConfigContractFunctionAbi } from '~features/web3/web3.selectors';
import useAppSelector from '~hooks/useAppSelector';

type ContractSettingsCardProps = {
  executionType: string;
  chainId: number | null;
  contractAddress: string;
};

const ContractSettingsCard = ({ executionType, chainId, contractAddress }: ContractSettingsCardProps) => {
  const projectConfig = useAppSelector(selectProjectConfig);
  const { contract } = projectConfig;
  const { read, write } = useAppSelector(selectWeb3ConfigContractFunctionAbi);

  if (read.length === 0 && write.length === 0) {
    return <></>;
  }

  return (
    <Stack>
      <DashboardCard title={`${executionType} Contract`}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 8 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            {executionType === 'read' &&
              read.length > 0 &&
              read.map((abi, index) => {
                return (
                  <ContractExecutable
                    key={index}
                    abi={abi}
                    executionType={executionType}
                    contractAddress={contractAddress}
                    chainId={chainId}
                    contractAbi={contract.abi}
                  />
                );
              })}
            {executionType === 'write' &&
              write.length > 0 &&
              write.map((abi, index) => {
                return (
                  <ContractExecutable
                    key={index}
                    abi={abi}
                    executionType={executionType}
                    contractAddress={contractAddress}
                    chainId={chainId}
                    contractAbi={contract.abi}
                  />
                );
              })}
          </Grid>
        </Grid>
      </DashboardCard>
    </Stack>
  );
};

export default ContractSettingsCard;
